
import { Box, Typography, makeStyles } from "@material-ui/core";
import url from "../../../../src/MyComponents/home/java/images/javahackingtruth.png";



   const useStyles = makeStyles({
	   
	   container:{
		   height: 350,
		   margin: 10,
		   border: '1px solid #d3cede',
		   borderRadius: 10, 
		   alignItems: 'center',
		   display: 'flex',
		   flexDirection: 'column',
		   '& > *': {
		   padding: '0 5px 5px 5px'
	        
		   }
	   },
	   
	   image: {
		   
		   height: 150,
		   width: '100%',
		   objectFit: 'cover',
		   borderRadius: '10px 10px 0 0'
	   },
	   
	   
	   text: {
		   color: '#878787',
		   fontSize: 12
		   
	   },
	   
	   heading: {
		   
		   fontSize: 18,
		   fontWeight: 600
		   
		   
	   },
	   
	   detail: {
		 
		 fontSize: 14,
		 wordBreak: 'break-word'
		   
		   
	   }
	   
   })

 

const CGIpostdata = () => {
	const classes = useStyles();
	
	
	
	return (
     
<>

   	
       <Box className= {classes.container}>	
	
            <img src={url} className={classes.image}/>

               <Typography className={classes.text}>Java</Typography>
               <Typography className={classes.heading}>CGI In JAVA</Typography>
               <Typography className={classes.text}>Author: Hacking Truth</Typography>
               <Typography className={classes.detail}>CGI technology enables the web server to call an HTTP request...</Typography>
             

     </Box>
	 
	 
	 
	 </>
	
	)
}

//const url = `https://www.hackingtruth.org/static/media/webdevelopmenthackingtruth.5b5ec1ffe79a862460b4.png`
	//const urlopen = `https://www.hackingtruth.org/static/media/webdevelopmenthackingtruth.5b5ec1ffe79a862460b4.png`
	
export default CGIpostdata;