import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Dbmsrelationalalgebrapostdata from "./Dbmsrelationalalgebrapostdata";
import Entitiespostdata from "./Entitiespostdata";
import Primarykeypostdata from "./Primarykeypostdata";
import Foreignkeypostdata from "./Foreignkeypostdata";



const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Relationalalgebraarticle"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Dbmsrelationalalgebrapostdata />
		</Link>
		</Grid>
		
		 
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Entitiesindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Entitiespostdata />
		</Link>
	    </Grid>
	
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Primarykeyindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Primarykeypostdata />
		</Link>
	    </Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Foreignkeyindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Foreignkeypostdata />
		</Link>
	    </Grid>
		
		
	
	</>
	 
   )
}

export default Posts;
