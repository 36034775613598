
import { Box, makeStyles, FormControl, InputBase, Button, TextareaAutosize} from "@material-ui/core";
import { AddCircle } from '@material-ui/icons';
import { useState } from "react";

import { createPost } from "../../../../service/api";
import { useHistory } from "react-router-dom";

const  useStyles = makeStyles((theme) => ({
	 
	 
	 container: {
		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 
	 
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover'
		 
		 
	 },
	 
	 form:{
		 
		 display: 'flex',
		 flexDirection: 'row',
		 marginTop: '10px'
	 },
	 
	 textfields: {
		 flex: 1,
		 margin: '0  30px',
		 fontSize: 25
			 
	 },
	 textarea: {
		 width: '100%',
		 marginTop: 50,
		 border: 'none',
		 fontSize: 18,
		 '&:focus-visible': {
			 
			 outline: 'none'
		 }
		 
		 
	 }
}));


   const intialValues = {
	   
	   title: '',
	   description: '',
	   pictures: '',
	   username: 'Hacking Truth',
	   categories: 'All',
	   createDate: new Date()
	   
   }

const Webcreateview = () => {
	
	const classes = useStyles();

 const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
 const history = useHistory();
	
	const [post, setPost] = useState(intialValues);
	
	const handleChange = (e) => {
		
		setPost({ ...post, [e.target.name]: e.target.value })
		
		
	}
	
	
	
	const savePost = async () => {
		
		await createPost(post);
		history.push('/')
	}
	
	
	return (
	
  <Box className={classes.container}>
    <img className={classes.image} src={url} alt="hackingtruth" />

   <FormControl className={classes.form}>	
      <AddCircle fontSize="large" color="action" />
        <InputBase  onChange= {(e) => handleChange(e)} 
          placeholder="Title"
		    className={classes.textfields}
			  name="title"
		
		
		/>
		
		
       <Button onClick={() => savePost()}variant="contained" color="primary">Publish </Button>  
   
   </FormControl>	
  
  
    <TextareaAutosize 
	   rowsMin = {10}
	   placeholder="Tell your story..."
	   className={classes.textarea}
	   onChange= {(e) => handleChange(e)}
	   name="description"
  
  />
  </Box>
  

	)
}


export default Webcreateview;