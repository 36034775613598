import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/classdiagram.png";
import webindex2 from "../../../../../src/MyComponents/home/Softwareengineering/images/objectdiagram.png";
import webindex3 from "../../../../../src/MyComponents/home/Softwareengineering/images/statemachine.png";
import webindex4 from "../../../../../src/MyComponents/home/Softwareengineering/images/activitydiagram.png";
import webindex5 from "../../../../../src/MyComponents/home/Softwareengineering/images/componentdiagram.png";
import webindex6 from "../../../../../src/MyComponents/home/Softwareengineering/images/packagediagram.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Functionalandnonfunctional = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Functional And Non-Functional</strong></Typography><br />
	 <Typography>
	 
	 Where Do Requirements Come From?
	 <br />
	 <br />
<ul>


<li>Stakeholders</li>

<li>Project customer</li>
<li>Product user group</li>
<li>An analyst/requirement engineer</li>

<li>Project leader</li>
<li>Project team members</li>
<li>Upper management</li>
<li>Project testers</li>

<li>Licensing entities</li>
<li>Outside vendors or suppliers</li>
<li>Government entities</li>

</ul>
     <br />
     <br />
<br />
<strong>Requirement Types</strong>
<br />
<br />
<ol>

<li>Functional</li>
<li> Non-Functional</li>

</ol>

<br />
	</Typography>
  	<br />
	
		
		
		<Typography variant="h5" component="h6"><strong>Functional</strong></Typography><br />
    <Typography>
    <ul> 

<li>Describe the behaviors (functions or services) of the system.</li>
<li>Support user goals, tasks or activities.</li>
<li>F(input, system state) (output, new state) Output, new state Input, initial state.</li>
</ul>

		<br />
		
		
        </Typography>
		
		
		
		<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Non Functional</strong></Typography><br />
    <Typography>
	
	<ul>
	
	<li>In software system engineering is, a software requirement that describes
not what the software will do, but how the software will do it, for example, software performance requirements,
software external interface requirements, design constraints, and software quality attributes.
</li>

<li>
<strong>Capacity</strong>
A factor of scalability, which measures how storage hardware would increase with time Attributes Throughput Volume of
data with time Year and year Growth (users, processing, storage) Security Security is the ability of an application to avoid
malicious incidences and events outside of the designed system usage, and prevent disclosure or loss of information.
</li>

<li>Attributes Authentication, Authorisation, Encryption, Data Confidentiality, Compliance Maintainability ability to go through
modifications and updates Attributes Design & Coding Standard Flexiability & Release Support.
</li>

<li>Attributes Handle new information types Manage new or changed business entities Consume
or provide new feeds Recovery In the event of a natural calamity, how soon you can recover
Attributes RTO (Recovery Time Objectives) RPO (Recovery Point Objectives)
Restore Time Backup Time Backup Frequencies Interoperateability Ability to
exchange information and communicate with internal and external applications and system
Compatability with Shared application, 3P application, Diﬀerent OS and hardware platforms.

</li>

<li>
Achieved via Publishing Interface (Documentation) Loosely couples with external system
</li>


<li>Nonfunctional requirements are difficult to test; therefore, they are usually evaluated subjectively.
</li>


</ul>


<br /><br />
	<br />
		

    <br />
		
		
       

		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Sdlc" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Functionalandnonfunctional;