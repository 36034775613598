import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/heading-hackingtruth1.png";
import webindexparagraph from "../../../../../src/MyComponents/home/webdevelopment/images/paragrapgh-hackingtruth1.png";
import webindexlinks from "../../../../../src/MyComponents/home/webdevelopment/images/links-hackingtruth.png";
import webindeximages from "../../../../../src/MyComponents/home/webdevelopment/images/images-hackingtruth.in.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jqueryeventlist = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Jquery EventList</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Basic Selectors </strong></Typography><br />
	 <Typography>
    

<li><strong>TagName </strong></li>

<br />
document.getElementByTagName('tagName'); <br />
$("tagName") - $("div"), $("p"), $("div")....

<br />
<li><strong>Tag ID </strong></li>

<br />
document.getElementById("id"); <br />
$("#id") - $("#name").$("#address")
<br />
<br />
<li><strong>Tag Class</strong></li>
<br />
   
document.getElementByClassName("className"); <br />
$(".className) - $(".comment").$(".code")
<br />   
<br />
<li><strong>To select all elements </strong></li>
<br />
$("*")
<br />

	</Typography>
  	<br />
	
	<br />
	
	
		 <Typography> <strong>Selectors Combined </strong></Typography><br />
	 <Typography>
    

<li><strong>Syntax </strong></li>



<br />
<li><strong>Examples </strong></li>

<br />
$("h1.mainTitle") <br />
$("h1.mainTitle#firstHeading")
<br />

<br />
	</Typography>
  	<br />
	
	<br />
	
	
	
		
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Index filters
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Syntax</th>
      <th scope="col">Example</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>$("selector:first") <br />
$("selector:last")</td>
      <td>$("p:first") <br />
$("p:last") <br /></td>
     
    </tr>
	<tr>
      <td>
$("selector:odd") <br />
$("selector:even")</td>
      <td>$("p:odd") <br />
$("p:even) </td>
      
    </tr>
    <tr>
      <td>$("selector:eq(i)") <br />
$("selector:gt(i)") <br />
$("selector:lt(i)") </td>
      <td>
$("p:eq(1)") <br />
$("p:gt(1)") <br />
$("p:lt(1)") </td>
      
    </tr>
   
  </tbody>
</table>


	<br />	
	
		
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Condition filters - Form filters
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
   
  </thead>
  <tbody>
    <tr>
      <td>$("selector:visible") <br />
$("selector:hidden")</td>
      <td>$("selector:input") <br />
	  $("selector:text") <br />
	  $("selector:password") <br />
	  
	  </td>
     
    </tr>
	<tr>
      <td>
$("selector:disabled") <br />
$("selector:enabled")</td>
      <td>$("selector:radio")  <br />
$("selector:Checkbox")  </td>
      
    </tr>
    <tr>
      <td>$("selector:checked") <br />
$("selector:selected) <br /><br />
$("selector:header) <br />
$("selector:animated) <br />


</td>
      <td>
$("selector:submit) <br />
$("selector:reset) <br />
$("selector:image) <br />
$("selector:file) <br />
$("selector:button) </td>
      
    </tr>
   
  </tbody>
</table>


	<br />	
	<br />
	
	
	
	
	
	
	
		
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Attribute filters
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Syntax</th>
      <th scope="col">Example</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>$("selector[attribute]") <br />
$("selector[attribute=value]")</td>
      <td>$("p:[name]") <br />
$("p:[name=para]") <br /></td>
     
    </tr>
	<tr>
      <td>
$("selector[attribute!=value]") <br />
</td>
      <td>$("p:[name!=para]")  </td>
      
    </tr>
    <tr>
      <td>$("selector[attribute^=value]")</td>
      <td>
$("p:[name^=para]")  </td>
      
    </tr>
   
   <tr>
      <td>
$("selector[attribute$=value]") <br />
</td>
      <td>$("p:[name$=para]")  </td>
      
    </tr>
    <tr>
      <td>$("selector[attribute*=value]")</td>
      <td>
$("p:[name*=para]")  </td>
      
    </tr>
   
 
   
   
  </tbody>
</table>


	<br />			
	<br />
	
	
	
	
		
	
		
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Retrieve, Set and Remove Attributes
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Syntax</th>
      <th scope="col">Example</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>$("selector").attr("name")</td>
      <td>$("img").attr("src") <br /></td>
     
    </tr>
	<tr>
      <td>
$("selector").attr("key", "val") <br />
</td>
      <td>$("p").attr("class", "val") "source")  </td>
      
    </tr>
    <tr>
      <td>$("selector").attr("key", fn())</td>
      <td>
$("img").attr("height", fn()) calHt())  </td>
      
    </tr>
   
   <tr>
      <td>
$("selector").attr(properties) <br />
</td>
      <td>$("img").attr(&#123; s) "src" : "&#47;path&#47;", "title" : "My Img" &#125;); </td>
      
    </tr>
    <tr>
      <td>$("div").removeAttr("class“ ttr))</td>
      <td>
$("p:[name*=para]")  </td>
      
    </tr>
   
 
   
   
  </tbody>
</table>


	<br />			
	<br />
	
	
	
	
		
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Class, HTML, Text, Value - Functions
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    
  </thead>
  <tbody>
    <tr>
      <td>$("selector").hasClass("className")</td>
      
     
    </tr>
	<tr>
      <td>
$("selector").addClass("className") <br />
</td>
     
      
    </tr>
    <tr>
      <td>$("selector").removeClass("className")</td>
      
      
    </tr>
   
   <tr>
      <td>
$("selector").html()
</td>
      
    </tr>
	
    <tr>
      <td>$("selector").html("html code")</td>
   
      
    </tr>
   
 
    <tr>
      <td>$("selector").text()</td>
   
      
    </tr>
   
 
    <tr>
      <td>$("selector").text("text content")</td>
   
      
    </tr>
   
    <tr>
      <td>$("selector").val()</td>
   
      
    </tr>
   
 
    <tr>
      <td>$("selector").val("value")</td>
   
    </tr>
   
 
   
   
  </tbody>
</table>


	<br />			
	<br />
	
	
	
	<Typography><strong>Events</strong></Typography><br />
    <Typography>
       
<li>bind()</li>
<li>unbind()</li>
<li>ready().</li>
<li>toggle()</li>
<li>hover()</li>
<li>trigger()</li>
<br />
<br />
<li>$("selector").bind(event, data, handler)</li>
<li>$("Selector").unbind(event, handler)</li>


		
		<br />
		<br />
		
		
        </Typography>
		
		<br />
		<br />
	
	
	
	
	<Typography><strong>Animations</strong></Typography><br />
    <Typography>
       
<li>show()</li>
<li>hide()</li>
<li>fadeIn().</li>
<li>fadeOut()</li>
<li>slideUp()</li>
<li>slideDown()</li>
<br />
<br />
<li>$("selector").bind(event, data, handler)</li>
<li>$("Selector").unbind(event, handler)</li>


		
		<br />
		<br />
		
		
        </Typography>
		
		<br />
		<br />
	
	
	
	
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jquerybasic" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Jquerysyntax" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jqueryeventlist;