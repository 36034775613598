import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/heading-hackingtruth1.png";
import webindexparagraph from "../../../../../src/MyComponents/home/webdevelopment/images/paragrapgh-hackingtruth1.png";
import webindexlinks from "../../../../../src/MyComponents/home/webdevelopment/images/links-hackingtruth.png";
import webindeximages from "../../../../../src/MyComponents/home/webdevelopment/images/images-hackingtruth.in.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const SEOtips = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > HyperText Markup Language</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>SEO - Search Engine Optimization</strong></Typography><br />
	 <Typography>
    


SEO is all about helping search engines understand and present content and optimization is the process of imporoving the quality and quantity of website traffic or webpage from search engines.
<br />
<br />

	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>SEO Tips</strong></Typography><br />
    <Typography>
       



Here's a 5 SEO steps :- 

<br />
<br />
<ol>
<li>Description</li>
<li>Viewport</li>
<li>Canonical Tag</li>
<li>Alt to image</li>
<li>Relavant Title</li>
</ol>
<br />
<br />
Consider the following :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		
		<Typography><strong>1. Description</strong></Typography><br />
		<Typography>
		 Meta Description - Add a quick description in between head tag &lt;head&gt;&lt;/head&gt; of your site here. This will be visible on
google results.<br />
		 <br />
		 
&lt;meta name="description" content="This is a example of a meta description. This will often show up
in the search results" &gt;
 <br />
		 <br />
		
		<br />
		<br />
		
		</Typography>
		
	
	
		<br />
		
		<Typography><strong>2. Viewport</strong></Typography><br />
    <Typography>
		
Viewport - Using a viewport meta tag you can control layout on mobile browsers.
<br />
<br />
&lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;
<br />
<br />

		</Typography>
		

		
		
		<Typography><strong>3. Canonical Tag</strong></Typography><br />
    <Typography>
		
Canonical Tag - Practically speaking, the canonical tag tell search engines which version of a
URL you want to appear in search results.<br />
<br />
<br />
&lt;link rel="canonical" href="https://moz.com/beginners-guide-to-connect-marketing" /&gt;

<br />
<br />
		</Typography>
		

		
		
		
		
		
		<Typography><strong>4. Alt to image </strong></Typography><br />
    <Typography>
	
Alt to Image - Yes, this is important. All reveals importatnt content to google.

<br />
<br />
<br />

		</Typography>
		

		
			
		
		<Typography><strong>5. Relavant Title </strong></Typography><br />
    <Typography>
	
Relavant Title - Add relavant title is not only appears on top of browers it also appears on
google search result.

<br />
<br />
&lt;title&gt; Primary Keyword | Secondary Keyword | Brand Name &lt;/title&gt;
<br />
<br />
&lt;title&gt; HTML | CSS | Hacking Truth &lt;/title&gt;

<br />
<br />
<br />

		</Typography>
		
	<Typography><strong>Example </strong></Typography><br />
    <Typography>
	
<br />

<br />
<pre>


&lt;!DOCTYPE html&gt;    <br />
&lt;html&gt;   <br />
&lt;head&gt;   <br />   <br />



&lt;meta name="description" content="This is an example of a meta description. This will often
show up in serarch results" &gt;
   <br />   <br />
&lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;
   <br />   <br />
&lt;title&gt;HTML | CSS nth child | Hacking Truth&lt;/title&gt;
   <br />   <br />
&lt;link rel="canonical" href="https://moz.com/beginners-guide-to-content-marketing" /&gt; 
   <br />   <br />

&lt;/head&gt;   <br />

&lt;body&gt;   <br />

&lt;/body&gt;   <br />
&lt;/html&gt;   <br />   <br />


</pre>
<br />
<br />

		</Typography>
		

		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Htmlintroarticle" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Htmlbasicarticle" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default SEOtips;