import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Postdata from "./Postdata";


import Htmlintropostdata from "../../../src/MyComponents/home/webdevelopment/Htmlintropostdata";
import Htmlbasicpostdataa from "../../../src/MyComponents/home/webdevelopment/Htmlbasicpostdataa";
import Softwareengineeringevolvingroleofsoftwarepostdata from "../../../src/MyComponents/home/Softwareengineering/Softwareengineeringevolvingroleofsoftwarepostdata";
import Layeredtechnologypostdata from "../../../src/MyComponents/home/Softwareengineering/Layeredtechnologypostdata";
import Softwareprocessmodelpostdata from "../../../src/MyComponents/home/Softwareengineering/Softwareprocessmodelpostdata";
import Requirementnegotiationpostdata from "../../../src/MyComponents/home/Softwareengineering/Requirementnegotiationpostdata";
import Sdlcpostdata from "../../../src/MyComponents/home/Softwareengineering/Sdlcpostdata";
import Umldiagrampostdata from "../../../src/MyComponents/home/Softwareengineering/Umldiagrampostdata";
import Functionalandnonfunctionalpostdata from "../../../src/MyComponents/home/Softwareengineering/Functionalandnonfunctionalpostdata";
import Cohesionandcouplingpostdata from "../../../src/MyComponents/home/Softwareengineering/Cohesionandcouplingpostdata";
import Casetoolpostdata from "../../../src/MyComponents/home/Softwareengineering/Casetoolpostdata";
import Blackboxtestingpostdata from "../../../src/MyComponents/home/Softwareengineering/Blackboxtestingpostdata";
import Whiteboxtestingpostdata from "../../../src/MyComponents/home/Softwareengineering/Whiteboxtestingpostdata";
import Softwarerequirementspecificationpostdata from "../../../src/MyComponents/home/Softwareengineering/Softwarerequirementspecificationpostdata";
import Optimizationtechniquespostdata from "../../../src/MyComponents/home/Softwareengineering/Optimizationtechniquespostdata";
 

import Datastructurepostdata from "../../../src/MyComponents/home/Datastructure/Datastructurepostdata";
import Arrayindatastructurepostdata from "../../../src/MyComponents/home/Datastructure/Arrayindatastructurepostdata";
import Stackdatastructurepostdata from "../../../src/MyComponents/home/Datastructure/Stackdatastructurepostdata";
import Applicationofstackpostdata from "../../../src/MyComponents/home/Datastructure/Applicationofstackpostdata";
import Infixtopostfixpostdata from "../../../src/MyComponents/home/Datastructure/Infixtopostfixpostdata";
import Postfixexpressionpostdata from "../../../src/MyComponents/home/Datastructure/Postfixexpressionpostdata";
import Infixtoprefixpostdata from "../../../src/MyComponents/home/Datastructure/Infixtoprefixpostdata";
import Prefixexpressionpostdata from "../../../src/MyComponents/home/Datastructure/Prefixexpressionpostdata";
import Recursionpostdata from "../../../src/MyComponents/home/Datastructure/Recursionpostdata";
import Lineardatastructurepostdata from "../../../src/MyComponents/home/Datastructure/Lineardatastructurepostdata";
import Sortingpostdata from "../../../src/MyComponents/home/Datastructure/Sortingpostdata";
import Linkedlistpostdata from "../../../src/MyComponents/home/Datastructure/Linkedlistpostdata";
import Binarysearchtreepostdata from "../../../src/MyComponents/home/Datastructure/Binarysearchtreepostdata";



import Htmlcardboxpostdata from "../../../src/MyComponents/home/webdevelopment/Htmlcardboxpostdata";
import Htmlsidenavbarpostdata from "../../../src/MyComponents/home/webdevelopment/Htmlsidenavbarpostdata";
import SEOtipspostdata from "../../../src/MyComponents/home/webdevelopment/SEOtipspostdata";

import Jquerybasicpostdata from "../../../src/MyComponents/home/webdevelopment/Jquerybasicpostdata";
import Jqueryeventlistpostdata from "../../../src/MyComponents/home/webdevelopment/Jqueryeventlistpostdata";
import Jquerysyntaxpostdata from "../../../src/MyComponents/home/webdevelopment/Jquerysyntaxpostdata";
import Jqueryclickeventpostdata from "../../../src/MyComponents/home/webdevelopment/Jqueryclickeventpostdata";
import Jqueryhovereventpostdata from "../../../src/MyComponents/home/webdevelopment/Jqueryhovereventpostdata";
import Jqueryhideeventpostdata from "../../../src/MyComponents/home/webdevelopment/Jqueryhideeventpostdata";
import Jqueryblurpostdata from "../../../src/MyComponents/home/webdevelopment/Jqueryblurpostdata";




import Dbmsrelationalalgebrapostdata from "../../../src/MyComponents/home/dbms/Dbmsrelationalalgebrapostdata";
import Primarykeypostdata from "../../../src/MyComponents/home/dbms/Primarykeypostdata";
import Entitiespostdata from "../../../src/MyComponents/home/dbms/Entitiespostdata";
import Foreignkeypostdata from "../../../src/MyComponents/home/dbms/Foreignkeypostdata";

import Osschedulingpostdata from "../../../src/MyComponents/home/os/Osschedulingpostdata";
import Redundantarraysofindependentpostdata from "../../../src/MyComponents/home/os/Redundantarraysofindependentpostdata";

import Javaservletspostdata from "../../../src/MyComponents/home/java/Javaservletspostdata";
import Lifecycleofaservletspostdata from "../../../src/MyComponents/home/java/Lifecycleofaservletspostdata";
import Helloservletpostdata from "../../../src/MyComponents/home/java/Helloservletpostdata";
import Genericservletsandhttpservletpostdata from "../../../src/MyComponents/home/java/Genericservletsandhttpservletpostdata";
import CGIpostdata from "../../../src/MyComponents/home/java/CGIpostdata";



const Posts = () => {
	
	return (
	<>
	
	
	
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/SEOtips"} style={{textDecoration: 'none', color: 'inherit'}}>
	<SEOtipspostdata />
	</Link>
	</Grid>
   
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Evolvingroleofsoftware"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwareengineeringevolvingroleofsoftwarepostdata />
		</Link>
		</Grid>
		
		
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Requirementnegotiation"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Requirementnegotiationpostdata />
		</Link>
		</Grid>
		
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Umldiagram"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Umldiagrampostdata />
		</Link>
		</Grid>
		
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Sdlc"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Sdlcpostdata />
		</Link>
		</Grid>
		
			
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Functionalandnonfunctional"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Functionalandnonfunctionalpostdata />
		</Link>
		</Grid>
		
				
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Casetool"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Casetoolpostdata />
		</Link>
		</Grid>
					
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Whiteboxtesting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Whiteboxtestingpostdata />
		</Link>
		</Grid>
		
						
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Blackboxtesting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Blackboxtestingpostdata />
		</Link>
		</Grid>
		
				
				
				
				
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Cohesionandcoupling"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Cohesionandcouplingpostdata />
		</Link>
		</Grid>
		
					
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Softwarerequirementspecification"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwarerequirementspecificationpostdata />
		</Link>
		</Grid>
		
					
    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Optimizationtechniques"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Optimizationtechniquespostdata />
		</Link>
		</Grid>
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Arrayindatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Arrayindatastructurepostdata />
		</Link>
		</Grid>
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Datastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Datastructurepostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Stackdatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Stackdatastructurepostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Applicationofstack"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Applicationofstackpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Infixtopostfix"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Infixtopostfixpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Postfixexpression"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Postfixexpressionpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Infixtoprefix"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Infixtoprefixpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Prefixexpression"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Prefixexpressionpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Recursion"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Recursionpostdata />
		</Link>
		</Grid>
		
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Lineardatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Lineardatastructurepostdata />
		</Link>
		</Grid>
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Sorting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Sortingpostdata />
		</Link>
		</Grid>
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Linkedlist"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Linkedlistpostdata />
		</Link>
		</Grid>
		
		
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Binarysearchtree"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Binarysearchtreepostdata />
		</Link>
		</Grid>
		
		
		
		
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Layeredtechnology"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Layeredtechnologypostdata />
		</Link>
		</Grid>
		
		
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Softwareprocessmodel"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwareprocessmodelpostdata />
		</Link>
		</Grid>
		
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Sdlc"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Sdlcpostdata />
		</Link>
		</Grid>
		
		
		
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Articledetails"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Postdata />
	</Link>
	</Grid>
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Htmlintroarticle"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Htmlintropostdata />
	</Link>
	</Grid>
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Schedulinginos"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Osschedulingpostdata />
	</Link>
	</Grid>
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Htmlcardbox"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Htmlcardboxpostdata />
	</Link>
	</Grid>
	
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Htmlsidenavbar"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Htmlsidenavbarpostdata />
	</Link>
	</Grid>
	
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Jqueryhoverevent"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Jqueryhovereventpostdata />
	</Link>
	</Grid>
	
	
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Jqueryhideevent"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Jqueryhideeventpostdata />
	</Link>
	</Grid>
	
	
	
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Jqueryclickevent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryclickeventpostdata />
		</Link>
	    </Grid>
	
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Primarykeyindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Primarykeypostdata />
	</Link>
	</Grid>
	
	
	
	
	
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Articledetails"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Postdata />
		</Link>
		</Grid>
		
		
	
	
	
	
	
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jquerybasic"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jquerybasicpostdata />
		</Link>
		</Grid>
		
		
	
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Foreignkeyindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Foreignkeypostdata />
		</Link>
		</Grid>
		
		
		
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Entitiesindbms"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Entitiespostdata />
	</Link>
	</Grid>
	
	
	
	
   
	<Grid item lg={3} sm={4} xs={12}>
	<Link to={"/Jqueryeventlist"} style={{textDecoration: 'none', color: 'inherit'}}>
	<Jqueryeventlistpostdata	/>
	</Link>
	</Grid>
	
	
	
	
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Redundantarraysofindependent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Redundantarraysofindependentpostdata />
		</Link>
		</Grid>
		
		
		
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jquerysyntax"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jquerysyntaxpostdata />
		</Link>
		</Grid>
		
		
		
	
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Relationalalgebraarticle"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Dbmsrelationalalgebrapostdata />
		</Link>
		</Grid>
		
		
		
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Htmlbasicarticle"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Htmlbasicpostdataa />
		</Link>
		</Grid>
		
		
		
	
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryblur"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryblurpostdata />
		</Link>
		</Grid>
		
		
		
		
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/CGIinjava"} style={{textDecoration: 'none', color: 'inherit'}}>
		<CGIpostdata />
		</Link>
		</Grid>
		
		
		
		
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Genericservletsandhttpservlet"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Genericservletsandhttpservletpostdata />
		</Link>
		</Grid>
		
		
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Helloservlet"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Helloservletpostdata />
		</Link>
		</Grid>
		
		
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Lifecycleofaservlets"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Lifecycleofaservletspostdata />
		</Link>
		</Grid>
		
		
		
        <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Servletsinjava"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Javaservletspostdata />
		</Link>
		</Grid>
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		</>
		
	)
}

export default Posts;