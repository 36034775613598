import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/dbms/images/DBMShackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Relationalalgebraarticle = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Database Management System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>19 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>DBMS - Relational Algebra</strong></Typography><br />
	 <Typography>
    <li>Relational algebra is a widely used procedural query language.</li>
    <li>It collects instances of relations as input and gives occurrences of relations as output.</li>
    <li>HTML describes the structure of a Web page</li>
    <li>It uses various operation to perform this action.</li>
    <li>Relational algebra operations are performed recursively on a relation.</li>
    <li>The output of these operations is a new relation, which might be formed from one or more input relations.
</li>

     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Fundamental Operations in Relational Algebra</strong></Typography><br />
    <Typography>
       
    <ol>	   
	<li>Selection Operator ( σ )</li>
    <li>Projection Operator ( 𝛑 )</li>
    <li>Union Operator ( ∪ )</li>
	<li>Set Difference (R-S)</li>
    <li>Inersection Operator ( ∩ )</li>
    <li>Cartesian Product ( X )</li>
    <li>Join Operator ( ⋈ )</li>
	<li>Rename</li>
	</ol>
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography variant="h5" component="h6"><strong>1. Selection Operator ( σ ) </strong></Typography><br />
    <Typography>
        
		
    <li>The SELECT operation is used for selecting a subset of the tuples according to a given selection condition.</li>
    <li>Sigma (σ) Symbol denotes it.</li>
    <li>It is used as an expression to choose tuples which meet the selection condition.</li>
    <li>Select operation selects tuples that satisfy a given predicate.</li>
   
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		
		<Typography><strong>Selection</strong></Typography><br />
    <Typography>
        Lets see how we can write its syntax!
	<br />
	<br />
	    <strong className={classes.selective}>σ</strong><strong> <sub>Selecion_Criteria</sub>(input)</strong>
	
	<br />
	<br />
	<br />
	
	<Typography><strong>For Example </strong></Typography>
	
	<br />
	<li>Select operation selects tuples that satisfy a given predicate.</li>
	<br />
	<li>Find all employees born after 1st Jan 1947: <strong className={classes.selectiveexample}>σ</strong><strong> <sub>01/JAN/1947</sub>(employee)</strong></li>
	<br />
	
	<li>Select the EMPLOYEE tuples whose department number is 4:
	<strong className={classes.selectiveexample}> σ</strong><strong> <sub>DNO = 4</sub>(employee)</strong></li>
	<br /> 
	 
	 <li>Select the EMPLOYEE tuples whose salary is greater than $30,000:
	<strong className={classes.selectiveexample}> σ</strong><strong> <sub>SALARY > 30, 000</sub>(employee)</strong></li>
	 <br />
	 
	 <li>Select the instruction in physics with a salary greater that $90, 000, (and (∧), or (∨), and not (￢))
	<strong className={classes.selectiveexample}> σ</strong><strong> <sub>dept name="Physics" ∧ salary>90000 </sub>(instructor)</strong></li>
	 <br />
	 
	 
	 
	 
	 </Typography>
	<br />
	<br />
	
	
	<Typography> So let us now understand it in more easy language through the Table : </Typography>
	<br />	
	<br />
	
	
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Person
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Name</th>
      <th scope="col">Address</th>
      <th scope="col">Hobby</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">2211</th>
      <td>Atul</td>
      <td>Harmu</td>
      <td>Web Dev</td>
    </tr>
	<tr>
      <th scope="row">2211</th>
      <td>Atul</td>
      <td>Harmu</td>
      <td>Pentesting</td>
    </tr>
    <tr>
      <th scope="row">5111</th>
      <td>hacking truth </td>
      <td>2 Rd no </td>
      <td>Ethical Hacking</td>
    </tr>
    <tr>
      <th scope="row">6555</th>
      <td>HackerBoY</td>
	  <td>colony</td>
      <td>Web Dev</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	<br />
	
	<Typography class=" text-center" variant="h3" component="h4">
	  σ Hobby = 'stamps' (Person)
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Name</th>
      <th scope="col">Address</th>
      <th scope="col">Hobby</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">2211</th>
      <td>Atul</td>
      <td>Harmu</td>
      <td>Web Dev</td>
    </tr>
	
    <tr>
      <th scope="row">6555</th>
      <td>HackerBoY</td>
	  <td>colony</td>
      <td>Web Dev</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	<br />
	
	
	
	

	
	
	
	<br />
<Typography variant="h5" component="h6"><strong>2. Projection Operator ( 𝛑 ) </strong></Typography><br />
    <Typography>
        
		
    <li>The projection eliminates all attributes of the input relation but those mentioned in the projection list.</li>
    <li>The projection method defines a relation that contains a vertical subset of Relation.</li>
    <li>The symbol used to choose attributes from a relation.</li>
    <li>This operation helps you to keep specific columns from a relation and discards the other columns.</li>
   
		
		
		<br />
		<br />
		
		
		<Typography><strong>Projection</strong></Typography><br />
    <Typography>
        Lets see how we can write its syntax!
	<br />
	<br />
	    <strong className={classes.selective}>𝛑</strong><strong> <sub>fields</sub>(input)</strong>
	
	<br />
	<br />
	Allow us to extract columns from a relation
	
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Person
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Name</th>
      <th scope="col">Address</th>
      <th scope="col">Hobby</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">2211</th>
      <td>Atul</td>
      <td>Harmu</td>
      <td>Web Dev</td>
    </tr>
	<tr>
      <th scope="row">2211</th>
      <td>Atul</td>
      <td>Harmu</td>
      <td>Pentesting</td>
    </tr>
    <tr>
      <th scope="row">5111</th>
      <td>hacking truth </td>
      <td>2 Rd no </td>
      <td>Ethical Hacking</td>
    </tr>
    <tr>
      <th scope="row">6555</th>
      <td>HackerBoY</td>
	  <td>colony</td>
      <td>Web Dev</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	<Typography class=" text-center" variant="h3" component="h4">
	   𝛑 Name, Hobby(Person)
	  </Typography>
	  
	  
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
     
      <th scope="col">Name</th>
      <th scope="col">Hobby</th>
    </tr>
  </thead>
  <tbody>
    <tr>
     
      <td>Atul</td>
      <td>Web Dev</td>
    </tr>
	<tr>
     
      <td>Atul</td>
      <td>Pentesting</td>
    </tr>
    <tr>
     
      <td>hacking truth </td>
     
      <td>Ethical Hacking</td>
    </tr>
    <tr>
      
      <td>HackerBoY</td>
	 
      <td>Web Dev</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	<br />
	
	
	
	 
	 
	 </Typography>
	
	
	
	
	
	
 	<strong>Note: By Default projection removes duplicate data.</strong>
	<br />
	<br />
		
    
	
	
		
        </Typography>
		
		
			
	
	
		<Typography><strong>The Selection Relation</strong></Typography><br />
    <Typography>
        Lets see how we can write its with big table
	<br />
	<br />
	    <strong className={classes.selective}>σ</strong><strong> <sub>Selecion_Criteria</sub>(input)</strong>
	
	<br />
	<br />
	<br />
	
	
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">C_ID</th>
      <th scope="col">Sec_ID</th>
      <th scope="col">Sem</th>
      <th scope="col">Year</th>
      <th scope="col">Building</th>
	  <th scope="col">Room_No</th>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">BIO-101</th>
      <td>1</td>
      <td>Summer</td>
      <td>2009</td>
      <td>Painter</td>
      <td>514</td>
      
	  </tr>
	  
	  
	   <tr>
      <th scope="row">BIO-301</th>
      <td>1</td>
      <td>Summer</td>
      <td>2010</td>
      <td>Painter</td>
      <td>514</td>
    
	  </tr>
	  
	  
   
	
	
	<tr>
      <th scope="row">CS-101</th>
      <td>1</td>
      <td>Fall</td>
      <td>2009</td>
      <td>Packard</td>
      <td>101</td>
       
    </tr>
	
	
	
	
	
	<tr>
      <th scope="row">CS-101</th>
      <td>1</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Packard</td>
      <td>101</td>
     
    </tr>
	
	
	
	
	
	<tr>
      <th scope="row">CS-190</th>
      <td>1</td>
      <td>Spring</td>
      <td>2009</td>
      <td>Taylor</td>
      <td>3128</td>
       
    </tr>
	
	
	
	
	<tr>
      <th scope="row">CS-190</th>
      <td>2</td>
      <td>Spring</td>
      <td>2009</td>
      <td>Taylor</td>
      <td>3128</td>
      
    </tr>
	
	
	
	
	
	
	<tr>
      <th scope="row">CS-315</th>
      <td>1</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Watson</td>
      <td>120</td>
    
    </tr>
	
	
	
	
	
	
	<tr>
      <th scope="row">CS-319</th>
      <td>1</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Watson</td>
      <td>100</td>
       
    </tr>
	
	
	
	
	
	
	<tr>
      <th scope="row">CS-319</th>
      <td>2</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Taylor</td>
      <td>3128</td>
     
    </tr>
	
	
	
	
	
	
	<tr>
      <th scope="row">CS-347</th>
      <td>1</td>
      <td>Fall</td>
      <td>2009</td>
      <td>Taylor</td>
      <td>3128</td>
     
    </tr>
	
	
	
	
	
	
	<tr>
      <th scope="row">EE-181</th>
      <td>1</td>
      <td>Spring</td>
      <td>2009</td>
      <td>Taylor</td>
      <td>3128</td>
   
    </tr>
	

	
	<tr>
      <th scope="row">FIN-201</th>
      <td>1</td>
      <td>Spring</td>
      <td>2009</td>
      <td>Packard</td>
      <td>101</td>
     
    </tr>
	
	
	
	
	
	<tr>
      <th scope="row">HIS-351</th>
      <td>1</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Painter</td>
      <td>514</td>
     
    </tr>
	
	
	
	
	
	<tr>
      <th scope="row">MU-199</th>
      <td>1</td>
      <td>Spring</td>
      <td>2010</td>
      <td>Packard</td>
      <td>101</td>
      
    </tr>
	
	
	<tr>
      <th scope="row">PHY-101</th>
      <td>1</td>
      <td>Fall</td>
      <td>2009</td>
      <td>Watson</td>
      <td>100</td>
      
    </tr>
	
	
	
	
  </tbody>
</table>

<br />
<br />
<br />

<li>Consider a query to find the set of all courses taught in the Fall 2009 Semester, the Spring 2010 Semester, or both</li>

<strong className={classes.selectiveexample}>  𝛑 </strong><strong> <sub>C_ID </sub>( σ <sub>sem = "Fall" ∧ year=2009 </sub>(section)) </strong>
	 <br />
	 
<strong className={classes.selectiveexample}>  𝛑 </strong><strong> <sub>C_ID </sub>( σ <sub>sem = "Spring" ∧ year=2010 </sub>(section)) </strong>
	 <br />
<br />
	 
	 
<li>To find the set of all course taught in the particular year, we</li>

<strong className={classes.selectiveexample}>  𝛑 </strong><strong> <sub>C_ID </sub>( σ <sub>sem = "Fall" ∧ year=2009 </sub>(section)) ∪ </strong>
	 <br />
	 
<strong className={classes.selectiveexample}>  𝛑 </strong><strong> <sub>C_ID </sub>( σ <sub>sem = "Spring" ∧ year=2010 </sub>(section)) </strong>
	 <br />
<br />
	 
	 
	 
</Typography>

	<br />	
	<br />
	<br />
	
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>3. Union Operator ( ∪ ) </strong></Typography><br />
    <Typography>
        
		
    <li>UNION is symbolized by ∪ symbol.</li>
    <li>It includes all tuples that are in tables A or in B.</li>
    <li>It also eliminates duplicate tuples.</li>
    <li>So, set A UNION set B would be expressed as: The result &lt;- A ∪ B</li>
	<li>For a union operation to be valid, the following conditions must hold -R and S must be the same number of attributes.</li>
	<li>Attribute domains need to be compatible.</li>
	<li>Duplicate tuples should be automatically removed.</li>
   
		
		
		<br />
		<br />
		
		
		<Typography><strong>Union Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>F </td>
      <td>4</td>
    </tr>
    <tr>
      
	  <td>E</td>
      <td>5</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
<Typography class=" text-center" variant="h3" component="h4">
	 B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>E </td>
      <td>4</td>
    </tr>
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 A ∪ B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
    <tr>
      
      <td>D </td>
      <td>3</td>
    </tr>
     
	  <tr>
      <td>E </td>
      <td>5</td>
    </tr>
     
	  <tr>
      <td>F </td>
      <td>4</td>
    </tr>
	
    <tr>
      <td>E </td>
      <td>4</td>
    </tr>
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

	<br />	
	<br />
	<br />
	
	 
	 
	 </Typography>
	
		
        </Typography>
		
		
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>4. Set Difference Operator </strong></Typography><br />
    <Typography>
        
		
    <li>The MINUS operation includes tuples from one Relation that are not in another Relation and symbolized by the - ( minus ) symbol.</li>
    <li>Therefore R - S would be expressed as</li>
    <li>RESULT &lt;- R - S</li>
    
		
		
		<br />
		<br />
		
		
		<Typography><strong>Set Difference Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 R
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>F </td>
      <td>4</td>
    </tr>
    <tr>
      
	  <td>E</td>
      <td>5</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
<Typography class=" text-center" variant="h3" component="h4">
	 S
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>E </td>
      <td>4</td>
    </tr>
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 R Difference S
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">B</th>
      <th scope="col">2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>F</td>
      <td>4</td>
    </tr>
	<tr>
      
      <td>E</td>
      <td>5</td>
    </tr>
   
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 S Difference R
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">C</th>
      <th scope="col">2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>E</td>
      <td>4</td>
    </tr>
	
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
	 
	 </Typography>
	
    
	
		
        </Typography>
		
		
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>5. Intersection Operator ( ∩ ) </strong></Typography><br />
    <Typography>
        
		
    <li>An intersection is defined by the symbol ∩</li>
	<li>For Ex. A ∩ B</li>
    <li>It defines a relation consisting of a set of all tuple that are in both A and B. However, A and B must be union-compatible. </li>
    
	
		
		<br />
		<br />
		
		
		<Typography><strong>Intersection Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>F </td>
      <td>4</td>
    </tr>
    <tr>
      
	  <td>E</td>
      <td>5</td>
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
<Typography class=" text-center" variant="h3" component="h4">
	 B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
      <td>E </td>
      <td>4</td>
    </tr>
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 A ∩ B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>A</td>
      <td>1</td>
    </tr>
	
    <tr>
      
      <td>D </td>
      <td>3</td>
    </tr>
     
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

	<br />	
	<br />
	<br />
	
	
	
	 
	 
	 </Typography>
	

		
        </Typography>
		
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>6. Cartesian Product ( X ) </strong></Typography><br />
    <Typography>
        
		
    <li>This type of operation is helpful to merge columns from two relations.</li>
	<li>Generally, a cartsian product is never a meaningful operation when it performs alone.</li>
    <li>However, it becomes meaningful when it is followed by other operations. </li>
	<li>Cartesian Product operation denoted by X.</li>
	<li>Notation r X s</li>
	<li>If we need the all borrowers and loan holder in Ranchi : <strong className={classes.selectiveexample}> σ branch = "Ranchi" (borrower X loan)</strong></li>
	 
    
	
		
		<br />
		<br />
		
		
		<Typography><strong>Cartesian Product Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    
	<tr>
      
      <td>E</td>
      <td>4</td>
    </tr>
    
	
   
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
<Typography class=" text-center" variant="h3" component="h4">
	 B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
	<tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
   
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 A X B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">A</th>
      <th scope="col">1</th>
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">A</th>
      <td>1</td>
      <td>C</td>
      <td>2</td>
    </tr>
	<tr>
      <th scope="row">A</th>
      <td>1</td>
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      <th scope="row">A</th>
      <td>1 </td>
      <td>E </td>
      <td>4</td>
    </tr>
    <tr>
      <th scope="row">B</th>
      <td>2</td>
	  <td>A</td>
      <td>1</td>
    </tr>
	
	<tr>
      <th scope="row">B</th>
      <td>2</td>
	  <td>C</td>
      <td>2</td>
    </tr>
	
	<tr>
      <th scope="row">B</th>
      <td>2</td>
	  <td>D</td>
      <td>3</td>
    </tr>
	
	<tr>
      <th scope="row">B</th>
      <td>2</td>
	  <td>E</td>
      <td>4</td>
    </tr>
	
	<tr>
      <th scope="row">D</th>
      <td>3</td>
	  <td>A</td>
      <td>1</td>
    </tr>
	
	
	
	<tr>
      <th scope="row">D</th>
      <td>3</td>
	  <td>C</td>
      <td>2</td>
    </tr>
	
	
	
	<tr>
      <th scope="row">D</th>
      <td>3</td>
	  <td>D</td>
      <td>3</td>
    </tr>
	
	
	
	<tr>
      <th scope="row">D</th>
      <td>3</td>
	  <td>E</td>
      <td>4</td>
    </tr>
	
	
	
	<tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>A</td>
      <td>1</td>
    </tr>
	
	
	
	<tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>C</td>
      <td>2</td>
    </tr>
	
	
	
	
	<tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>D</td>
      <td>3</td>
    </tr>
	
	
	
	
	<tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>E</td>
      <td>4</td>
    </tr>
	
	
	
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
		 
	 </Typography>

        </Typography>
		
	




	
	<br />
<Typography variant="h5" component="h6"><strong>7. Join Operator ( ⋈ ) </strong></Typography><br />
    <Typography>
        
		
    <li>Join operation is essentially a Cartesian product followed by a selection criterion.</li>
	<li>Join operation denoted by ⋈.</li>
    <li>JOIN operation also allows joining variously related tuples from different relations.</li>
    
	
		
		<br />
		<br />
		
		
		<Typography><strong>Types of JOIN: </strong>
		<br />
		<br />
		Various forms of join operation are:</Typography>
		<br />
		
		<li><strong>Inner Joins:</strong></li>
		<ul>
		<li>Theta join</li>
        <li>EQUI join</li>
        <li>Natural join</li>
		</ul>
		<br />
		
		
		<li><strong>Outer join:</strong></li>
		<ul>
		<li>Left Outer Join</li>
        <li>Right Outer Join</li>
        <li>Full Outer Join</li>
		</ul>
		<br />
		
		
		<Typography><strong>Join Operation Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">Col a</th>
      <th scope="col">Col b</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>A</td>
      <td>1</td>
    </tr>
	<tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
    <tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
	  <td>F</td>
      <td>4</td>
    </tr>
	<tr>
	  <td>E</td>
      <td>5</td>
    </tr>
	
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
<Typography class=" text-center" variant="h3" component="h4">
	 B
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">Col a</th>
      <th scope="col">Col b</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>A</td>
      <td>1</td>
    </tr>
	<tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
    <tr>
      
      <td>D </td>
      <td>3</td>
    </tr>
    
	
      <tr>
      <td>E </td>
      <td>4</td>
    </tr>
    
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 A ⋈ B
	  </Typography>
	  
<Typography class=" text-center" variant="h3" component="h4">
	 A.Col a = B.Col a
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">A</th>
      <th scope="col">1</th>
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">D</th>
      <td>3</td>
      <td>D</td>
      <td>3</td>
    </tr>
	
    <tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>E</td>
      <td>4</td>
    </tr>
  </tbody>
</table>



<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 A ⋈ B
	  </Typography>
	  
<Typography class=" text-center" variant="h3" component="h4">
	 A.Col b = B.Col b
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">A</th>
      <th scope="col">1</th>
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">B</th>
      <td>2</td>
      <td>C</td>
      <td>2</td>
    </tr>
	
    <tr>
      <th scope="row">D</th>
      <td>3</td>
	  <td>D</td>
      <td>3</td>
    </tr>
	
	
	<tr>
      <th scope="row">F</th>
      <td>4</td>
	  <td>E</td>
      <td>4</td>
    </tr>
  </tbody>
</table>




	<br />	
	<br />
	 <br />
	
	 </Typography>
	
	
        </Typography>
		
			<br />
			<br />
			<br />
			
			
			
			
	
	<br />
<Typography><strong>Natural Join </strong></Typography><br />
    <Typography>
        
		
    <li>The JOIN involves an equality test, and thus is often described as an equi-join. Such joins result in two attributes in the resulting relation having exactly the same value. A `natural join' will remove the duplicate attribute(s).</li>
	<li>In most systems a natural join will require that the attributes have the same name to identify the attribute(s) to be used in the join. This may require a renaming mechanism.</li>
    <li>If you do use natural joins make sure that the relations do not have two attributes with the same name by accident</li>
    
	
		
		<br />
		<br />
		
		
		<Typography><strong>Outer Join </strong></Typography>
		<br />
		
		<li>There are three forms of the outer join, depending on which data is to be kept.</li>
		<li>LEFT OUTER JOIN - keep data from the left-hand table.</li>
		<li>RIGHT OUTER JOIN - keep data from the right-hand table.</li>
		<li>FULL OUTER JOIN - keep data from both tables.</li>
		
		
		
		<br />
		<br />
		<br />
		<br />
		
		<Typography><strong>LEFT & RIGHT OUTER JOIN Example</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	 R
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">Col A</th>
      <th scope="col">Col B</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>A</td>
      <td>1</td>
    </tr>
	<tr>
      
      <td>B</td>
      <td>2</td>
    </tr>
    <tr>
      
      <td>D</td>
      <td>3</td>
    </tr>
    <tr>
      
	  <td>F</td>
      <td>4</td>
    </tr>
	<tr>
	  <td>E</td>
      <td>5</td>
    </tr>
	
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
<Typography class=" text-center" variant="h3" component="h4">
	 S
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">SCol A</th>
      <th scope="col">Sol B</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>A</td>
      <td>1</td>
    </tr>
	<tr>
      
      <td>C</td>
      <td>2</td>
    </tr>
    <tr>
      
      <td>D </td>
      <td>3</td>
    </tr>
    
	
      <tr>
      <td>E </td>
      <td>4</td>
    </tr>
    
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	

	 
	 
<Typography class=" text-center" variant="h3" component="h4">
	 R LEFT OUTER JOIN
	  </Typography>
	  
<Typography class=" text-center" variant="h3" component="h4">
	  R.Col A = S.SCol A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">A</th>
      <th scope="col">1</th>
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">D</th>
      <td>3</td>
      <td>D</td>
      <td>3</td>
    </tr>
	
    <tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>E</td>
      <td>4</td>
    </tr>
	
	
	<tr>
      <th scope="row">B</th>
      <td>2</td>
	  <td></td>
      <td></td>
    </tr>
	
	
	<tr>
      <th scope="row">F</th>
      <td>4</td>
	  <td></td>
      <td></td>
    </tr>
	
	
	
  </tbody>
</table>


<br />
<br />
<br />
	

<br />
	 <br />
	

<Typography class=" text-center" variant="h3" component="h4">
	 R RIGHT OUTER JOIN
	  </Typography>
	  
<Typography class=" text-center" variant="h3" component="h4">
	 R.Col A = S.SCol A
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">A</th>
      <th scope="col">1</th>
      <th scope="col">A</th>
      <th scope="col">1</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <th scope="row">D</th>
      <td>3</td>
      <td>D</td>
      <td>3</td>
    </tr>
	
	
    <tr>
      <th scope="row">E</th>
      <td>5</td>
	  <td>E</td>
      <td>4</td>
    </tr>
	
	
	<tr>
      <th scope="row"></th>
      <td></td>
	  <td>C</td>
      <td>2</td>
    </tr>
  </tbody>
</table>




	<br />	
	<br />
	 <br />
	
	 </Typography>
	
	
        </Typography>
		
						
			
			
			<Typography> <strong>Objective Questions</strong></Typography><br />
	 <Typography>
	 <ol>
    <li>Who is the Father of DBMS? <br /> Ans :- Edgar.F.Codd</li>
    <li>Which is used to denote the selection operation in relational algebra? <br /> Ans :- Sigma (Greek)</li>
    <li>How is the left outer join symbol represented in relational algebra? <br /> Ans :- ⟕ </li>
   
</ol>
     
	</Typography>
  	<br />
			
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Entitiesindbms" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Relationalalgebraarticle;