import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';

import { Link, NavLink } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/java/images/javahackingtruth.png";
import webimages1 from "../../../../../src/MyComponents/home/java/images/download-apache-tomcat-hackingtruth.png";
import webimages2 from "../../../../../src/MyComponents/home/java/images/tomcat-installation.png";
import webimages3 from "../../../../../src/MyComponents/home/java/images/conf.png";
import webimages4 from "../../../../../src/MyComponents/home/java/images/jdk-hackingtruth.png";
import webimages5 from "../../../../../src/MyComponents/home/java/images/env.png";
import webimages6 from "../../../../../src/MyComponents/home/java/images/startup-hackingtruth.png";
import webimages7 from "../../../../../src/MyComponents/home/java/images/run-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Genericservletsandhttpservlet = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Java </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>13 April 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>GenericServlets and HTTPServlet</strong></Typography><br />
	 <Typography>
	 The generic servlets class define a generic 
football independence servlets in the sense that it can be extended to provide 
implementation of any protocol such as HTTP, FTP, and SMTP.
<br />
<br />
public void Service (javax.servlet.ServletRequest request, javax.servlet.ServletResponse.ServletException, java.io.IOException;
<br />
<br />

This method accept to arguments request in capsules the client request and
 each used to extract information from the client request.
<br />
On the other hand response contains information to be sent back to the client.

<br />
The HTTPServlets class extends GenericServlet and provides a framework for handling http request.



     
	</Typography>
  	<br />
  	<br />
  	<br />
	

	
		
		
		<Typography variant="h5" component="h6"><strong>The list of all methods are httpServlet </strong></Typography><br />
    <Typography>
        
		<li>GET = doGet()</li>
<li>POST = doPost()</li>
<li>PUT = doPut()</li>
<li>DELETE = doDelete()</li>
<li>TRACE = doOptions()</li>
		
		
<br />
<br />


Each of these methods take to arguments and HTTPServletRequest type object.
<br />
<br />
<b>Following is the prototype of the doGet() method</b>
<br />
<br />
Void doGet(HTTPServletRequest request, HTTPServletResponse response)
<br />
<br />

<ol>
<li>
The HTTPServletRequest interface extends the ServletRequest, interface 
and provides all the functionalities of the ServletsRequest interface, 
Additionally it provides method to Retrieve HTTP headers, cookies sent 
and other HTTP-specific information.</li>

<li>
The HTTPServletResponse interface extends the ServletsResponse 
interface and provides all the functionality of the ServletsResponse 
interface, Additionally it provides methods to send HTTP_Specific information 
back to the client.
</li>

</ol>

<br />
<br />
<br />
<br />
<Typography variant="h5" component="h6"><strong>Servlet Architecture  </strong></Typography><br />



Servelt architecture consists of two packages : javax.servlet and javax.servlet.http
<br />
The first package contains top level interface and classes that are used and extended by all other servlets.
<br />
The second package is provided for servlets that can handle HTTP request.

		
		<br /><br /><br /><br />


</Typography>



		
		
	 

       

<br />
		<br />
		
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Lifecycleofaservlets" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Helloservlet" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Genericservletsandhttpservlet;