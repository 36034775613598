import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/sorting1.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/sorting2.png";
import webindex3 from "../../../../../src/MyComponents/home/Datastructure/images/sorting3.png";
import webindex4 from "../../../../../src/MyComponents/home/Datastructure/images/sorting4.png";


import webindex5 from "../../../../../src/MyComponents/home/Datastructure/images/sorting5.png";
import webindex6 from "../../../../../src/MyComponents/home/Datastructure/images/sorting5.png";


import webindex7 from "../../../../../src/MyComponents/home/Datastructure/images/sorting6.png";
import webindex8 from "../../../../../src/MyComponents/home/Datastructure/images/sorting6.png";
import webindex9 from "../../../../../src/MyComponents/home/Datastructure/images/sorting6.png";


import webindex10 from "../../../../../src/MyComponents/home/Datastructure/images/sorting7.png";
import webindex11 from "../../../../../src/MyComponents/home/Datastructure/images/sorting7.png";
import webindex12 from "../../../../../src/MyComponents/home/Datastructure/images/sorting8.png";

import webindex13 from "../../../../../src/MyComponents/home/Datastructure/images/sorting8.png";
import webindex14 from "../../../../../src/MyComponents/home/Datastructure/images/sorting9.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Sorting = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Sorting in Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>27 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Linear in Data Structure</strong></Typography><br />
	<Typography variant="h5" component="h6"> <strong>Sorting</strong></Typography><br />
	


	<Typography>
    
Sorting is a method to arrange N elements array in a particular format either asscending or descending
order.
<br />
<br />
<b>Types of Sorting </b>
<br />	
<br />
<ul>


<li>Bubble Sort</li>

<li>Selection Sort</li>

<li>Insertion Sort</li>

<li>Quick Sort</li>

<li>Heap Sort</li>

<li>Redix Sort</li>

<li>Shell Sort</li>

<li>Bucket Sort</li>
</ul>	
<br />
<br />
<br />
 <b>There are two different categories in Sorting - </b>
<br />
		
<br />
<ol>
<li><b>Internal Sorting</b> - If the input data is adjusted in the main memory at once. It is called internal
sorting.</li>


<li><b>External Sorting</b> - If the input data is such that it cannot be adjusted in the memory entirely at once,
it needs to floppy disk, or any other storage device. This is called external sorting.
</li>

</ol>
	
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Selection Sort</strong></Typography><br />
    <Typography>
      <ul>
	  
The list is divided into two sublists, sorted and unsorted.
<br />
<br />
<b>Step 1</b> :- We repeatedly find the next smallest elements in the array.
<br />
<b>Step 2</b> :- Swap the smallest elements and elements in the first position.
<br />
<b>Step 3</b> :- The process stop when the effective size of the array becomes 1.
        
	  </ul>
    <br />
 

	<br /><Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       

	
	
<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       

<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       

<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
	
	
<b>Algorithm</b>
<br />
<br />
		<br />
		
		<pre>
		
	SelectionSort(A) <br />
  &nbsp;&nbsp; for (i=0; i&lt;n-1; i++) <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&#123; <br /> 
	     &nbsp;&nbsp;&nbsp;&nbsp;int min = i; <br />
		 
		 &nbsp;&nbsp;&nbsp;&nbsp;for (j=i+1; j&lt;n; j++) <br />
		  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&#123; <br /> 
		 &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; if (a[j] &lt; a [min]) <br />
		&nbsp;   &nbsp;&nbsp;  min = j; <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125; <br /> 

        &nbsp;&nbsp;&nbsp;if (min != i) <br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123; <br />
			 &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Swap(a [i], a [min]); <br />
			&nbsp;&nbsp;&nbsp;&nbsp; &#125; <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&#125; <br />
	  
		
		</pre>
		
		<br />
		<br />
		
        </Typography>
		
	
		
	
	
	<br />
	
	<Typography><strong>Intsertion Sort</strong></Typography><br />
    <Typography>
       
  
An element which is to be inserted n this sorted sublist, has to find its appropriate place and 
then it has to be inserted there.
<br />
<br />
The algorithm is not suitable for large data sets as it average and worst case complexity are of
O(n^2), where n is the number of items.	  
	  
	  
		
		</Typography>
			<br />
		<br />
		<br />
		<Typography><strong>How Insertion sort works? </strong></Typography><br />
    <Typography>
      
<b>Step 1</b> :- If it is the first element it is already sorted, return 1.
<br />

<b>Step 2</b> :- Pick next element.
<br />
<b>Step 3</b> :- Compare with all elements in the sorted sublist.
<br />
<b>Step 4</b> :- Shift all the elements in the sorted sub-list that is greater than the value to be sorted.
<br />
<b>Step 5</b> :- Insert the value.
<br />
<b>Step 6</b> :- Repeat until list is sorted.
<br />
<br />
We take an unsorted array for our example 

<br />
<br />
<Typography>
<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />






Insertion sort compares the first two element.


<br />
<br />
<Typography>
<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />

Now 8 and 5 are in ascending order, For now 5 is in sorted sublist.

		   
	   
<br />
<br />
<Typography>
<img src={webindex7} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />

Insertion sort moves ahead and compares 8 and 9.
<br />
<br />
<Typography>
<img src={webindex8} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />



If find that 8 and 9 are already in ascending order for now 8 is in shorted sublist.
<br />
<br />

Next, it compares 9 and 2.


<br />
<br />
<Typography>
<img src={webindex9} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />


The values are not sorted so we swap them.



<br />
<br />
<Typography>
<img src={webindex10} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />


However, swapping makes 8 and 2 unsorted.



<br />
<br />
<Typography>
<img src={webindex11} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />




Hence, we swap them too.


<br />
<br />
<Typography>
<img src={webindex12} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />




Again , we can see that swapping makes 5 and 2 unsorted.

<br />
<br />
<Typography>
<img src={webindex13} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />

 

Again we swapp them too.

<br />
<br />
<Typography>
<img src={webindex14} className = {classes.myfirstheading} alt="hackingtruthbanner" />	
</Typography>
     
<br />
<br />





		</Typography>
	<br />
		

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Lineardatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Datastructure" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Sorting;