import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Zoom  } from "@material-ui/core";
//import { categories } from "../../Categoriesconstant/Data";
import { Link, NavLink } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)',
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
		
	}
	
	
})

const Htmlcategorieshome = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Webcreateview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <NavLink to="/"><TableCell>
		 
		All Categories
		
		 </TableCell></NavLink>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	  	  
	  
				    <TableRow>
				  
				  
				  <NavLink to="/Webdevelopmenthome"><TableCell>	Web Development  </TableCell></NavLink>

	              </TableRow>
				  
				  
				    <TableRow>
				  
				   <TableCell>	HTML  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				   <Tooltip TransitionComponent={Zoom} title="HTML Basics"><NavLink to="/Htmlbasicarticle"><TableCell>	HTML Basics  </TableCell></NavLink></Tooltip>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	HTML Elements  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	HTML Attributes  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	HTML Heading </TableCell>

	              </TableRow>	  
				  
				  <TableRow>
				  
				  
				  <NavLink to="/Htmlcardbox"><TableCell>	HTML Card Box </TableCell></NavLink>

	              </TableRow>	  
				  
				   <TableRow>
				  
				  
				  <NavLink to="/Htmlsidenavbar"><TableCell>	HTML SideNav Bar </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	HTML Paragraph  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	HTML Style  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell> HTML Formatting  </TableCell>

	              </TableRow>	  
				    
				  
		 
		</TableBody>
	 </Table>
	
	
   
	
	</>
   )
};

export default Htmlcategorieshome;