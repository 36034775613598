import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/jquerypathhackingtruth.png";
import webindex2 from "../../../../../src/MyComponents/home/webdevelopment/images/jquerywgethackingtruth.png";
import webindex3 from "../../../../../src/MyComponents/home/webdevelopment/images/wget-jquery-download-file.png";
import webindex4 from "../../../../../src/MyComponents/home/webdevelopment/images/click-on-hackingtruth-event.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jqueryclickevent = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Javascript jQuery</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Jquery Click Event </strong></Typography><br />
	 <Typography>
    

<li>HTML document is very easy to use in Jquery with click event method or function, you can say that it's a totally
    documentation with jquery rather than only jquery, so otherwise now we will use jquery with &lt;p&gt;
  tag. So, lets get started.	.</li>

<br />
So, first of all if you use windows with jquery so first you can download "Wget" tool for downloading "jquery" files and then
  you will place that file where your main file is. (like para.html)

   
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Step to Reproduce</strong></Typography><br />
    <Typography>
       
<ol>
<li>Download <strong>wget</strong> utitlity tool (<strong>wget</strong> network utility tool for retrieve files from the World Wide Web using HTTP and FTP on command propmpt. <br />
<a href="https://eternallybored.org/misc/wget/">Click Here for download wget utility tool</a> </li><br /> <br />
<li>After downloading the wget zip file or exe file and we need to determine where to copy WGET.exe and then we need to find out which directory that should be. Type: "path" in command prompt without quotes.</li>

<br />


<li>If you’re a Linux or Mac user, WGET is either already included in the package you’re running or it’s a trivial case of installing from whatever repository you prefer with a single command.</li>
<br />

You should see something like this:
<br />
<br />
<br />
<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br /><br />
		Thanks to the “Path” environment variable, we know that we need to copy wget.exe to the c:\Windows\System32 folder location.
<br />
<br />
Go ahead and copy WGET.exe to the System32 directory and restart your Command Prompt.
<br /><br />
Restart command terminal and test WGET
<br />
<br />
If you want to test WGET is working properly, restart your terminal and type:
<br />
<br />
<strong>wget -h</strong>
<br />
<br />
If you’ve copied the file to the right place, you’ll see a help file appear with all of the available commands.
<br />
So, you should see something like this:
<br /><br /><br />


<Typography>
<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />


</Typography>
<br />
<br />







<li>We need to download jquery file via wget (because its a easy method to download any file) <br /> Type in command prompt:<br /> <br />wget https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js <br /> <br />Something like this- <br /><br />



<br />
<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />

<br /><br /><br />




</li>
<li>Now we will use (jquery.min.js) file anywhere where you want to use :-)</li>

</ol>

		
		<br />
		<br />
		
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>Click Event</strong></Typography><br />
    <Typography>
     <br />


<pre>

&lt;!DOCTYPE html&gt;  <br />
&lt;html&gt;  <br />
&lt;head&gt;  <br />

&lt;script src="jquery.min.js"&gt;&lt;&#47;script&gt;   <br />  <br />

&lt;&#47;head&gt;  <br />
&lt;body&gt;  <br />  <br />

&lt;p&gt;Click me on this paragraph.&lt;&#47;p&gt;   <br />

&lt;&#47;body&gt;  <br />  <br />

&lt;script&gt;  <br />
$(document).ready(function()&#123;  <br />
  $("p").click(function()&#123;  <br />
    alert("The paragraph was clicked (hackingtruth.in)");  <br />
  &#125;);   <br />
&#125;);  <br />
&lt;&#47;script&gt;   <br />  <br />
&lt;&#47;html&gt;  <br />


</pre>

  <br />  <br />  <br />
  <strong> You can also use this line of code..something like this in between &lt;head&gt; open and &lt;&#47;head&gt; close without downloading any file of jquery.</strong>
      <br />  <br />
	  
	&lt;script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;
	
	  &lt;&#47;script&gt;
  
<br />
<br />
<br />
<br />




	      
	   
	   
	   
<li><strong>Explaination and Some Suggestions</strong></li><br />
<ul>
<li>You can use jquery file in both the ways by downloading and without downloading. so always use before closing &lt;&#47;head&gt; tag. </li>
<li>Always write the jquery script after closing the body tag. It will happen that you ust have seen that when
   you have must seen that when the website load on the internet, it takes a lot of time, the biggest reason for this can be javascript or jquery script.</li>
<li>Document is html file and thats why we use with &lt;p&gt; tag. when someone click on paragraph, the function will call alert method and then generate a popup.</li>
</ul>
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
			<br />
	
	
	<Typography><strong>Hover Event </strong></Typography><br />
    <Typography>
       


When mouse hover on paragraph then generate a popup alert box. <br />
<br />
<pre>

&lt;!DOCTYPE html&gt;  <br />
&lt;html&gt;  <br />
&lt;head&gt;  <br />

&lt;script src="jquery.min.js"&gt;&lt;&#47;script&gt;   <br />  <br />

&lt;&#47;head&gt;  <br />
&lt;body&gt;  <br />  <br />

&lt;p&gt;Click me on this paragraph.&lt;&#47;p&gt;   <br />

&lt;&#47;body&gt;  <br />  <br />

&lt;script&gt;  <br />
$(document).ready(function()&#123;  <br />
  $("p").hover(function()&#123;  <br />
    alert("Popup generated (hackingtruth.in)");  <br />
  &#125;);   <br />
&#125;);  <br />
&lt;&#47;script&gt;   <br />  <br />
&lt;&#47;html&gt;  <br />


</pre>



		<br />
		<br />
		
		
        </Typography>
		
		
		
			<br />
	
	
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jqueryeventlist" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Jqueryhoverevent" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jqueryclickevent;