import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/dbms/images/DBMShackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Foreignkeyindbms = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Database Management System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>3 March 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>DBMS - Foreign Key</strong></Typography><br />
	 <Typography>
    It is an attribute or set of attributes that reference to primary key of same table or another table (relation).<br />

    
     
	</Typography>
  	<br />
	
	<br />
	<Typography variant="h5" component="h6"><strong>Attributes or Set of Attributes</strong></Typography><br />
	  <Typography>
       
	   Attributes or Set of Attributes primary key can be simple or composite.
	   <br />
	   <br />
	   If a table has a primary key, it can be a primary key consiting of one attributes or more than one attributes.
	   
		<br />
		Primary key always reference it takes reference from the primary key or same table or another table.
		<br />
		<br />
		
		<strong>Q) Which key is actually used to maintain the refertial Integrity</strong><br />
		Ans - It's a foreign key.
        </Typography>
		
		
		
		<br />
		<br />
		
		
		
		
		
	
	<br />
	
	
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Primary key 
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Roll no</th>
      <th scope="col">Name</th>
      <th scope="col">Adress</th>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Atul</td>
      <td>Ranchi</td>
      
    </tr>
	<tr>
      <th scope="row">2</th>
      <td>HackerBoY</td>
      <td>Chennai</td>

    </tr>
    <tr>
      <th scope="row">3</th>
      <td>HackingTruth </td>
      <td>Mumbai </td>
     
    </tr>
    
  </tbody>
  <caption class="text-center">This is Base table (referenced table)</caption>
</table>


<Typography></Typography><br />
    <Typography>
        
	
		</Typography>
	
	<br />
   <Typography>
        
		
		
    <Typography>
        
	<br />
	<br />
	
	<table class="table table-dark table-hover">
	 	 
  <thead>
    <tr>
      <th scope="col">Course_id</th>
      <th scope="col">Course_Name</th>
      <th scope="col">Roll no</th>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">c1</th>
      <td>Ethical Hacking</td>
      <td>1</td>
      
    </tr>
	<tr>
      <th scope="row">c2</th>
      <td>Web Deve</td>
      <td>2</td>

    </tr>
   
  </tbody>
  <caption class="text-center">This is Course table (referencing table) table</caption>
</table>




	<br />	
	<br />
	<strong>NOTE </strong>: In this referencing table we can say that "Roll no" is a foreign key.
	
	
	 <br />
	 <br />
	 <li>Both the tables should have an attribute in common then we can say that its foreign key.</li>
	 <li>Common is "Roll no" and if you want you can also make the "Course_id" common.</li>
	 <li>If you want to write the Roll no 10 in course table then first you have to write the roll no 10 in the student table.</li>
	 <li>The "Roll no 10" will already be written as "10" in the student table, only then you can write as a "Roll no 10" in the student table. </li>
	 <li>The roll no 10 will already be written as 10 in the student table, only then you can write Roll no as a 10 in the course table.</li>
		
        </Typography>		
        </Typography>
		

	<br />
	
		Lets write some query ...
		<br />
		<br />
		<pre>
		CREATE table course <br />
		( <br />
		Course_id varchar(10), <br />
		Course_Name varchar(20), <br />
		Roll no int reference <br />
		student (Roll no) <br />
		);<br />
		
		
		</pre>
		
		<br />
		<br />
		In case after the table means already table created but now i want to take foreign key.
	
	<br />
<Typography ></Typography><br />
    <Typography>
        
	 <pre>
	 ALTER table course ADD constraint fk <br />
	     foreign key (Roll no) reference student (Roll no);
	 
	 
	 </pre>
	 <br />
	 <br />
	 <strong>NOTE :</strong> fk is a name<br />
	 <strong>NOTE :</strong> There can be more than one foreign key in a table but there is only one primary key in  a table.
		</Typography>
		<br />
		<br />
	
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Primarykeyindbms" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Foreignkeyindbms;