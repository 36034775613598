import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Softwareprocessmodel = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>19 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Software Process Model</strong></Typography><br />
	 <Typography>
    <li>A software process model is simplified representation of a software process.</li>
    <li>DEach model represents a process from a specific perspective.</li>
    
<li>Some methodology are some items known as software development life cycle.</li>

<br />
<br />
<br />
<strong>A model will define the following</strong>
     <br />
<br />
<ol>
<li>The task to be performed.

</li>
<li>The input and output of each task.</li>

</ol>
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Factors in choosing a software process</strong></Typography><br />
    <Typography>
       
    <ol>	   
	<li>Project requirements</li>
 <li>Project Size</li>
<li>Project complexity</li>
    <li>Cost of delay</li>
    <li>Customer involvment</li>
    <li>Familiarity with technology</li>
    <li>Project Resources</li>
    
   
	</ol>
		<br />
		<br />
		<br />
		
		<Typography><strong>Project Requirements</strong></Typography><br />
    <Typography>
       
    	   
	Before going move forwarded, take some time to go through the project
requirements and clarify them alongside your orgranizations or team 
expectations.
 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		
		<Typography><strong>Project Size</strong></Typography><br />
    <Typography>
       
   Consider the size of be working on larger you will be working on. Larger
project means bigger team, so you'll need more extensive and elaborate
project management plans.
 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		<Typography><strong>Project Complexity </strong></Typography><br />
    <Typography>
       
   Time to time complext projects may not have clear requirements. The requirements
may change often and the cost of delay is high.
 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		
		<Typography><strong>Cost of delay </strong></Typography><br />
    <Typography>
       
   Is the project highly time bound with a huge cost of dealy or are the timelines flexible ?

 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		
		<Typography><strong>Customer Involvment  </strong></Typography><br />
    <Typography>
       
  Do you need to consult customer during the process?
Does the user need to participate in all phases ?
 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		<Typography><strong>Familiarity with Technology  </strong></Typography><br />
    <Typography>
       
  This involve the developers knowledge and experience with the project
domain, software tools, language methods needed for development.
 
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
		
			
		<Typography><strong>Project Resources </strong></Typography><br />
    <Typography>
       
  This involves the amount and availability of funds, staff and other resources.

 
   
	<br /><br /><br /><br />
		<br />
		<strong>There are two types of process model </strong>
		<br />
		<br />
		<ol>
		<li>Waterfall process model</li>
   <li>Prototyping process model</li>
		
		</ol>
		
		
		
        </Typography>
		
		
			
		
		
		
		
		<br />
		<br />
		<br />
		
		
		
		
		
		
		
		
        </Typography>
		
		
		
		<Typography variant="h5" component="h6"><strong>Waterfall Process Model</strong></Typography><br />
    <Typography>
        
 The waterfall process model is a sequential plan driven process where you
must plan and schedule all your activites before starting the project.


Each activity in the waterfall model is represented as a separate phases
arranged in linear order.

<strong>It has the following phases</strong>

		<br />
		<br />
	 <ol>
<li>Requirement</li>
<li>Design</li>
<li>Implementations</li>
<li>Testing</li>
<li>Deployment</li>
<li>Maintaintance</li>
	</ol>	<br />	<br />	
		 
	
		Each of these phases produces one or more documents that need to be 
approved before the next phase begins.


However, in these phases are very likely to overlap and may feed information
to one another.

		<br /><br /><br />
		
		
        </Typography>
		
		
		
	
	
	<br />
<Typography variant="h5" component="h6"><strong>Prototyping Process Model</strong></Typography><br />
    <Typography>
    <br />
  A prototype is a version of a system or part of the system that developed
quickly to check the customer's requirements of some desing decisions.


<br />
So, prototype is useful when a customer or developer is not sure of the 
requirements or of algorithms, efficiency business rules, response time etc.


   <br />
		<br />	

		<br />
		
		
	
		
		
		</Typography>
			
		
	
	<br />
<Typography variant="h5" component="h6"><strong>A software prototype can be used</strong></Typography><br />
    <Typography>
    
 In the requirements engineering, a prototype can help with the elicitation
and validation of system requirements.

<br />
<br />
In the system desing -

A prototype can help to carry out design experiments to check the feasibility
of a proposed design.



		<br />	

		<br />
		
		
		<br />	

		<br />
		
		
	
		
		
		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Softwareprocessmodel;