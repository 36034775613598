import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/dbms/images/DBMShackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	
	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Primarykeyindbms = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Database Management System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>3 March 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>DBMS - Primary Key</strong></Typography><br />
	 <Typography>
    PRIMARY KEY = &#123; UNIQUE + NOT NULL &#125; <br />

   

     
	</Typography>
  	<br />
	
	<br />
	<Typography variant="h5" component="h6"><strong>Key </strong></Typography><br />
	  <Typography>
       
	   Each property has a unique key, which is called a key.
	   <br />
		
		
        </Typography>
		
		<br />
		<br />
		
		
		
	
	<br />
	
	
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Table
	  </Typography>
	  
	  	<Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">S_id</th>
      <th scope="col">Name</th>
      <th scope="col">Login</th>
	  <th scope="col">Age</th>
	  <th scope="col">Gpa</th>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Atul</td>
      <td>atul@cs</td>
      <td>21</td>
      <td>8.2</td>
      
    </tr>
	<tr>
      <th scope="row">2</th>
      <td>Golu</td>
      <td>golu@cs</td>
	   <td>19</td>
	   <td>7.1</td>

    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Hackerboy </td>
      <td>hackerboy@ </td>
	   <td>22</td>
      <td>8.1 </td>
     
    </tr>
    <tr>
      <th scope="row">4</th>
      <td>Kumar</td>
	  <td>kumar@</td>
	   <td>24</td>
	  <td>9.0</td>
      
    </tr>
	
	
	 <tr>
      <th scope="row">5</th>
      <td>HackingTruth</td>
	  <td>contact@hackingtruth.org</td>
	   <td>23</td>
	   <td>4.8</td>
      
    </tr>
	
	
  </tbody>
</table>


	<br />	
	<br />
	
	<li> <strong>S_id</strong> = Filed name</li>
	<li> <strong>name, login, age, gpa =</strong> Field (Attributes, Columns)</li>
	<li> <strong>1,2,3,4,5, golu, contact@hackingtruth.org, 17, 9.0 etc</strong> = Tuples (Records, Rows)</li>
	<br />
	<br />
	<br />
	
	<strong>Unique Key</strong> = Phone number, Adhaar card, Pan card, License key, Registration number, Roll no... 
	        so, you can also say that these are <strong>Candidate keys.</strong>
	
<br />	
<br />	
	
	
	</Typography>
     
	 
<br />	
<br />	
	
    <li>It is also possible that siblings in the some collge may have given the some parent's phone number.</li>
    <li>Also possible happen that among all the students in the college, no one can have adhaar card, pan card license card.</li>
    <li>So in such a situation, using these condition key cannot be found out.</li>
    <li>To be identified, such objects much be <strong>unique key</strong> or <strong>NOT NULL</strong> in tuples.</li>
    <li>In NOT NULL case, may be students entered wrogn adhaar, pan card number  because due to take a admission so its 
	not a primary key</li>
     <li>Primary key is what we provide to our clients, users, customer, students. So, it will be called completely unique.</li>
	<li>For example : s_id, roll no, etc</li>
		<br />
		<li><strong>NOTE: </strong>s_id means student ID.</li>
		<br />
		<br />
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Entitesindbms" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Primarykeyindbms;