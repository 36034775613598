import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/infixtopostfix-2.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/infixtopostfix-next-question-2.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Infixtoprefix = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Conversion of Infix To Prefix</Typography>
	 
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Infix To Prefix</strong></Typography><br />
	 <Typography>
  An expression is called the infix expression if the operator appears in between
  the operands in the expression.
  <br />
	  <br />
	

    <br />
    <br />
    <br />
	<ul><b>infix : A + B</b><br /><b>prefix : + AB</b><br />
	<li>For eg - (A + B) * C</li><br />
	    (+ AB) * C<br />
		* + ABC
		<br />
		<br />
	<li>(A + B) / ( C - D)</li><br />
	     (+ AB) /  ( - CD) <br />
		 / + AB - CD <br />
		 / + AB - CD
		 <br />
		 <br />
	
	</ul>
	  <br />
	  <br />
	  <br />
	<ul>
	<li>A + B = operand  operator operand</li>
	<li>+ AB = operator operand operand</li>
	<br />
	<br />
	For eg - A + B * C ^ D <br />
	&lt;------- right to left calculate <br />
	Precidence priority first ( )
	<br /> ^ 
	<br /> *, /
	<br /> -, +
	
	<br />
	<br />
	A + B * ^ C D
	<br />
	A + * B ^ C D
	<br />
	+ A * B ^ C D
	</ul>



	</Typography>
	
	<br />
	
	<Typography><strong>Algorithm</strong></Typography><br />
    <Typography>
       
   <ul> 
   <li>Initially  add a left parenthesis '(' at the  beginning of the infix expression.</li>
   <li>Push ')' right parenthesis to the stack.</li>
   <li>Scan the symbol from right to left manner</li>
   <li>If scan item = operand write / add to expression.</li>
   <li>If item = operator push to stack but scanned operator precedence &lt; precedence of existing
   operator on the stack then pop the existing on the stack then pop the existing one & push.</li>
   <li>If item = '(' pop until ')' & add the operands & operator to the expression.</li>
   <li>If stack = empty stop</li>
   
   
    </ul>
<br />
		<br />
		<br />
		<br />
		<br />
		
		
		</Typography>
			<br />
		<br />
		
		<br />
		  <b> Some more related Questions </b>
		 <br />
		 Q) Updating....
		<br />
		Q) Updating....
		<br />
		
		 
		<br />
		

	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Infixtopostfix" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Postfixexpression" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Infixtoprefix;