import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/classdiagram.png";
import webindex2 from "../../../../../src/MyComponents/home/Softwareengineering/images/objectdiagram.png";
import webindex3 from "../../../../../src/MyComponents/home/Softwareengineering/images/statemachine.png";
import webindex4 from "../../../../../src/MyComponents/home/Softwareengineering/images/activitydiagram.png";
import webindex5 from "../../../../../src/MyComponents/home/Softwareengineering/images/componentdiagram.png";
import webindex6 from "../../../../../src/MyComponents/home/Softwareengineering/images/packagediagram.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Umldiagram = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>2 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>UML Diagram</strong></Typography><br />
	 <Typography>
<ul>

<li>UML Stands for Unified Modeling Language.</li>

<li>Use to create Structure or blueprint of software.</li>
<li>Example Rational Case Agro UML, Microsoft Visual office</li>


</ul>
     <br />
<br />
<strong>
Basic Building blocks of UML</strong>
<br />

<br />
<ol>

<li>Use case Diagram</li>
<li>Class Diagram</li>
<li>Object Diagram</li>
<li>Communication Diagram</li>
<li>Sequence Diagram</li>
<li>State Machine Diagram</li>
<li>Activity Diagram</li>
<li>Component Diagram</li>
<li>Package Diagram</li>
<li>Deployment Diagram</li>

</ol>
<br />
<br />
<br />
<br />
	</Typography>
  	<br />
	
		
		
		<Typography variant="h5" component="h6"><strong>Use Case Diagram</strong></Typography><br />
    <Typography>
     

It represent the operation of functionality of the system. In the diagram draw the actor use cases and the relationship.

	

		<br />
		
		
        </Typography>
		
		
		
		<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Class Diagram</strong></Typography><br />
    <Typography>
	
	
	
	
It is the collection of classes interface and their relationship classes diagram contains three components.
<br /><br />
	<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />
	<br />
	
    <br />
		
		
		</Typography>
			
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Object Diagram</strong></Typography><br />
    <Typography>

<ul>



<li>It represent the instance of class. </li>


<br />
<strong>
In object diagram threre are two components.</strong> 
<br />
<br />

<ol>
<li>In the first compartment, we have to keep object and class name. </li>
<li>
In second compartment we have to keep attribute and initial value.
</li>

</ol>
</ul>
	
<br /><br />
	<br />
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Communication Diagram </strong></Typography><br />
    <Typography>
    <br />
	
It represent the communication between two objects through messages or function.



	
    <br />

<br />


		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Sequence Diagram</strong></Typography><br />
    <Typography>
    

<br />
<br />

In sequence diagram the communication between two objects through messages
order in sequence of time.

	<br />
	<br />
	<ul>
	<li>obj.func1();</li>
	<li>obj.func2();</li>
	</ul>

		
		
	<br />
<br />
<br />
	
		</Typography>
       

		
       
	<br />
<Typography variant="h5" component="h6"><strong>State Diagram</strong></Typography><br />
    <Typography>
    


To change the state of object due to the some action is represented state machine diagram.


<br />
    <br />
	<br />
	<br />
	
<br />
		
<br />
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
  
<br />
		
		
	<br />

<br />

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Activity Diagram</strong></Typography><br />
    <Typography>
    
 <br />
	
Flow of control from one activity to another.
 <br /> <br />
In activity diagram activity starts with initial knwon as start state and activity and final
state known as end state.
 <br /> <br />
To flow of control from one activity to another activity is represented by arrow (->) sign.


	 <br />
	<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />

    <br />

		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>Component  Diagram</strong></Typography><br />
    <Typography>
    
 <br />

In components diagram we have to create a executables components by combining two ro more 
class. For eg- In order supply.exe file which is executable file which is 
the combination of <strong>order.java</strong> or <strong>order.cs</strong>

	
<br />
<br />
		<Typography>
		<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />

		

		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>Package Diagram </strong></Typography><br />
    <Typography>
    
 <br />
In package diagram we have to create the collection fo similar classes in a similar package so that we 
can use the same by importing the package in other classes.
<br />	

		
<br />
<br />
		<Typography>
		<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>Deployment Diagram</strong></Typography><br />
    <Typography>
    
 <br />
 
In deployment diagram we have to place different components of application
on a different node over a network. For eg in  case of three tiers artchitecture
there are three components.


		<br />
		<br />
Client application server or db server so we have to place this components
on different node for eg we have to place this interface in client node, we 
have to store the data inside of a data server node.


<br /><br /><br />
		
		
		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Sdlc" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Umldiagram;