import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';

import { Link, NavLink } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/java/images/javahackingtruth.png";
import webimages1 from "../../../../../src/MyComponents/home/java/images/download-apache-tomcat-hackingtruth.png";
import webimages2 from "../../../../../src/MyComponents/home/java/images/tomcat-installation.png";
import webimages3 from "../../../../../src/MyComponents/home/java/images/conf.png";
import webimages4 from "../../../../../src/MyComponents/home/java/images/jdk-hackingtruth.png";
import webimages5 from "../../../../../src/MyComponents/home/java/images/env.png";
import webimages6 from "../../../../../src/MyComponents/home/java/images/startup-hackingtruth.png";
import webimages7 from "../../../../../src/MyComponents/home/java/images/run-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Lifecycleofaservlets = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Java </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>13 April 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Life Cycle of a Servlets</strong></Typography><br />
	 <Typography>
	 The web container web server maintenance the cycle of a servlets instance. 
Let's see the life cycle of the servlets.
<br />
<br />



     
	</Typography>
  	<br />
	

	 <Typography>
       
    <ol>	   
	<li>Servlets class is loaded</li>
    <li>Servlets instance is created</li>
    <li>init method is invoked</li>
	<li>Service method is invoked</li>
	<li>Destroy method is involved</li>
    
   
	</ol>
		<br />
		<br />
	
<li>There are three states of a servlets, new, ready and end.</li>

<li>The servlets is in new state of  servlets instance is created.</li>

<li>After invoking the init() method, servlets comes in the ready State.</li>

<li>In the ready state servlets perform all tasks.</li>

<li>The web container (web server) invokes the destroy method it shift to the end State.</li>


		<br />
		<br />
	<b>OR</b>
		<br />
		<br />
		
<li>The init() methods initializes servlets.</li>

<li>The service() method serves the client requests, and the destroy method.</li>

<li>The destroy() Method shuts the servlets down.</li>
        <br />
		<br />
        
		<ol>
		<li><b>Servlets class is loaded</b> - The class loader is responsible to load the servlets, 
the servlets class is loaded when the first request for the servlets is received by 
the web container.</li><br />
		<li><b>Servlets instance is created</b> -
The wave container creates the instance of a servlets after loading the servlets 
class. The servlets instance is created only once in the servlets life cycle.</li><br />
		<li><b>init() Method is invoked </b> - The web container the init () method only once. 
After creating the servlets instance. The init() method is used to initialize 
the Servlets.
<br />

<b>For example</b> - public void init(ServletsConfig) throws ServletsException</li>
		<br />
		<li><b>Service() method is invoked</b> - The web container calls the servlets method each 
time when request for the servlets is received. If servlets is not inised it follow 
the first three steps as described right side then call the service method.

If servlets is initialised, it calls the service method notice that servlets is 
initialised only once.</li>
		<br />
		
		<b>The syntax - </b>
		public static service (Servlets Request request, ServletsResponse response) 
		throws ServletsException, IOException
		<br />
		<br />
		
		<li> <b>Destroy () Method is invoked </b> - 

The web container calls the destroy method before removing the Servlets instance 
from the service. It gives the Servlets an opportunity to clean up any resources 
for to clean up any resources.
<br />
<br />
For example memory, threads etc.

public void destroy() 
<br /><br /></li>

		</ol>
		
	     
		</Typography>
		
		
		<Typography variant="h5" component="h6"><strong>Download Apache Tomcat </strong></Typography><br />
    <Typography>
        
		<ol>
    <li>First download the tomcat (Apache Tomcat) web server on browser tomcat for 
windows 7.10 whatever.
<br /><br />
<b>https://tomcat.apache.org/download-10.cgi</b>
<br />
<br />
</li>
    <li>Scroll down and go to the binary distribution section (core) and download 
the 32 bit/64 but windows Service installer (bgp sha512).</li>
    
</ol>
<br />
<br />
 <img src={webimages1} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br />
		<br />

<br />
<br />
<br />
<br />
<Typography variant="h5" component="h6"><strong>Installation  </strong></Typography><br />

<ol>

<li>Then right click on file and run with administrator power and click next
then click on tick mark (✅) host manager, example the next.</li>
<br />
<br />
 <img src={webimages2} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br /><br />





    <li>Change HTTP/1.1 connector Port like 9090 ( it's mine case) and if you
want to change windows Service then you can add a username and password 
for managing Tom cat application and add a roles, manager_script.
<br /><br /><br />
<br />
 <img src={webimages3} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br />



<br />
<br />
Then,
<br />
<br />
</li>

<li>Please select the path of a java SE 8.0 or later JRE installed on the 
system if in case already add a JRE, JDK file, copy full path and paste 
somewhere. Then next, next ,next, (Run Apache Tomcat) and we 
will set the environment variables. (JAVA_HOME)</li>
</ol>
<br />
<br />
 <img src={webimages4} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br /><br />


		<br />
		
<br />
<br />
<Typography variant="h5" component="h6"><strong> Path Set </strong></Typography><br />

		<br />
	<ol>
	<li>Go to the MyPC, or desktop, right click on it and go to the properties
 ---&gt; Advance system settings ---&gt; Environment variable,
 then click on new button ( on user variable for system name 
 HP(it's mine case)).</li>
	
	<li>Add a variable_name JAVA_HOME and Add value c:\Program Files\java\jdk_15.0.2
</li>
	<li>Click ok ok ok ok then go to the ...
</li>
	
	</ol>
	<br />
<br />
 <img src={webimages5} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br /><br />

			
<br />
<br />
<Typography variant="h5" component="h6"><strong> Run </strong></Typography><br />

		<br />
	
	
	
	<li>After sitting the JAVA_HOME double click on the "Startup.bat" file in 
"Apache Tomcat/bin" directory and click double click on it.</li>
	
		<br />
<br />
 <img src={webimages6} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br /><br />

			
<br />
	
	
	<li>Now server is started successfully.
</li>
	<li>Run on browser - http:&#47;&#47;localhost:8080&#47;
</li>

	<li>
And manage with user & Password.
	</li>

		<br />
<br />
 <img src={webimages7} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br /><br /><br /><br />

		
	
		<br />
		
		<br />
		<br />
		
		
        </Typography>
		
		
  <br />
    
		
		<br />
		 
		 
		 

	 

       

<br />
		<br />
		
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/CGIinjava" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Genericservletsandhttpservlet" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Lifecycleofaservlets;