import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/bst1.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/bst2.png";
import webindex3 from "../../../../../src/MyComponents/home/Datastructure/images/bst3.png";
import webindex4 from "../../../../../src/MyComponents/home/Datastructure/images/bst4.png";
import webindex5 from "../../../../../src/MyComponents/home/Datastructure/images/bst5.png";
import webindex6 from "../../../../../src/MyComponents/home/Datastructure/images/bst6.png";
import webindex7 from "../../../../../src/MyComponents/home/Datastructure/images/bst7.png";
import webindex8 from "../../../../../src/MyComponents/home/Datastructure/images/bst8.png";
import webindex9 from "../../../../../src/MyComponents/home/Datastructure/images/bst9.png";

import webindex10 from "../../../../../src/MyComponents/home/Datastructure/images/bst10.png";
import webindex11 from "../../../../../src/MyComponents/home/Datastructure/images/bst11.png";
import webindex12 from "../../../../../src/MyComponents/home/Datastructure/images/bst12.png";
import webindex13 from "../../../../../src/MyComponents/home/Datastructure/images/bst13.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Binarysearchtree = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Binary Search Tree </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Binary Search in Data Structure</strong></Typography><br />
	<Typography variant="h5" component="h6"> <strong>What is Binary Tree</strong></Typography><br />
	


	<Typography>
    
Binary tree is a finite set of data items or elements which is either empty of consists of  a single item
called root and two disjoint binary tree called the left subtree and right subtree. 

<br />
<br />
In binary tree every node can have maximum of 2 children which are known as left child and right child.
<br />
<br />
Nodes are organize in levels (indexed from 0)
<br />
<b>Types of Binary Tree </b>
<br />
<br />
<br />
<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		
<ul>
<li>Full Binary Tree</li>
<li>Complete Binary Tree</li>
<li>Perfect Binary Tree</li>

</ul>
<br />
<br />

		

	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Full Binary Tree</strong></Typography><br />
    <Typography>
     A binary tree is a full binary tree if every node has 0 and 2 child.
    <br />
    <br />
	
	
		<br />
		
		
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	
	<br />
	
	<Typography><strong>Complete Binary Tree</strong></Typography><br />
    <Typography>
   A binary tree is completely binary tree if all levels are completely filled except possibly the last level
   and the last level has all keys are left as possible.
    <br />
    <br />
	
	
		<br />
		
		
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		 <br />
    <br />
	
        </Typography>
		
	
	
	
	<br />
	
	<br />
	
	<Typography><strong>Perfect Binary Tree</strong></Typography><br />
    <Typography>
     A tree in which all internal nodes has two children and all leaves are at the same level..
   	 
    <br />In which all levels has 2^n child.
    <br />
	
	
		<br />
		
		
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		 <br />
    <br />
	
        </Typography>
		
	<br />
	<br />

	<br />
	
	<Typography><strong>Perfect Search Tree</strong></Typography><br />
    <Typography>
   A node is a Data structure composed of nodes that has the following Characterstics -
    <br />
    <br />
	Each tree has a root node at the top (also known as Parent node) containing some value (can be any data type).
	<br />
	<br />
	The root node has zero or more child nodes.
	
	
		<br />
		
		 <br />
    <br />
	
        </Typography>
		
	<br />
	<br />
<br />
	
	<Typography><strong>Basic Operations on a BST</strong></Typography><br />
    <Typography>
  <ul>
  <li><b>Create</b> : Creates an empty tree.</li>
  <li><b>Insert</b> : Insert a node in the tree</li>
  <li><b>Search</b> : Searches for a node in the tree</li>
  <li><b>Delete</b> : Delete a node from the tree</li>
  <li><b>In-order</b> : In-order traversal of the tree</li>
  <li><b>Pre-order</b> : Pre-order traversal of the tree</li>
  <li><b>Post-order</b> : Post-order traversal of the tree</li>  
  
  </ul>
	
		<br />
		
		 <br />
    <br />
	<b>Implementation of BST (Binary Search Tree)</b>
	<br />
	<br />
	<pre>
	
	Struct node   <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&#123;         <br />  <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int data;   <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Struct node *left child;  <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Struct node *right child;  <br />  <br />
	
&nbsp;&nbsp;&nbsp;&nbsp;};
	
	
	
	</pre>
        </Typography>
		
	<br />
	<br />

						
	<Typography><strong>What is the height h of a full tree with N nodes?</strong></Typography><br />
    <Typography>
    2^h -1 = N <br />
	=> 2^h = N + 1 <br />
	h = log(N + 1) -> O(log N)
		<br />
		
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	<br />

									
	<Typography><strong>What is h important?</strong></Typography><br />
    <Typography>
  <ul>
  <li>The tree operations like insert, delete, retrieve etc. are typically expressed in terms of the height of the tree <b>h</b></li>
  <li>So, it can be stated that the tree height <b>h</b> determines running time!.</li>
  <li></li>
  
  </ul>
		
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	<br />

				
	<Typography><strong>How to search a binary tree?</strong></Typography><br />
    <Typography>
    <ol>
	<li>Start at the root</li>
	<li>Compare the value of item you are searching for with the value stored at the root.</li>
	<li>If the values are equal, then item found; otherwise, if it is a leaf node, then not found.</li>
	<li>If it is less than the value stored at the root, then search the left subtree.</li>
	<li>If it is greater then the value stored at the root, then search the right subtree.</li>
	<li>Repeat steps 2-6 for the root subtree chose in the previous step 4 or 5.</li>
 

	
	</ol>
	
		<br />
		
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	<br />

						
	<Typography><strong>Difference between BT and BST</strong></Typography><br />
    <Typography>
    <ul>
	<li>A binary tree is simply a tree in which each node can have at most two children.</li>
	<li>A binary search tree is a binary tree in which the nodes are assigned values, with the following restrictions.</li>
	<ol>
	<li>No duplicate values.</li>
	<li>The left subtree of a node can only have values less than the node.</li>
	<li>The right subtree of a node can only have values greater than the node and recursively defined.</li>
	<li>The left subtree of a node is a binary search tree.</li>
    <li>The right subtree of a node is a binary search tree.</li>

	
	</ol>
	</ul>
		<br />
		
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	<br />
						
	<Typography><strong>Binary Search Tree Algorithm</strong></Typography><br />
    <Typography>
    <ul>
	<li>Let x be a node in binary search tree and k is the value, we are supposed to search.</li>
	<li>Then according to the binary search tree property we know that; if y is a node in the
	left subtree of x, then y.key &lt;= x.key. If y is node in the right subtree of x, then 
	y.key &gt;= x.key. (x.key denotes the value at the node x)</li>
	<li>To search the location of given data k, the binary serch tree algorithm begins its 
search at the root and traces the path downward in the tree.	</li>
	<li>For each node x it compares the value k with x.key. If the values are equal then the
	search terminates and x is the desired node.</li>
	<li>If k is smaller then x.key, then the search continues in the left subtree of x, since the 
binary search tree property implies that k could not be in the right subtree.	</li>
	<li>Symmetrically, if k is larger then x.key, then the search continues in the right subtree.</li>
	<li>The nodes encountered during the recursion from a simple path downward from the root of the tree, and this the running timeo of TREE-SEARCH is
	O(h), where h is the height of the tree.</li>

	
	
	</ul>
		<br />
		
	
        </Typography>
		<br />
	<br />
						
	<Typography><strong>Binary Search Tree Algorithm</strong></Typography><br />
    <Typography>
	TREE-SEARCH(x, k)
    <ol>
	<li>If x==Nil or k==x.key</li>
	<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return x</li>
	<li>if k &lt; x.key</li>
	<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return TREE-SEARCH(x.left, k)</li>
	<li>else return TREE-SEARCH(x.right, k)</li>

	
	
	</ol>
		<br />
		
		 <br />
    <br />
	
        </Typography>
		
	
	<br />
	
		<br />
		
		
		<Typography>
		<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		<br />
		
		
		<Typography>
		<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		<br />
		
		
		<Typography>
		<img src={webindex7} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		<br />
		
		
		<Typography>
		<img src={webindex8} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
	<br />
		<Typography>
		<img src={webindex9} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		<br />
	
	<br />
	
	<Typography><strong>How binary search works</strong></Typography><br />
    <Typography>
   For a binary search to work, it is mandatory for the target array to be sorted. we shall learn 
   the process of binary search with a pictorial example.
    <br />
    <br />
	The following is our sorted array and let us assume that we need to search the location of value 31 using binary search.
	
	
	
		<br />
		
		
		<Typography>
		<img src={webindex10} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		First, we shall determine half of the  array by using the formula
		 <br />
    <br />
	mid = low + (high - low) / 2
	<br />
	<br />
	Here it is 0 + (9 - 0) /2
	<br />
	=> 4
	
	<br />
   So 4 is the mid of the array.
   <br />
 
   <br />
   Now, we compare the value sorted at location 4, with the value being searched ie. 31. We find
   that the value at location 4 is 27. Which is not a match.
   <br />
   <br />
   As the value is greater than 27 and we have a sorted array, so we also know that  value must be
    in the upper portion of the array.
	<br />

	<br />
		
		
		<Typography>
		<img src={webindex11} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		We change our low to <b>mid  + 1</b> and find the new mid value again.
		<br />
		<br />
		mid = low + (high - low) / 2
		<br />
		5 + (9 - 5) / 2
		<br />
		5 + (4) / 2
		<br />
		=> 7
		<br />
		<br />
		Our new mid is 7 now, we compare the value sorted at location 7 with our target value 31.
		<br />
		<br />
		
	<br />
		
		
		<Typography>
		<img src={webindex11} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		The value stored at location 7 is not a match, rather it is more than what we are looking for
		so the value must be in  the lower part from this location.
	<br />
		
		
		
		<Typography>
		<img src={webindex12} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		
		<br />
        
		Hence, we calculate the mid again. This time it is 5.
		<br />
		<br />
		mid = low + (high - low) / 2 
		<br />
		5 + (6 - 5) / 2
		<br />
		5 + (1) / 2
		<br />
		=> 5.5
		<br />
		<br />
		We compare the value stored at location 5 with our target value, we find that it is a match.
		<br />
		<br />
		
		
		
		<Typography>
		<img src={webindex13} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		
		<br />
		We conclude that the target value 31 is stored at location 5.
		</Typography>
		
	
	<br />

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Infixtoprefix" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Postfixexpression" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Binarysearchtree;