import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/1.png";

const  useStyles = makeStyles((theme) => ({
	 
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	 
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Webarticledetails = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > HyperText Markup Language</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>HTML - What is HTML?</strong></Typography><br />
	 <Typography>
    <li>HTML stands for Hyper Text Markup Language</li>
    <li>HTML is the standard markup language for creating Web pages</li>
    <li>HTML describes the structure of a Web page</li>
    <li>HTML consists of a series of elements</li>
    <li>HTML elements tell the browser how to display the content</li>
    <li>HTML elements label pieces of content such as "this is a heading", "this is a paragraph", "this is a link", etc.</li>

     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>A Simple HTML Document</strong></Typography><br />
    <Typography>
        <pre>
		
		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth&lt;/title&gt;<br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;h1&gt;My First Heading&lt;/h1&gt; <br />
        &lt;p&gt;My first paragraph.&lt;/p&gt; <br /><br />

		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		</pre>
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Example Explained</strong></Typography><br />
    <Typography>
        
		
    <li>The &lt;!DOCTYPE html&gt; declaration defines that this document is an HTML5 document</li>
    <li>The &lt;html&gt; element is the root element of an HTML page</li>
    <li>The &lt;head&gt; element contains meta information about the HTML page</li>
    <li>The &lt;title&gt; element specifies a title for the HTML page (which is shown in the browser's title bar or in the page's tab)</li>
    <li>The &lt;body&gt; element defines the document's body, and is a container for all the visible contents, such as headings, paragraphs, images, hyperlinks, tables, lists, etc.</li>
    <li>The &lt;h1&gt; element defines a large heading</li>
    <li>The &lt;p&gt; element defines a paragraph</li>

		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>What is an HTML Element?</strong></Typography><br />
    <Typography>
        A HTML element which is defined by using a start tag, some content, and end tag.
	<br />
	<br />
	
	&lt;StartTagName&gt; Content goes here (element)...&lt;/EndTagName&gt;
	
	 </Typography>
	<br />
	<br />
	<Typography>
	
	
	
<Typography className={classes.headingone}>&lt;h1&gt; My first Heading&lt;/h1&gt;</Typography>
<Typography className={classes.headingtwo}>&lt;h2&gt; My second Heading&lt;/h2&gt; </Typography>
<Typography className={classes.headingthree}>&lt;h3&gt; My second Heading&lt;/h3&gt; </Typography>
<Typography className={classes.headingfour}>&lt;h4&gt; My second Heading&lt;/h4&gt; </Typography>
<Typography className={classes.headingfive}>&lt;h5&gt; My second Heading&lt;/h5&gt; </Typography>
<Typography className={classes.headingsix}>&lt;h6&gt; My second Heading&lt;/h6&gt; </Typography>

		<br />
		&lt;p&gt;My first paragraph&lt;/p&gt; 
	    <br />
		<br />
		<br />
		
        </Typography>
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		 The purpose of a web browser (chrome, firefox, etc) is to read HTML document and display them correctly.
		 <br />
		 <br />
		 A browser does not display the HTML tags, but uses them to determine how to display the document.
		 <br />
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		
		
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
		
		
	</Box>
	</Box>
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Home</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Htmlbasicarticle" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	
	</>
	
 )
}


export default Webarticledetails;