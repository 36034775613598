import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Casetool = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>2 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>CASE Tool</strong></Typography><br />
	 <Typography>
    <ul>
	<li>CASE is stand for Computer Aided Software Engineering</li>
	<li>Software that is used to support software process activities</li>
	<li> As computer technology is being used for automatic business and industrial application, it is being used for automatic the development of the software </li>
	<li>Case rule is used for creating a difference of diagram of software system from planning to maintenance phase.</li>
	<li>There are different tools in case such as Diagram tools, user interface tools, testing tools, engineering tools, project management tools.</li>
	
	
	</ul>
	<br />
<br />
<br />
 
<strong>Diagram Tools</strong>
     <br />
<br />
By using this diagram tool we have to create class diagram of a diagram, interface diagram, class diagram, activity diagram, colloboration diagram, sequence diagram and requirement diagram.
	<br />
	<br />
	You can divide the case tools in central repository, Higher case tools, lower case tools and integrated tools.
	
	<br />
	<br />
	
	
	
	</Typography>
  	<br />
	
	<br />
	
	
	<Typography><strong>Central case tool</strong></Typography><br />
    <Typography>
       
All types of tools are stored in central repository from where we can open the tools for making different types of diagram.
	
<br />
		Used to store Graphical Diagrams & Prototype Forms and Reports of analysis and diagramming workflow Act as Information Repository Data Dictionary.
<br />
<br />
<ol>
<li>Higher Case Tools</li>
<li>Lower Case Tools</li>

</ol>		
		<br />
		
		</Typography>
			
	
	
	<br />
	
	
	<Typography><strong>Higher Case Tools</strong></Typography><br />
    <Typography>
       
	   Planning, analysis and design are known as Higher case tools. By using this tool we can create 
	   software plan, test document, analyzer document and all the other design repository diagram.
		<br />
		<br />
	     	
		
		<br />
		
		</Typography>
			
		
	
	<br />
	
	
	<Typography><strong>Lower Case Tools</strong></Typography><br />
    <Typography>
       implementation testing and maintenance testing are known as lower case tool.  We can create from the 
	   different unit and how this unit server.<br />
		<br />
	     	
		
		<br />
		
		<br />
		
		</Typography>
			
		
	
	<Typography><strong>Integrated Case Tools</strong></Typography><br />
    <Typography>
    Together all the tools are known as integrated tools. In this type we can find all types of tools in 
    a single based.	

	  <br />
	     	
		
		<br />
		
		</Typography>
			
		
       

			
		<br />
		<br />
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Functionalandnonfunctional" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Blackboxtesting" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Casetool;