import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Datastructurepostdata from "./Datastructurepostdata";
import Arrayindatastructurepostdata from "./Arrayindatastructurepostdata";
import Stackdatastructurepostdata from "./Stackdatastructurepostdata";
import Applicationofstackpostdata from "./Applicationofstackpostdata";
import Infixtopostfixpostdata from "./Infixtopostfixpostdata";
import Postfixexpressionpostdata from "./Postfixexpressionpostdata";
import Infixtoprefixpostdata from "./Infixtoprefixpostdata";
import Prefixexpressionpostdata from "./Prefixexpressionpostdata";
import Recursionpostdata from "./Recursionpostdata";
import Lineardatastructurepostdata from "./Lineardatastructurepostdata";
import Sortingpostdata from "./Sortingpostdata";
import Linkedlistpostdata from "./Linkedlistpostdata";
import Binarysearchtreepostdata from "./Binarysearchtreepostdata";

const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Datastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Datastructurepostdata />
		</Link>
		</Grid>
		
		
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Arrayindatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Arrayindatastructurepostdata />
		</Link>
		</Grid>
		
			
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Stackdatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Stackdatastructurepostdata />
		</Link>
		</Grid>
		
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Applicationofstack"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Applicationofstackpostdata />
		</Link>
		</Grid>
		
			
		
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Infixtopostfix"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Infixtopostfixpostdata />
		</Link>
		</Grid>
		
			
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Postfixexpression"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Postfixexpressionpostdata />
		</Link>
		</Grid>
		
			
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Infixtoprefix"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Infixtoprefixpostdata />
		</Link>
		</Grid>
		
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Prefixexpression"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Prefixexpressionpostdata />
		</Link>
		</Grid>
		
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Recursion"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Recursionpostdata />
		</Link>
		</Grid>
		
			
				
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Lineardatastructure"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Lineardatastructurepostdata />
		</Link>
		</Grid>
		
					
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Sorting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Sortingpostdata />
		</Link>
		</Grid>
		
			
					
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Linkedlist"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Linkedlistpostdata />
		</Link>
		</Grid>
		
			
					
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Binarysearchtree"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Binarysearchtreepostdata />
		</Link>
		</Grid>
		
			
		
		
	
	</>
	 
   )
}

export default Posts;
