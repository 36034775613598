import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/fifo.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/topfifo.png";
import webindex3 from "../../../../../src/MyComponents/home/Datastructure/images/topfifo-hackingtruth.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Stackdatastructure = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Stack Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>21 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>About Stack Data Structure</strong></Typography><br />
	 <Typography>
    Stack is a linear data structure which follows a particular order in which the operations are performed.
	The order may be LIFO (last in first out) or FIFO (first in first out).
    <br />
    <br />
	  <br />
	  	
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />


There are many real life examples of a stack consider an example of plates stacked over one another in the canteen.
<br />
The plate is which at the top is the first one to be removed. ie, The plate which has
been placed at the bottom most position remains in the stack for the longest period
of time. So, it can be simply seen to follow LIFO (lsat in first out) / FIFO (first in first out)
order.

<br />
<br />



	</Typography>
	
	<br />
	
	<Typography><strong>implementation of Stack using Array</strong></Typography><br />
    <Typography>
       
    <ul>	   
	
int arr[ 5 ];
<br />
<br />
<li>Insertion and deletion at the top of the stack only</li>
<li> Initialy when the stack is empty top = -1 </li>
<br />
<li>For push operation first the value of the top is increased by 1 and then
the new elements is pushed at the position of top. </li>
   
	</ul>
		<br />
		<br />
		if (top == 5 - 1)
			<br />
			printf("Stack is full");
		
		
		
		<br />
		<br />
		<br />
			
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		if ( top == -1 )
		<br />
	printf("Stack is empty")
	<br /> <br /> <br />
	push(70) <br />
	push(80) <br />
	push(10) <br />
	push(50) <br />
	push(30) <br />
	 <br />
	  <br />
		</Typography>
			<br />
		<br />
		<br />
		<Typography><strong>Pop Operation</strong></Typography><br />
    <Typography>
       
    	   
	
For pop operation, first the element at the position  of top is popped and then top is

decreased by 1.
<br />
<br />
pop(30) <br />
pop(50) <br />
pop(10) <br /> <br />
<br />

		
		<br />
		<br />
			
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
		<ul>
	<li>push - Check for overflow</li>
	<li>pop - check for underflow</li>
	
	</ul>
	 
<br />
<br /> 
<br />
<br />
		</Typography>
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Datastructure" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Arrayindatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Stackdatastructure;