import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/heading-hackingtruth1.png";
import webindexparagraph from "../../../../../src/MyComponents/home/webdevelopment/images/paragrapgh-hackingtruth1.png";
import webindexlinks from "../../../../../src/MyComponents/home/webdevelopment/images/links-hackingtruth.png";
import webindeximages from "../../../../../src/MyComponents/home/webdevelopment/images/images-hackingtruth.in.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jquerysyntax = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Javascript jQuery</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Javascript vs jQuery </strong></Typography><br />
	 <Typography>
    

<li><strong>Javascript via Example 1 - Hide an element with id "textbox" </strong></li>

<br />
document.getElementById('textbox').style.display = "none";

<br />
<li><strong>jQuery via Example 1 - Hide an element with id "textbox" </strong></li>

<br />
$('#textbox').hide();
<br />
<br />
<li><strong>Javascript via Example 2 - Create a &lt;h1&gt; tag with "my text"</strong></li>
<br />
   
var h1 = document.CreateElement("h1"); <br />
h1.innerHTML = "my text"; <br />
document.getElementByTagName('body')[0].appendChild(h1);

<br />   
<br />
<li><strong>jQuery via Example 2 - Create a &lt;h1&gt; tag with "my text"</strong></li>
<br />
$('body).append($("&lt;h1 /&gt;").html("my text");
<br />
<br />
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>jQuery Syntax</strong></Typography><br />
    <Typography>
       
The Basic Syntax is <strong>  $(selector).action()</strong>
<br />
<br />
<li>A $ sign to define/access jQuery.</li>
<li>A (selector) to "query" (or find)" HTML elements.</li>
<li>A jQuery action() to to performed on the element(s).</li>

<br />
<br />
<h5><strong> Examples: </strong></h5>

<li>$(this).hide() - hides the current element.</li>
<li>$("p").hide() - hides all &lt;p&gt; elements</li>
<li>$("test").hide() - hides all elements with class="test".</li>
<li>$("#test").hide() - hides the element with id="test".</li>


		
		<br />
		<br />
		
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>Enable jQuery in your page</strong></Typography><br />
    <Typography>
       
<strong> jQuery can be enabled in your page by including reference to jQuery library file.</strong>
<br />
<br />
<li>&lt;script src="http:&#47;&#47;ajax.googleapis.com&#47;ajax&#47;&#47;libs&#47;jQuery&#47;3.5.1&#47;jQuery.min.js"&gt;&lt;&#47;script&gt;</li>

<br />
<br />
<h5><strong> Examples: </strong></h5>

<li>$(this).hide() - hides the current element.</li>
<li>$("p").hide() - hides all &lt;p&gt; elements</li>
<li>$("test").hide() - hides all elements with class="test".</li>
<li>$("#test").hide() - hides the element with id="test".</li>


		
		<br />
		<br />
		
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>The Document Ready Event</strong></Typography><br />
    <Typography>
       
You might have noticed that all jQuery methods in our examples, are inside a document ready event:
<br />
<br />

<strong>
$(document).ready(function() &#123;  <br /><br />

  &nbsp; &nbsp; &nbsp; &#47;&#47; jQuery methods go here...<br /><br />

&#125;); <br /><br />

</strong>


<li>This is to prevent any jQuery code from running before the document is finished loading (is ready).</li>
<li>It is good practice to wait for the document to be fully loaded and ready before working with it. This also allows you to have your JavaScript code before the body of your document, in the head section.</li>
<br />

Here are some examples of actions that can fail if methods are run before the document is fully loaded:
<br />
<br />
<li>Trying to hide an element that is not created yet.</li>
<li>Trying to get the size of an image that is not loaded yet</li>

		<br />
		<br />
		
		
        </Typography>
		
		
		
			<br />
	
	
	<Typography><strong>Shorter Method for document ready event</strong></Typography><br />
    <Typography>
	<br />
	
	<strong>
	
	$(function()  &#123; <br /><br />
</strong>
  &#47;&#47; jQuery methods go here... <br /><br />
<strong>
}); 
	
	
	
	
	</strong>
       <br />
       <br />
       <br />
	   Use the syntax you prefer. We think that the document ready event is easier to understand when reading the code.
       <br />
       <br />
       <br />

</Typography>
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jquerybasic" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Jqueryeventlist" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jquerysyntax;