import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Osschedulingpostdata from "./Osschedulingpostdata";
import Redundantarraysofindependentpostdata from "./Redundantarraysofindependentpostdata";


const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Schedulinginos"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Osschedulingpostdata />
		</Link>
		</Grid>
		
		 
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Redundantarraysofindependent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Redundantarraysofindependentpostdata />
		</Link>
	    </Grid>
	
	</>
	 
   )
}

export default Posts;
