import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody,  } from "@material-ui/core";
//import { categories } from "../../Categoriesconstant/Data";
import { Link, NavLink } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)'
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
	
})

const Oscategorieshome = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Webcreateview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <NavLink to="/"><TableCell>
		 
		All Categories
		
		 </TableCell></NavLink>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	  	  
	  
				    <TableRow>
				  
				  
				  <TableCell> Operating System  </TableCell>

	              </TableRow>
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	What is OS?  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				 <NavLink to="/Schedulinginos" ><TableCell>	Scheduling in OS  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <NavLink to="/Redundantarraysofindependent" ><TableCell> RAID  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Memory Management  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Distributed Database </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Unix Command  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	File Management & Security  </TableCell>

	              </TableRow>	  
				  
				  
				     
				    
				  
		 
		</TableBody>
	 </Table>
	
	
   
	
	</>
   )
};

export default Oscategorieshome;