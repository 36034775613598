import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/java/images/javahackingtruth.png";
import webimages from "../../../../../src/MyComponents/home/java/images/CGI-pic.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const CGIinjava = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Java </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>13 April 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>CGI ( Common Gateway Interface ) in Java</strong></Typography><br />
	 <Typography>
	 <li>CGI technology enables the web server to call an HTTP request information 
to the external program to process the request.</li>
    <li>For each request its start to new processes. Since a new process is 
created and insaalized, it's result in the delay of response time. </li>

<li>CGI application can be written in almost every language and most of them are not platform independent and common platform independent language is Perl. Although perl is a very powerful text processing language for every request it require new interpreted to be started. This makes the overall response time longer.
</li>

     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Advantages of servlets </strong></Typography><br />
    <Typography>
       
    <ol>	   
	<li><b>Better performance</b> - create a thread for each request not process.</li>
    <li><b>Portability</b> - because it's uses Java language.</li>
    <li><b>Robust</b> - JVM manage servlets so we don't need to worry about a memory Lake, garbage collection etc.
</li>
	<li><b>Secure</b> - because it's uses Java language.</li>
    
   
	</ol>
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography variant="h5" component="h6"><strong>Execution of a Servlets </strong></Typography><br />
    <Typography>
        
		
    <li>The client send a request to the web server.</li>
    <li>The web server interpret it and forward it to the corresponding servlets.</li>
    <li>The servlets processes the request generate the output if any and send it back to the web server.</li>
    <li>The web server send the response back the client. The browser then display it on the screen.
</li>
		<br />
		<br />
		
		<br />
		
		 <img src={webimages} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br />
		<br />
		
		
        </Typography>
		
		
  <br />
    
		
		<br />
		 
		 
		 

	 

       

<br />
		<br />
		
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Servletsinjava" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Lifecycleofaservlets" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default CGIinjava;                                                                                                                                                                                                                        