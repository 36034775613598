import { Grid } from "@material-ui/core";



import Banner from "./Banner";
import Datastructurecategorieshome from "./Datastructurecategorieshome";
import Posts from "./Posts";

const Datastructurehome = () => {
	
	return (
	
	<>
	        <Banner />
			
            <Grid container> 
                <Grid item lg={2} xs={12} sm={2}>			
			  <Datastructurecategorieshome />
			    </Grid>
			    <Grid container item lg={10} xs={12} sm={10}>
			  <Posts />
	        </Grid>
			</Grid>
	
	
	</>
	);
 };
 
export default Datastructurehome;