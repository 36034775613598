import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/heading-hackingtruth1.png";
import webindexparagraph from "../../../../../src/MyComponents/home/webdevelopment/images/paragrapgh-hackingtruth1.png";
import webindexlinks from "../../../../../src/MyComponents/home/webdevelopment/images/links-hackingtruth.png";
import webindeximages from "../../../../../src/MyComponents/home/webdevelopment/images/images-hackingtruth.in.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Htmlelementsarticle = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > HyperText Markup Language</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>HTML - HTML Basic</strong></Typography><br />
	 <Typography>
    


If you are new to html or if you want to be above average in webpage design you should concentrate solely on learning the structure of an HTML page, tags, and their functionalities etc.
<br />
<br />
HTML page is all about tags. Tags are similar to instructions or codes. Web browsers know their meaning and interpret them to render text images and other elements on the screen properly.
<br />
<br />
The set of HTML tag is fixed and is standardized by W3C. Invalid tags are displayed on the screen as they are.
     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>HTML Headings</strong></Typography><br />
    <Typography>
       


You may have noticed that many webpages contain different section headings with different sizes and perhaps different colors and fonts.
<br />
<br />
HTML provides tags for such section headings.
<br />
<br />
There are total Six level of headings h1 through h6. The h1 heading has the largest and h6 has the smallest font size. The exact size is determined by the browser used.
<br />
<br />
Consider the following code :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Heading&lt;/title&gt;<br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;h1&gt;My First Heading&lt;/h1&gt; <br />
		&lt;h2&gt;My Second Heading&lt;/h2&gt; <br />
		&lt;h3&gt;My Third Heading&lt;/h3&gt; <br />
		&lt;h4&gt;My Fourth Heading&lt;/h4&gt; <br />
		&lt;h5&gt;My Fifth Heading&lt;/h5&gt; <br />
		&lt;h6&gt;My Sixth Heading&lt;/h6&gt; <br /><br />
       

		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>StartTag and EndTag</strong></Typography><br />
    <Typography>
        A HTML element which is defined by using a start tag, some content, and end tag.
	<br />
	<br />
	
	&lt;StartTagName&gt; Content goes here (element)...&lt;/EndTagName&gt;
	
	 </Typography>
	<br />
	<br />
	<Typography>
	
	
	
<Typography className={classes.headingone}>&lt;h1&gt; My first Heading&lt;/h1&gt;</Typography>
<Typography className={classes.headingtwo}>&lt;h2&gt; My second Heading&lt;/h2&gt; </Typography>
<Typography className={classes.headingthree}>&lt;h3&gt; My second Heading&lt;/h3&gt; </Typography>
<Typography className={classes.headingfour}>&lt;h4&gt; My second Heading&lt;/h4&gt; </Typography>
<Typography className={classes.headingfive}>&lt;h5&gt; My second Heading&lt;/h5&gt; </Typography>
<Typography className={classes.headingsix}>&lt;h6&gt; My second Heading&lt;/h6&gt; </Typography>

		
	    <br />
		<br />
		<br />
		
        </Typography>
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		 The purpose of a web browser (chrome, firefox, etc) is to read HTML document and display them correctly.
		 <br />
		 <br />
		 A browser does not display the HTML tags, but uses them to determine how to display the document.
		 <br />
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		<Typography><strong>HTML Paragraph</strong></Typography><br />
    <Typography>
		
Web browsers ignore any carriage return all Character encountered in the HTML document.
<br />
<br />
HTML provides a paragraph tag &lt;p&gt; , which is used to start a fresh paragraph. The tag &lt;/p&gt; ,  insert a blank line immediately before the new paragraph.
<br />
<br />

		</Typography>
		

		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Paragraph&lt;/title&gt;<br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;p&gt;This is my first paragraph&lt;/p&gt; <br />
       &lt;p&gt;This is my another paragraph&lt;/p&gt; 
       
        <br /><br />
		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
				<Typography>
		<img src={webindexparagraph} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		
		
		
		
		
		
		<Typography><strong>HTML Links - Linking to the destination</strong></Typography><br />
    <Typography>
		
With the identifier in place, now I can make a link to it. <br />
We use the a element with the href attribute to provide the location of the link. To indicate that I’m linking to a fragment, I use the octothorpe symbol (#), also called a hash or number symbol, before the fragment name.
<br />
<br />

		</Typography>
		

		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Links&lt;/title&gt;<br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		 &lt;h2&gt;HTML Links&lt;/h2&gt;<br />
         &lt;p&gt;HTML links are defined with the a tag.&lt;/p&gt;<br />
<br />
		&lt;a href="https://www.hackingtruth.in">This is a link&lt;/a&gt; <br />
       
	   
        <br />
		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
				<Typography>
		<img src={webindexlinks} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		
		
		
		
		
		<Typography><strong>HTML Images </strong></Typography><br />
    <Typography>
	
The <strong> src</strong>attribute in the <strong> img </strong> element works the same as the <strong> href </strong> attribute in anchors when it comes to specifying URLs. Since you’ll most likely be using images from your own server, the src attributes within your image elements will
be set to relative URLs. <br />
<br />
HTML images are defined with the &lt;img&gt; tag.
<br />
<br />
The source file (src), alternative text (alt), width, and height are provided as attributes:
<br />
<br />

		</Typography>
		

		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Images&lt;/title&gt;<br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;h2&gt;HTML Image&lt;/h2&gt; <br />
&lt;p&gt;HTML images are defined with the &lt;img&gt; tag.&lt;/p&gt;

<br />
&lt;img src="ht.png" alt="hackingtruth.in" width="142" height="142"&gt;
<br />
	   
        <br />
		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
				<Typography>
		<img src={webindeximages} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Htmlintroarticle" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Htmlbasicarticle" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Htmlelementsarticle;