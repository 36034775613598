import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Requirementnegotiation = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>2 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Requirement Negotiation</strong></Typography><br />
	 <Typography>
    After collecting requirement from single customer. we can analyze the requirement and fix the requirement easily but
whenever there are multiple stake holder or customer then collection of requirement and fixation of requirements and
easily so we have to negotiate with customer to fix the requirement and this is known as requirement negotiation.
<br />
<br />
In requirement negotiate we have to identity all the issues related with and try to give the best solution for all the issues.

<br />
<br />
<br />
<strong>
Requirement negotiation based on conflicts on few phases negotiation, pre negotiation, post negotiation.
</strong>
     <br />
<br />
<ol>
<li>
<b>Pre Negotiation</b> - Pre negotiation is the first phase of requirement negotiation. In which we have to talk with customer
to find all the issues or conflicts related with functionalitites of the projects.
<br />
<br />
In this phase participate of all stakeholders are nesessary so that we can listen all the stakeholders and find all the 
conflicts of the projectss.

<br />
<br />
</li>
<li>
<b>Negotiation Phase</b> - In this phase we have to give the solution related with issues. We have to give more than one
solution for a particular issues. If a customer not satisfies with a solution than he/she can select the alternative
solution also.
</li>
<br />
<br />

<li> 
<b>Post Negotiation </b>- In this phase all the solution given by us is evalueted by customer. If the customer is satifies
with solution then you can document the solutoin.
<br />
<br />
If customer not satified then they can demand pre-negotiation. In this case we have to follow each phase of 
negotiation from the beginning. If a customer suggest any other solution for a particular problem then we can 
modifier document as per suggestion.
</li>
</ol>
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Requirement negotiation is the conflicts management related with requirement in which are - 
</strong></Typography><br />
    <Typography>
       
    

1) We have to collect the conflicts. <br />
2) We have to analyze the conflicts.<br />
3) Given solution related to the conflicts.<br />
4) Document all the solution.<br />

		<br />
		<br />
		<br />
		
	
		
		
		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Requirementnegotiation;