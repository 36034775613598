import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Softwareengineeringevolvingroleofsoftwarepostdata from "./Softwareengineeringevolvingroleofsoftwarepostdata";
import Layeredtechnologypostdata from "./Layeredtechnologypostdata";
import Softwareprocessmodelpostdata from "./Softwareprocessmodelpostdata";
import Requirementnegotiationpostdata from "./Requirementnegotiationpostdata";
import Sdlcpostdata from "./Sdlcpostdata";
import Umldiagrampostdata from "./Umldiagrampostdata";
import Functionalandnonfunctionalpostdata from "./Functionalandnonfunctionalpostdata";
import Cohesionandcouplingpostdata from "./Cohesionandcouplingpostdata";
import Casetoolpostdata from "./Casetoolpostdata";
import Blackboxtestingpostdata from "./Blackboxtestingpostdata";
import Whiteboxtestingpostdata from "./Whiteboxtestingpostdata";
import Softwarerequirementspecificationpostdata from "./Softwarerequirementspecificationpostdata";
import Optimizationtechniquespostdata from "./Optimizationtechniquespostdata";

const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Evolvingroleofsoftware"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwareengineeringevolvingroleofsoftwarepostdata />
		</Link>
		</Grid>
		
		
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Layeredtechnology"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Layeredtechnologypostdata />
		</Link>
		</Grid>
		
			
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Softwareprocessmodel"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwareprocessmodelpostdata />
		</Link>
		</Grid>
		
			
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Requirementnegotiation"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Requirementnegotiationpostdata />
		</Link>
		</Grid>
		
		
	 <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Sdlc"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Sdlcpostdata />
		</Link>
		</Grid>
			
	 <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Cohesionandcoupling"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Cohesionandcouplingpostdata />
		</Link>
		</Grid>
		
		
	 <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Umldiagram"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Umldiagrampostdata />
		</Link>
		</Grid>
		
	 <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Functionalandnonfunctional"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Functionalandnonfunctionalpostdata />
		</Link>
		</Grid>
		
		 <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Casetool"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Casetoolpostdata />
		</Link>
		</Grid>
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Blackboxtesting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Blackboxtestingpostdata />
		</Link>
		</Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Whiteboxtesting"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Whiteboxtestingpostdata />
		</Link>
		</Grid>
		
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Softwarerequirementspecification"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Softwarerequirementspecificationpostdata />
		</Link>
		</Grid>
		<Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Optimizationtechniques"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Optimizationtechniquespostdata />
		</Link>
		</Grid>
		
		
	
	</>
	 
   )
}

export default Posts;
