import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody,  } from "@material-ui/core";
//import { categories } from "../../Categoriesconstant/Data";
import { Link, NavLink } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)'
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
	
})

const Datastructurecategorieshome = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Webcreateview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <NavLink to="/"><TableCell>
		 
		All Categories
		
		 </TableCell></NavLink>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	  	  
	  
				    <TableRow>
				  
				  
				  <NavLink to="/Datastructure" ><TableCell> Data Structure  </TableCell></NavLink>

	              </TableRow>
				  
				  
				    <TableRow>
				  
				  
				 <NavLink to="/arrayindatastructure" > <TableCell>	Array in Data Structure  </TableCell></NavLink>

	              </TableRow>	  
				  
				    
				    <TableRow>
				  
				  
				 <NavLink to="/stackdatastructure" > <TableCell>	Stack Data Structure  </TableCell></NavLink>

	              </TableRow>	  
				  
				    
				   <TableRow>
				  
				  
				 <NavLink to="/Applicationofstack" > <TableCell>	Application of Stack  </TableCell></NavLink>

	              </TableRow>	  
				  
				       <TableRow>
				  
				  
				 <NavLink to="/infixtopostfix" > <TableCell>	Infix to Postfix  </TableCell></NavLink>

	              </TableRow>	  
				  
				        <TableRow>
				  
				  
				 <NavLink to="/Postfixexpression" > <TableCell>	Postfix Expression </TableCell></NavLink>

	              </TableRow>	  
				  
				         <TableRow>
				  
				  
				 <NavLink to="/Infixtoprefix" > <TableCell>	Infix to Prefix </TableCell></NavLink>

	              </TableRow>	  
				  
				        <TableRow>
				  
				  
				 <NavLink to="/Prefixexpression" > <TableCell>	Prefix Expression </TableCell></NavLink>

	              </TableRow>	  
				  
				    
				        <TableRow>
				  
				  
				 <NavLink to="/Recursion" > <TableCell>	Recursion </TableCell></NavLink>

	              </TableRow>	  
				  
				          <TableRow>
				  
				  
				 <NavLink to="/Lineardatastructure" > <TableCell>	Linear Data Structure </TableCell></NavLink>

	              </TableRow>	  
				  
				    
				    
				<TableRow>

                   <NavLink to="/Sorting"><TableCell>Sorting </TableCell></NavLink>

                   </TableRow>				   
		 
		 
		 
		     <TableRow>
			 <NavLink to="/Linkedlist"><TableCell>Linked LIst</TableCell></NavLink>

             </TableRow>			 
		 
		     <TableRow>
			 
			 <NavLink to="/Binarysearchtree"><TableCell>BInary Search Tree</TableCell></NavLink>

             </TableRow>			 
		 
		</TableBody>
	 </Table>
	
	
   
	
	</>
   )
};

export default Datastructurecategorieshome;