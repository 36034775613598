import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody,  } from "@material-ui/core";
//import { categories } from "../../Categoriesconstant/Data";
import { Link, NavLink } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)'
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
	
})

const Dbmscategorieshome = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Webcreateview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <NavLink to="/"><TableCell>
		 
		All Categories
		
		 </TableCell></NavLink>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	  	  
	  
				    <TableRow>
				  
				  
				  <TableCell> DBMS  </TableCell>

	              </TableRow>
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	ER Model  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				 <NavLink to="/Relationalalgebraarticle" ><TableCell>	Relational Algebra  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    <TableRow>
				   
				 <NavLink to="/Entitiesindbms" ><TableCell>	Entites  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    
				    <TableRow>
				   
				 <NavLink to="/Primarykeyindbms" ><TableCell>	Primary Key  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				  
				  
				    <TableRow>
				  
				  <TableCell>	Schema  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Integrity Rules  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Distributed Database </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Data Security  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Data Mining  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell> Parallel DB  </TableCell>

	              </TableRow>	  
				    
				  
		 
		</TableBody>
	 </Table>
	
	
   
	
	</>
   )
};

export default Dbmscategorieshome;