import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment.png";
import webindex2 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-row.png";
import webindex3 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-column.png";
import webindex4 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-rowcheckbutcolumncut.png";
import webindex5 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-columncheckbutrowcut.png";
import webindex6 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-lowestuncoveredvalue.png";
import webindex7 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-intersection.png";
import webindex8 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-intersectionafterrowcheckcolumncut.png";
import webindex9 from "../../../../../src/MyComponents/home/Softwareengineering/images/assignment-total.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Optimizationtechniques = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Optimization Techniques </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>10 Sep 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Optimization Techniques Question</strong></Typography><br />
	 <Typography>
<b>Q) What is LPP and its application ?</b>
<br />
	<br />
Linear Programming Problems delay with the optimization (maximization or minimization)
of an entity (or function) known as the objective function which are subjected to certain constraint.
which are the set of linear equation.

	<br />
	<br />
Application of Linear Programming Problems - 

	<br />
<ol>
<li>Personal assignemnt Problems</li>
<li>Graphical Method</li>
<li>Optimum Estimation of employee compensation</li>
<li>Simplex Method</li>
<li>Product Management</li>
<li>Marketing Management</li>
</ol>

	
	</Typography>
  	<br />
	
	<br />
	
	
	<Typography><strong>Q) What do you mean by operation research and its application ?</strong></Typography><br />
    <Typography>
       
Operation research (OR) is an analytical method of problem solving and decision making that is useful
in the management of organization.
<br />	
		</Typography>
			

	<br />
	
	
	<Typography><strong>Application of OR </strong></Typography><br />
    <Typography>
       
	  
<ol>
<li>Allocation and distribution in project</li>
<li>Production and failities planning</li>
<li>Programes Decision</li>
<li>Marketing</li>

</ol>

		<br />	     	
		
		<br />
		
		
		</Typography>
			
		
	
	<br />
	<br />
	
	
	<Typography><strong>Q) What are the different methods to determine initial feasible solution ?</strong></Typography><br />
    <Typography>
      
Obtain initial feasible solution for the following transportation table using -

	   <br />
		<br />
		
<ol>
<li>LCM Least Cost Method</li>
<li>North West Corner Rule</li>
<li>VAM Vogel's Approximation Method</li>
</ol>
 	
		
		<br />
		
		
		<br />
		
		</Typography>
			
		
	
	<Typography><strong>Q) What is the PERT CPM programs encounter review technique critical path method? </strong></Typography><br />
    <Typography>
	
	
<li>PERT is stand for Project Evaluation and Review Technique.</li>
<li>CPM is stand for Critical Path Method.</li>

	  <br />
		<br />
PERT is that a technique of project management which is used to manage uncertain (ie. time is not known) activities 
of any project.

	  <br />
		<br />
CPM is that a technique of project management which is used to manage only certain (ie. time is known) activities
of any project.

	  <br />
		<br />
	
		
		</Typography>
			
	
		
	<Typography><strong>Pert Review</strong></Typography><br />
    <Typography>
	
	
A program evaluation review technique (PERT) chart is a graphical representation of a project's timeline that display all of the individual task
necessary to complete the project.




	  <br />
	  
		<br />
		It is an identifies task dependencies.
		</Typography>
			<br />
			<br />
			<br />
			
			
		
	<Typography><strong>Q) What is assignemnt problem ? Explain the steps to solve the assignment problem.</strong></Typography><br />
    <Typography>
	
	
<ul>
<li>Assignment problem refers to special class of linear programming problems that involves determining the most efficient
assignment of people to projects, salespeople to territories, contracts and so on.</li>
<li>It is often used to minimize total cost or time of performing task.</li>
<li>One important characteristic of assignment problems is that only one job (or worker) is assigned to one machine (or project)</li>

<br />
<li> Each Assignment problem has a Matrix associated with it.</li>
<li>The number in the table indicates COST associated with the assignment. </li>
<li>The most efficient linear programming algorithm to find optimum solution to an assignment problem is Hungarian Method.</li>
</ul>



	  <br />
		<br />
		
		<b> Steps To Solve The Assignemnt Problem. </b>
				
<br />
<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
Row Reduction (choosing minimum from each row and then subtracted from their respective row <br />
<br />
		
		
				
<br />
<br />
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
Column Reduction (after Row reduction from the reduced table , choosing minimum from each column and then subtracted from their respective column)

<br />
<br />
		
		
				
<br />
<br />
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
Now zero elimination by using vertical or horizontal
lines (maximum lines upto = max. Row = max. Column)(Try to use less lines to cut) 
<br />
<br />
		
		
				
<br />
<br />
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
		

		<Typography>
		<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
		As you can see that n = ( 4 x 4 means Row x Column ) Given in question.
<br />
So, but there are 3 boxes and 3 Line (Row and Column) but condition doesn't meet 
<br />
<br />
So, create a new table but now we will check lowest uncovered value and the lowest value is 1.
<br />
<br />
<b>x - 1 </b>		
<br />
<br />
		<Typography>
		<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
Intersection Time means Where the two are crossing, the value has to be x + 1.
				
<br />
<br />
		<Typography>
		<img src={webindex7} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
	
		Now, again Row Check but column cut 
		
<br />
<br />
		<Typography>
		<img src={webindex8} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
		Now a moment when all lines(max lines) were used , we called the moment of Optimal solution/assignment,
assign square boxes to each zero so that assignment process can start.	
				
<br />
<br />
		<Typography>
		<img src={webindex9} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		</Typography>
      
<br />
<br />
		
		
		
		
		
		
		
	
		</Typography>
			<br />
			<br />
			<br />
			
			
			
			
			
	<Typography><strong>Q) How to find the critical path ?</strong></Typography><br />
    <Typography>
	
	
Finding the critical path involves looking at the duration of critical and non-critical task.

	  <br />
	  
		<br />
		
<ol>
<li>List activities</li>
<li>Identify Dependencies</li>
<li>Create a network diagram</li>
<li>Estimate task duration</li>
<li>Calculation the critical path</li>
<li>Calculate the float</li>

</ol>
	  <br />
	  
		<br />
		
	
       
	</Typography>
			
		<br />
		<b> NOTE :- Please wait for more updates.... </b>
		
		<br />
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Optimizationtechniques;