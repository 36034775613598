import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/blackboxtesting.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Whiteboxtesting = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>10 Sep 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>White Box Testing</strong></Typography><br />
	 <Typography>
    In this white box testing we have to check all the portion which is remains untested by white box testing.
	
	<br />
	<br />
	In white box testing we have to focus internal login of the system and each and every executable
	statement algorithm.
	
<br />
<br />
 Recover all the logical path of the software system white box testing is focuses on input processing
 extensible output.
<br />
<br />
In input we have to pass multiple value related with input field for further process inside of the system.
<br />
<br />
After retreiving of the input data programming login execute and output is respect.
<br />
<br />

	
	
	
	</Typography>
  	<br />
	
	<br />
	
	
	<Typography><strong>Processing</strong></Typography><br />
    <Typography>
       
Based on program instruction input data processed and this data are used for successive test cases.
<br />

Test cases by using we can cover  the important portion of the programming untested and this is known as code coverage.
		
		</Typography>
			
	
	
	<br />
	<br />
	
	
	<Typography><strong>Testing condition</strong></Typography><br />
    <Typography>
       
	   The data we have to received  processing is used in test condition we have to create different test cases to cover 
	   maximum code of the program.
		<br />	     	
		
		<br />
		
		
		</Typography>
			
		
	
	<br />
	<br />
	
	
	<Typography><strong>Output</strong></Typography><br />
    <Typography>
      After checking all the data in test cases output data is displaying to the end users.
	   
	   <br />
	   By using white box testing we can conduct different  types of white box testing condition testing branch testing
	   multiple condition testing loop testing and path testing.
		<br />
	     	
		
		<br />
		
		
		<br />
		
		</Typography>
			
		
	
	<Typography><strong>State Testing</strong></Typography><br />
    <Typography>
	
	We have to execute and every statement atleast once.
	  <br />
	  
		<br />
		
		</Typography>
			
		
	<Typography><strong>Branch Testing</strong></Typography><br />
    <Typography>
	
	By using branch testing check all the decision making concept such that flow chart.
	  <br />
	  
		<br />
		
		</Typography>
			
		
	<Typography><strong>Condition Testing</strong></Typography><br />
    <Typography>
	
	Based on a particular condition we have to check the state for positive as the negative.
	  <br />
	  
		<br />
		
		</Typography>
			
	<Typography><strong>Multiple Test Condition</strong></Typography><br />
    <Typography>
	
	If there are logical execute construct then we have to check multiple condition by defined meaningful
	test cases.
	  <br />
	  
		<br />
		
		</Typography>
			
	<Typography><strong>Path Testing</strong></Typography><br />
    <Typography>
	
	We can cover the untested portion by eliminator duplicate test cases and create new test cases.
	  <br />
	  
		<br />
		
		</Typography>
			
		<br />
		<br />
		
			
			
	<Typography><strong>Advantage of White Box Testing</strong></Typography><br />
    <Typography>
	
    In white box testing with recovered maximum code by checking each and every statement.	

	<br />
	In white box testing we have to find the error and fix it. 
	  
		<br />
		<br />
		<br />
		
		</Typography>
			
				
	<Typography><strong>Drawbacks</strong></Typography><br />
    <Typography>
	
   We have to create the test cases again and again for different blocks of code.
	<br />
	In white box testing is a expensive test cases because we have to write and change the meaningful
	portion of the program.
		<br />
		<br />
		We can start white box testing during initial stage because there is no need User interface 
		for checking programming logic.
		
		</Typography>
			
		
       

			
		<br />
		<br />
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Casetool" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Whiteboxtesting;