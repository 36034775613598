import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/2.png";
import webindex2 from "../../../../../src/MyComponents/home/webdevelopment/images/3.png";
import webindex3 from "../../../../../src/MyComponents/home/webdevelopment/images/4.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jqueryhideevent = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Javascript jQuery</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Javascript Hide Event </strong></Typography><br />
	 <Typography>
    

    You can hide or show any data elements (HTML Elements) with the Jquery hide() and show() methods.
	<br />
	









<br />
<br />
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Syntax</strong></Typography><br />
    <Typography>
       
The Syntax is - <br /><strong>  $(selector).hide(speed,callback)</strong>
<br />
<strong> $(selector).show(speed,callback)</strong> <br />
<li>A $ sign to define/access jQuery.</li>
<li>A (selector) to "query" (or find)" HTML elements.</li>
<li>A jQuery hide() to performed hide on the element(s).</li>
<li>A jQuery show() to performed show on the element(s).</li>
<li>A jQuery speed parameter specifies the speed of the hiding/showing but its a Optional and its take the values like <strong>"slow"</strong> and <strong>"fast"</strong>.</li>

<br />
<br />

		
		
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>Hide method program</strong></Typography><br />
    <Typography>
       
The following example demonstrates the speed parameter with hide().
<br />
<br />
<pre>

&lt;!DOCTYPE html&gt;    <br />
&lt;html&gt;    <br />
&lt;head&gt; 
    <br />    <br />
&lt;title&gt; Hide Method of Jquery &lt;/title&gt;
    <br />    <br />
&lt;script src="jquery.min.js"&gt;&lt;/script&gt;
    <br />    <br />
&lt;/head&gt;    <br />    <br />

&lt;body&gt;    <br />
&lt;p&gt;if you click on me then i will become mr india&lt;/p&gt;    <br />
&lt;p&gt;Click me away!&lt;/p&gt;    <br />
&lt;p&gt;Click me too!&lt;/p&gt;    <br />
    <br />

&lt;/body&gt;    <br />    <br />

&lt;script&gt;    <br />    <br />
$(document).ready(function()&#123;    <br />
 &nbsp;&nbsp;   $("p").click(function()&#123;    <br />
 &nbsp;&nbsp;&nbsp;     $(this).hide();    <br />
  &nbsp; &#125;);    <br />
&#125;);    <br />
    <br />

&lt;/script&gt;
&lt;/html&gt;




</pre>



		
		<br />  <br />  <br />
  <strong> You can also use this line of code..something like this in between &lt;head&gt; open and &lt;&#47;head&gt; close without downloading any file of jquery.</strong>
      <br />  <br />
	  
	&lt;script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;
	
	  &lt;&#47;script&gt;
  
<br />
<br />
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>Hide Method with Image</strong></Typography><br />
    <Typography>
       
Now we will hide any image via hide method.
<br />
<br />
<br />
<br />

<pre>

&lt;!DOCTYPE html&gt;    <br />
&lt;html&gt;    <br />
&lt;head&gt; 
    <br />    <br />
&lt;title&gt; Hide Method of Jquery with Image&lt;/title&gt;
    <br />    <br />
&lt;script src="jquery.min.js"&gt;&lt;/script&gt;
    <br />    <br />
&lt;/head&gt;    <br />    <br />

&lt;body&gt;    <br />
&lt;p&gt;if you click me on photo then i will become mr india&lt; &#47;p&gt; <br /> <br />

&lt;img src="images&#47;img-atul.png" alt="i am here" style="width:200px; height=200px"; &#47;&gt;  <br /> <br />



&lt;/body&gt;    <br />    <br />

&lt;script&gt;    <br />    <br />
$(document).ready(function()&#123;    <br />
 &nbsp;&nbsp;   $('img').click(function()&#123;    <br />
 &nbsp;&nbsp;&nbsp;     $(this).hide();    <br />
  &nbsp; &#125;);    <br />
&#125;);    <br />
    <br />

&lt;/script&gt;
&lt;/html&gt;




</pre>

<br />
<br />





		<br />
		<br />
		
		
        </Typography>
		
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		<br />
		<br />
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		
		
			<br />
	
	
	<Typography><strong>Hide Method with Time</strong></Typography><br />
    <Typography>
	
	
	 <pre>
	 
	
	
&lt;!DOCTYPE html&gt;    <br />
&lt;html&gt;    <br />
&lt;head&gt; 
    <br />    <br />
&lt;title&gt; Hide Method of Jquery with Time &lt;/title&gt;
    <br />    <br />
&lt;script src="jquery.min.js"&gt;&lt;/script&gt;
    <br />    <br />
&lt;/head&gt;    <br />    <br />

&lt;body&gt;    <br />
&lt;button&gt;Hide Me&lt;&#47;button&gt; <br /> <br />
&lt;p&gt; I will disappear slowly slowly&lt;&#47;p&gt;   <br /><br />


&lt;/body&gt;    <br />    <br />

&lt;script&gt;    <br />    <br />

$(document).ready(function() &#123;    <br />
  &nbsp;&nbsp;   $("button").click(function()  &#123;    <br />
  &nbsp;&nbsp;   &nbsp;   $("p").hide(1000);    <br />
  &nbsp; &#125;);    <br />
&#125;);    <br />
	
	
    <br />

&lt;/script&gt;  <br />
&lt;/html&gt;


	
	</pre>
	
       <br />
       <br />
       <br />
	   Use the syntax you prefer. We think that the document ready event is easier to understand when reading the code.
       <br />
       <br />
       <br />

</Typography>
		
		
		
		
		
		
		
	<Typography><strong>Show Method </strong></Typography><br />
    <Typography>
	
	
	 <pre>
	 
	
&lt;!DOCTYPE html&gt;    <br />
&lt;html&gt;    <br />
&lt;head&gt; 
    <br />    <br />
&lt;title&gt; Hide/Show Method of Jquery &lt;/title&gt;
    <br />    <br />
&lt;script src="jquery.min.js"&gt;&lt;/script&gt;
    <br />    <br />
&lt;/head&gt;    <br />    <br />

&lt;body&gt;    <br />
&lt;button id="hide"&gt;Hide Me&lt; &#47;button&gt; <br /> &nbsp;&nbsp;
&lt;button id="show"&gt;Show Me&lt; &#47;button&gt; <br /> <br />
&lt;p&gt; I will appear and disappear slowly slowly&lt;&#47;p&gt; <br />


&lt;/body&gt;    <br />    <br />

&lt;script&gt;    <br />    <br />



$(document).ready(function() &#123;  <br />
  &nbsp; &nbsp; $("#hide").click(function()&#123;   <br />
    &nbsp; &nbsp; &nbsp; $("p").hide();  <br />
  &nbsp; &nbsp; &#125;);  <br />
  $("#show").click(function()&#123;   <br />
  &nbsp; &nbsp;   $("p").show();  <br />
 &nbsp; &nbsp;  &#125;);  <br />
&nbsp; &#125;);    <br />
  
&#125;);    <br />
    <br />
&lt;/script&gt; <br />
&lt;/html&gt;

	</pre>
	
     
       <br />

</Typography>
		
		
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		

		
		</Typography>
		<br />
		<br />
		
		
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jquerybasic" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Jqueryeventlist" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jqueryhideevent;