
import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/htmlsidenav.png";
import webindex1 from "../../../../../src/MyComponents/home/webdevelopment/images/htmlsidenav1.png";

import webindex2 from "../../../../../src/MyComponents/home/webdevelopment/images/fav.ico";
import webindex3 from "../../../../../src/MyComponents/home/webdevelopment/images/kaj-ht.jpg";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Htmlsidenavbar = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > HyperText Markup Language</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>HTML - SideNav Bar</strong></Typography><br />
	 <Typography>
    


If you are new to html or if you want to be above average in webpage design you should concentrate solely on learning the structure of an HTML page, tags, and their functionalities etc.
<br />
<br />
HTML page is all about tags. Tags are similar to instructions or codes. Web browsers know their meaning and interpret them to render text images and other elements on the screen properly.
<br />
<br />
Today we will make a <strong>SideNav Bar</strong> through <strong>Flexbox</strong> i think so, hahah LoL , in which we will only take the help of <strong>HTML</strong> and <strong>CSS</strong> and will try to make it completely responsive.     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>FlexBox</strong></Typography><br />
    <Typography>
       


The CSS3 Flexible Box, or flexbox, is a layout mode providing for the arrangement of elements on a page such that the elements behave predictably when the page layout must accommodate different screen sizes and different display devices. Mozilla Developer Network <br />
<br />


</Typography>

<br />

	
	<br />
	
	<Typography><strong>What is FlexBox</strong></Typography><br />
    <Typography>
       

 Designed to provide a better way to lay out, align, and distribute space among items in a container - even when dynamic. Flexbox is a CSS layout mode designed to ensure that elements behave predictably in different screen sizes and devices. … it creates Intelligent boxes. Easily Defined in CSS and markup (incl. media queries). Directionally agnostic. (block = vertically-based & inline = horizontally-based) Best used for smaller modules over broader layout <br />


</Typography>

<br />

<br />









<Typography><strong>FlexBox Syntax</strong></Typography><br />
    <Typography>
      
A Flexible Layout must have a parent element with the display property set to flex.

Direct child elements(s) of the flexible container automatically becomes flexible items.
<br />
<br />
Consider the following code :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets write the Syntax</strong></Typography><br />
    <Typography>
        
		
    <pre>
		 

		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Flex Box&lt;/title&gt;<br />
		<br />
		&lt;style&gt;   <br />
		.flex-container &#123;   <br />
           display: flex;        <br />
           background-color: DodgerBlue;  <br />
&#125;

<br />
<br />
.flex-container &gt; div &#123;   <br />
  background-color: #f1f1f1;        <br />
  margin: 10px;                      <br />
  padding: 20px;                       <br />
  font-size: 30px;                        <br />
&#125;
<br />

      &lt;/style&gt;      <br /> <br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;div class="flex-container"&gt; <br />
  &lt;div&gt;1&lt;/div&gt;  <br />
  &lt;div&gt;2&lt;/div&gt;  <br />
  &lt;div&gt;3&lt;/div&gt;   <br />
&lt;/div&gt;                   <br /><br />


		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		
		
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
<br />
<br />

<Typography><strong> HTML Code</strong></Typography><br />
    <Typography>
      
First of all we will create a sidenavbar.html or sidenav.html file 
<br />
Consider the following code :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt;     <br />
&lt;html&gt;     <br />
&lt;head&gt;     <br />
&lt;title&gt;Side Navbar&lt;&#47;title&gt;     <br />     <br />
&lt;link rel="stylesheet" href="sidenav.css" &#47;&gt;     <br />     <br />
&lt;&#47;head&gt;     <br />     <br />
&lt;body&gt;     <br />     <br />


&lt;div class="container"&gt;     <br />
&lt;div class="row"&gt;     <br />
&lt;div class="col-3"&gt;     <br />
&lt;!--&lt;p class="para-sidenav"&gt;sidenav bar&lt;&#47;p&gt;--&gt;     <br />
&lt;div class="icon-ht"&gt;     <br />
&lt;img src="..&#47;fav.ico" class="icon-ht" alt="icon is here" &#47;&gt;     <br />
&lt;&#47;div&gt;     <br />
&lt;ul&gt;     <br />     <br />

&lt;li&gt;&lt;a href="#"&gt;Home&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Academy&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Service&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Internship&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Blog&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Meet-us&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;About&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;Contact&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />
&lt;li&gt;&lt;a href="#"&gt;FAQ&lt;&#47;a&gt;&lt;&#47;li&gt;     <br />     <br />

&lt;&#47;ul&gt;     <br />
&lt;&#47;div&gt;     <br />     <br />

&lt;div class="col-9"&gt;
&lt;p class="para-side-align"&gt;This is the right side of alignment&lt;&#47;p&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />
&lt;img src="kaj-ht.jpg" alt="i am here" style="width:200px; height:200px;" &#47;&gt;     <br />

     <br />     <br />

&lt;&#47;div&gt;     <br />


&lt;&#47;div&gt;     <br />
&lt;&#47;div&gt;      <br />     <br />     <br />
 
&lt;footer class="foot-er"&gt;     <br />
© 2022 Hacking Truth Cyber Security Company. OPC. All Rights Reserved. Privacy Policy
&lt;&#47;footer&gt;     <br />     <br />
 

&lt;&#47;body&gt;     <br />
&lt;&#47;html&gt;     <br />
</pre>
		
		<br />
		<br />
		
		
        </Typography>
		
		<br />	
		<Typography><strong> CSS Code</strong></Typography><br />
    <Typography>
		

then we will create another file with the name is cardbox and the extension is .css (cardbox.css)
<br />
<br />

		</Typography>
		

		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		* &#123;    <br />    <br />
 
 margin:0;    <br />
 padding: 0;     <br />    <br />
 
&#125;    <br />    <br />


body &#123;    <br />    <br />
  
 
  
 &#125;    <br />    <br />
 
 
.container &#123;    <br />    <br />
  display: flex;      <br />
 &#125;    <br />    <br />
 
 
.col-3  &#123;    <br />    <br />
	
  height: 100%;    <br />
  width: 180px;    <br />
  position: fixed;    <br />
  z-index: 1;    <br />
  top: 0;    <br />
  left: 0;    <br />
  background-color: #111;    <br />
  overflow-x: hidden;    <br />
  padding-top: 20px;    <br />    <br />
  
	
	
&#125;     <br />    <br />

.col-9 &#123;    <br />    <br />
	width: 100%;    <br />
	 
&#125;    <br />    <br />

.col-9 > img &#123;    <br />    <br />
	 margin-left: 200px;     <br />
     padding: 5px 10px;    <br />
	  
&#125;    <br />    <br />



.col-9 > p &#123;    <br />    <br />
	 margin-left: 200px;     <br />
	 font-size: 28px;     <br />
     text-align: center;    <br />
	
	 
&#125;    <br />    <br />


.icon-ht > img &#123;    <br />    <br />
	width: 50px;    <br />
	height: 50px;    <br />
	margin: 20px;    <br />
	
&#125;    <br />    <br />

.para-side-align  &#123;    <br />    <br />
	margin: 20px;    <br />
	
	
&#125;    <br />    <br />


a &#123;    <br />    <br />
	text-decoration: none;    <br />
	color: white;    <br />
&#125;    <br />    <br />

a:hover &#123;    <br />    <br />
	border-bottom: 2px solid white;    <br />
	border-radius: 50px;    <br />
&#125;    <br />    <br />

ul > li &#123;    <br />    <br />
	
	padding: 6px 8px 6px 16px;    <br />
	display: block;    <br />
	margin-left: 10px;    <br />
	color: white;    <br />
	
&#125;    <br />    <br />

li &#123;    <br />    <br />
	text-transform: uppercase;    <br />
	color: white;    <br />
	
	
&#125;
    <br />    <br />
.foot-er &#123;    <br />
	text-align: center;    <br />
	margin: 20px;    <br />
	margin-left: 200px;     <br />
     
	
&#125;    <br />    <br />




		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
		<strong>Lets Run the code :</strong>
		<br />
		<br />
		<br />
				<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		<br />
		<br />
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		<strong>Downloadable file  :</strong>
		<br />
		<br />
		<br />
				<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		<br />
		<br />
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		<strong>Please pay attention here,  explanation this code is still incomplete.<br />

Because it is time for me to go to college, see you very soon on this website. 😹 LoL</strong>
		<br />
		<br />
		<br />
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Htmlbasicarticle" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Htmlintroarticle" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Htmlsidenavbar;