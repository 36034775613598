import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/hover-me-hackingtruth-event.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jqueryhoverevent = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Javascript jQuery</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Jquery Hover Event </strong></Typography><br />
	 <Typography>
    

   
	</Typography>
  	<br />
	
	<br />

	
	
	<Typography><strong>Click Event</strong></Typography><br />
    <Typography>
     <br />

When mouse hover on image then generate a popup alert box
<br />
<br />
<pre>
&lt;!DOCTYPE html&gt;        <br />
&lt;html&gt;    <br />
&lt;head&gt;   <br />
&lt;title&gt;Jquery Hacking Truth&lt;&#47;title&gt;    <br />   <br />
&lt;script src="jquery.min.js"&gt;&lt;&#47;script&gt;   <br />

&lt;&#47;head&gt;  <br /><br />


&lt;body&gt;  <br /><br />

&lt;p&gt;Hello This is Kumar Atul Jaiswal (Founder of HackingTruth)&lt;&#47;p&gt;

<br /><br />



&lt;img src="images&#47;1.png" alt="I am Here" style="width:200px; height=200px"; &#47;&gt;

<br /><br />


&lt;caption&gt;Hover Me &lt;&#47;caption&gt;

<br /><br />
&lt;&#47;body&gt;

<br /><br />
&lt;script&gt;   <br />

	$(document).ready(function() &#123;    <br />
	  $('img').hover(function() &#123;   <br />
	    alert("Thank you For hovering me");   <br />
		 &#125;);   <br />
	   &#125;);   <br />
	
	

&lt;&#47;script&gt;
   <br />   <br />

&lt;&#47;html&gt;
</pre>

  <br />  <br />  <br />
  <strong> You can also use this line of code..something like this in between &lt;head&gt; open and &lt;&#47;head&gt; close without downloading any file of jquery.</strong>
      <br />  <br />
	  
	&lt;script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;
	
	  &lt;&#47;script&gt;
  
<br />
<br />
<br />
<br />




	      
	   
	   
	   
<li><strong>Explaination and Some Suggestions</strong></li><br />
<ul>
<li>You can use jquery file in both the ways by downloading and without downloading. so always use before closing &lt;&#47;head&gt; tag. </li>
<li>Always write the jquery script after closing the body tag. It will happen that you ust have seen that when
   you have must seen that when the website load on the internet, it takes a lot of time, the biggest reason for this can be javascript or jquery script.</li>
<li>Document is html file and thats why we use with &lt;img&gt; tag. when someone click on image, the function will call alert method and then generate a popup.</li>
<li>You can also use <strong>click event</strong> in the place of <strong>hover event</strong>...its so simple..okay!! </li>
</ul>
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
			<br />
	
	
	
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jqueryclickevent" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Webdevelopmenthome" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jqueryhoverevent;