import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';

import { Link, NavLink } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/java/images/javahackingtruth.png";
import webimages1 from "../../../../../src/MyComponents/home/java/images/servlet-pack.png";
import webimages2 from "../../../../../src/MyComponents/home/java/images/tomcat-installation.png";
import webimages3 from "../../../../../src/MyComponents/home/java/images/conf.png";
import webimages4 from "../../../../../src/MyComponents/home/java/images/jdk-hackingtruth.png";
import webimages5 from "../../../../../src/MyComponents/home/java/images/env.png";
import webimages6 from "../../../../../src/MyComponents/home/java/images/startup-hackingtruth.png";
import webimages7 from "../../../../../src/MyComponents/home/java/images/run-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Helloservlet = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Java </Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>13 April 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Hello Servlet</strong></Typography><br />
	 <Typography>
	 Our first servlet is a simple servlet designed to handle the HTTP GET 
	 Method. Create the following name HelloWorldservlet.java
<br />
<br />

<br />


     
	</Typography>
  	<br />
  	<br />
  	<br />
	

	
		
		
		<Typography variant="h5" component="h6"><strong>Program </strong></Typography><br />
    <Typography>
        
		<pre>
		import java.io.*; <br />
import javax.servlet.*; <br />
import javax.servlet.http.*; <br /><br /><br />

public class Helloworldservlet extends HttpServlet &#123;<br />
&nbsp;&nbsp;	public void doget (HttpServletRequest request, HttpServletResponse response) throws IOException, ServletException &#123;<br />
&nbsp;&nbsp;&nbsp;		response.setContentType("text&#47;html");<br />
&nbsp;&nbsp;&nbsp;		PrintWriter out = response.getWriter();<br />
&nbsp;&nbsp;&nbsp;		out.println("&lt;html&gt;&lt;head&gt;&lt;title&gt;First Servlet&lt;&#47;title&gt;&lt;&#47;head&gt;");<br />
&nbsp;&nbsp;&nbsp;		out.println("&lt;body&gt;");<br />
&nbsp;&nbsp;&nbsp;		out.println("&lt;h1&gt;Hello World Servlet&lt;&#47;h1&gt;");<br />
&nbsp;&nbsp;&nbsp;		out.println("&lt;&#47;body&gt;&lt;&#47;html&gt;");<br />
&nbsp;&nbsp;&nbsp;		out.close();<br />
	&nbsp;&nbsp;&#125;<br /><br />
&nbsp;	&#125;<br /><br />
		
		
		
		</pre>
		
<br />
<br />
<br />
<br />

<li>The class servlet extends the HTTPServlet class. So it can handle HTTP 
request it is written to handle only the GET method.
<br />
<br />
Therefore only the doGet() method is overridden.</li>

<li>This is mentioned using the setContentType() method.</li>

<li>To send the data back to the client, Printwriter object is obtained by 
calling the getwriter() method on the response object.
</li>

<li>
To Compile our Helloworldservlet.java program file, servlet are
 required class files are required.
</li>
<li>
Tomcat comes with a (.jar)  file (<b>servlet_api.jar</b>) for version 9 or 10)  that contains necessary files.
</li>
<li>Find with this JAR file usually in the $TOMCAT_HOME\lib directory.</li>
<br />
<br />


<br />
<br />
<br />
<br />
<br />
 <img src={webimages1} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br />
		<br />
		<br />

		
<br />
<br />
<br />
<b>javac -cp C:\xampp\tomcat\lib\servlet-api.jar Helloworldservlet.java </b>
<br />
<br />
<b>java -cp C:\xampp\tomcat\lib\servlet-api.jar Helloworldservlet</b>
		<br /><br />


</Typography>


       

<br />
		<br />
	<Typography variant="h5" component="h6"> <strong>Building and Installing Servlet</strong></Typography><br />
	 <Typography>
	 
	 To compile our Helloworldservlet.java file, servlet class are required.
	 Tomcat comes with .jar file (servlet-api.jar) for version 6.0.16) that 
	 contains nescessary class files. You can find this jar file usually in the $TOMCAT_HOME\lib directory.
	 
	 <br />
	 <br />
	 To Compile the Helloworldservlet.java 
		using this following command in command propmpt :
<br />
<br />
<br />
<b>javac -cp C:\xampp\tomcat\lib\servlet-api.jar Helloworldservlet.java </b>
<br />
<br />
<br />
This generates the file Helloworldservlet.class that contains the byte code
for our servlet. Create the following directory structure.
<br />
<br />
<ul>
<li>wt</li>
<ul>
<li>WEB-INF</li>
<ul>
<li>classes</li>
</ul>
</ul>
</ul>

<br />
Put this HelloWorldservlet.class file in the $TOMCAT_HOME\webapps\wt\WEB-INF\classes 
directory.
<br />
<br />

The servlet class file is now ready to use. However, we have to inform the web
server about the existence of this servlet and the URL that will be used to 
refer to this servlet. This is specified in the $TOMCAT_HOME\wt\WEB-INF\web.xml file,
which is the configuration XML file for this website. If the file doesnt exist, create this
file first.
<br />
<br />
Now, insert the following line in this file. Some IDE provides interfaces to generate
the same information. However, it is recommended to the beginners to do it manually. When 
you have sufficient knoweldge about how to install servlets and how they work, you can
use IDEs.
<br />
<br />
<br />
<b>XML Program</b>
<br />
<br />
<pre>


&lt;servlet&gt;   <br />
&lt;servlet-name&gt;Helloworld&lt;&#47;servlet-name&gt;  <br />
&lt;servlet-class&gt;Helloworldservlet&lt;&#47;servlet-class&gt;  <br />
  <br />  <br />

&lt;&#47;servlet&gt;  <br />  <br />

&lt;servlet-mapping&gt;  <br />
&lt;servlet-name&gt;Helloworldservlet&lt;&#47;servlet-name&gt;  <br />
&lt;url-pattern&gt;&#47;Helloworld&lt;&#47;url-pattern&gt;  <br />

&lt;&#47;servlet-mapping&gt;  <br />  <br />



</pre>
     
	 <br />
	 <br />
	 <br />
	 This code maps the servlet class(Helloworldservlet.class) file to a
	 servlet name (Helloworld). You then need to map this servlet name to a URL 
	 to be used to invoke this servlet. This is a URL relative to the document root
	 for this website.
	 
	 <br />
	 <br />
	 <br />
	 <pre>
	  <br />  <br />

&lt;servlet-mapping&gt;  <br />
&lt;servlet-name&gt;Helloworldservlet&lt;&#47;servlet-name&gt;  <br />
&lt;url-pattern&gt;&#47;Helloworld&lt;&#47;url-pattern&gt;  <br />

&lt;&#47;servlet-mapping&gt;  <br />  <br />



	 
	 
	 </pre>
	 
	 
	 <br />
	 <br />
	 <br />
	 In our case, the root document is <b>http://localhost:9090/wt</b>. So, 
	 the complete URL of this servlet will be <b>http://localhost:9090/wt/Helloworld</b>.
	 <br />
	 <br />
	 <br />
	 
	</Typography>
  	<br />
  	<br />
  	<br />
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Genericservletsandhttpservlet" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Javahome" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Helloservlet;