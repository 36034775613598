import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/sdlc1.png";
import webindex2 from "../../../../../src/MyComponents/home/Softwareengineering/images/waterfallmodel.png";
import webindex3 from "../../../../../src/MyComponents/home/Softwareengineering/images/iterativemodel.png";
import webindex4 from "../../../../../src/MyComponents/home/Softwareengineering/images/prototypingmodel.png";
import webindex5 from "../../../../../src/MyComponents/home/Softwareengineering/images/spiral.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Sdlc = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>2 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Itroduction To SDLC</strong></Typography><br />
	 <Typography>
<ul>

<li>SDLC Stands for Software Development Life Cycle.</li>

<li>The software development life cycle (SDLC) is a framework defining tasks performed at each step in the software development process.</li>

<li>It consists of a detailed plan describing how to develop, maintain and replace specific software. </li>



</ul>
     <br />
<br />

	</Typography>
  	<br />
	
		
		
		<Typography variant="h5" component="h6"><strong>Objective Of SDLC</strong></Typography><br />
    <Typography>
     <strong>   
SDLC has Three primary objectives:
</strong>
<br />
<br />
<br />
<ul>
<li>Ensure the delivery of high quality systems.</li>
<li>Provide strong management control.</li>
<li>Maximize productivity.</li>
</ul>



		 
	

		<br />
		
		
        </Typography>
		
		
		
	
	
	<br />
<Typography variant="h5" component="h6"><strong>SDLC Phases</strong></Typography><br />
    <Typography>
	
	
	<br />
	<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />
	<br />
	
    <br />
<strong>	Phase 1: Planning </strong>
<br />
<br />
<ul>

<li>Determine if a new system is needed</li>
<li>Three primary tasks:</li>
<li>Define the problem.</li>
<li>Suggest alternative solutions.</li>
<li>Prepare a short report.</li>


</ul>
<br />
<br />
<strong>Phase 2: System Analysis</strong>
<br />
<br />
<ul>
<li>In depth study of the existing system to determine what the new system should do.</li>
<li>In addition to observation and interviews, examine:</li>
<li>Formal lines of authority (org chart)</li>
<li>Standard operating procedures</li>
<li> How information flows</li>
<li>Reasons for any in efficiencies</li>

</ul>


<br />
<br />


<strong>Phase 3: System Design </strong>

<br />
<br />

<ul>

<li>It takes the initial input  For each requirements design elements will be produced</li>
<li>It describes the software features and includes hierarchy diagrams , screen layout diagrams</li>
<li>The output of the stage describe the new system as a collection of modules or subsystems.</li>

</ul>


<br />
<br />

<strong>Phase 4: System Development </strong><br />
<br />
<ul>
<li>Build the system to the design specifications</li>
<li>Develop the software</li>
<li>Acquire the hardware</li>
<li>Create manuals for users and operators</li>

</ul>



<br />
<br />

<strong>PHASE 5: System Testing</strong><br />
<br />
<ul>
<li>Test the new system</li>
<li>Module (unit) test - tests each part of system</li>
<li>Integration testing - tests system as one unit</li>

</ul>


<br />
<br />

<strong>Phase 6: System Implementation</strong><br />
<br />
<ul>
<li>Convert from old system to new system.</li>
<li>Train users</li>
<li>Compile final documentation </li>
<li>Evaluate the new system</li>
<li>User Training</li>

</ul>
<br />
<br />




<strong>Phase 7: System Maintenance</strong>

<br />
<br />
<ul>
<li>Physical repair of the system.</li>
<li>Correction of new bugs found.</li>
<li>System adjustments to environmental changes.</li>
<li>Adjustments for users’ changing needs (adaptive).</li>
<li>Changes to user better techniques when they become available (perfective).</li>

</ul>

   <br />
		<br />	

		<br />
		
		
	
		
		
		</Typography>
			
		
	
	<br />
<Typography variant="h5" component="h6"><strong>SDLC Model</strong></Typography><br />
    <Typography>
    
A framework that describes the activities performed at each stage of a software development project.
<br />
<br />
<br />
<ol>
<li>Waterfall model</li>
<li>Iterative model</li>
<li>Prototyping model</li>
<li>Spiral Model</li>
<li>V model</li>

</ol>
		<br />	

		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Waterfall Model </strong></Typography><br />
    <Typography>
    <br />
	
The Waterfall Model was first Process Model to be introduced. It is also Referred to as a linear-sequential life cycle model .


	
    <br />
	<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />

<br />

<strong>Strengths of Waterfall model</strong>
<br />
<br />
<ul>
<li>Structure minimizes wasted effort.</li>
<li>Works well for technically weak or in experienced staff.</li>
<li>Minimizes planning overhead.</li>


</ul>
    <br />

<br />

<strong>Weakness of Waterfall model</strong>
<br />
<br />
<ul>
<li>Inflexible</li>
<li>Only final stage produces documentation.</li>
<li>Backing up to address mistake is difficult.</li>


</ul>
		<br />	

		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Iterative Model</strong></Typography><br />
    <Typography>
    

<br />
<br />
Iterative Model In Iterative model ,iterative process start with a simple implementation Of a small set of the software requirements
and iteratively enhances the Evolving version until the complete system is implemented and ready to be deployed.


<br />
<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />
    <br />

<br />

<strong>Strengths of Iterative model</strong>
<br />
<br />
<ul>
<li>Results are obtained early and periodically.</li>
<li>Parallel development can be planned.</li>
<li>Progress can be measured.</li>
<li>Less costly to change the scope/requirements.</li>
<li>Testing and debugging during smaller iteration is easy.</li>



</ul>
    <br />

<br />

<strong>Weakness of Iterative model</strong>
<br />
<br />
<ul>
<li>More resources may be required.</li>
<li>Although cost of change is lesser but it is not very suitable for changing requirements.
</li>
<li>System architecture or design issues may arise because not all requirements are gathered in
the beginning of the entire life cycle.</li>


<li>Defining increments may require definition of the complete system.
</li>



</ul>
		<br />	

		<br />	

		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Prototyping Model</strong></Typography><br />
    <Typography>
    


<br />
    <br />
	<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
  
<br />
		
		
	<br />

<br />

<strong>Strengths of Prototyping model</strong>
<br />
<br />
<ul>
<li>Customers can see steady progress.</li>
<li>This is useful when requirements are changing rapidly.</li>


</ul>
    <br />

<br />

<strong>Weakness of Prototyping model</strong>
<br />
<br />
<ul>
<li>It is impossible to know how long it will take.</li>
<li>AThere is no way to know the no of iterations will be required.
</li>


<li>Defining increments may require definition of the complete system.
</li>



</ul>
		<br />	
		<br />	

		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
	<br />
<Typography variant="h5" component="h6"><strong>Spiral Model</strong></Typography><br />
    <Typography>
    
 <br />
	
The Spiral model combines the idea of iterative development With the systematic , controlled aspects of the waterfall model.


	 <br />
	<br />
	<br />
	
<br />
		<Typography>
		<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		
		
	<br />

    <br />

<br />

<strong>Strengths of Spiral model</strong>
<br />
<br />
<ul>
<li>Early iterations of the project are cheapest</li>
<li>Risk decreases.</li>
<li>All iterations meets the project needs</li>


</ul>
    <br />

<br />

<strong>Weakness of Spiral model</strong>
<br />
<br />
<ul>
<li>Complicated</li>
<li>Require attentive & knowledgeable management</li>


</ul>
		<br />	

		<br />
		
		
		<br />	
		<br />
		
		
		<br />	

		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>V- Model</strong></Typography><br />
    <Typography>
    
 <br />
The V-model is SDLC model where execution of processes happens in a sequential manner in V-shape. It is also known as Verification
and Validation model.

	
    <br />

<br />

<strong> Strengths of V model</strong>
<br />
<br />
<ul>
<li>Complicated</li>
<li>Require attentive & knowledgeable management</li>

</ul>
    <br />

<br />

<strong> Weakness of V model</strong>
<br />
<br />
<ul>
<li>Does not easily handle concurrent events</li>
<li>Does not handle iterations or phases</li>
<li>Does not easily handle dynamic changes in requirements</li>
<li>Does not contain risk analysis activities</li>


</ul>
		<br />	

		<br />
		
		

		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>Software Development Life Cycle: The Role Of Testing</strong></Typography><br />
    <Typography>
    
 <br />
Software development life cycle (SDLC) is an integral part of any software application development.
Testing has a lot of influence in SDLC.

In every company, testing is the most vital and precious stage in the software development
life cycle. But, the technique by which it is performed, dissimilar from one company to another company.
		<br />	

		<br />
		
		
		
		</Typography>
			
		
       
       
	<br />
<Typography variant="h5" component="h6"><strong>SDLC Testing Phase</strong></Typography><br />
    <Typography>
    
 <br />
 
 <ul>
<li> The SDLC Testing phase is notionally designed to be carried out after system development is complete.</li>

<li>With the help of different type of testing (e.g. assimilation tests, element testing, user recognition testing system testing
and regression tests etc.) coder may produce a consistent and reliable application. Testing also includes some process e.g.
test analysis, test plan, test design and test execution. </li>

</ul>

		<br />
		
		
		
		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Sdlc;