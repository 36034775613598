import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/linear1.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/linear2.png";
import webindex3 from "../../../../../src/MyComponents/home/Datastructure/images/linear3.png";
import webindex4 from "../../../../../src/MyComponents/home/Datastructure/images/linear4.png";
import webindex6 from "../../../../../src/MyComponents/home/Datastructure/images/linear6.png";
import webindex7 from "../../../../../src/MyComponents/home/Datastructure/images/linear7.png";
import webindex8 from "../../../../../src/MyComponents/home/Datastructure/images/linear8.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Lineardatastructure = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Linear in Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Linear in Data Structure</strong></Typography><br />
	<Typography variant="h5" component="h6"> <strong>What is Search</strong></Typography><br />
	


	<Typography>
    
The definition of a search is the process of looking for something or someone. Example : An example of a search is a quest to find a mission 
person.

<br />
<br />
<br />
<b>Linear Search  </b>
<br />
<br />
Algorithm involves checking all the elements of the array (or any other structure) one by one and in sequence until the desired result
is found.
<br />
<br />
<b>OR</b>
<br />
<br />
In this type of search, a sequential search is made over all items one by one. Every item is checked and if a match is found then that 
particular item is returned, otherwise the search continues till the end of the data collection.

<br />
<br />
<br />
<b>Implementing Linear Search </b>
<br />	
<br />
<ul>

<li>Following are the steps of implementation that we will be following:</li>

<li>Traverse the array using a for loop.</li>

<li>In every iteration, compare the target value with the current value of the array.</li>

<li>If the value match, return the current index of the array.</li>

<li>If the values do not match, move on to the next array elements.</li>

<li>If no match is found, return -1. </li>
</ul>	
<br />
<br />
<br />
<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		
		

<br />

	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Algorithm</strong></Typography><br />
    <Typography>
      <ul>
	  <li>Linear Search (Array A, Value x)</li>
	  <li>Step 1: Set i to 1</li>
	  <li>Step 2: if i> n then go to step 7</li>
	  <li>Step 3: if A[i] = x then go to step 6</li>
	  <li>Step 4: Set i to i + 1</li>
	  <li>Step 5: Go to Step 2</li>
	  <li>Step 6: Print element x found at index i and go to step 8</li>
	  <li>Step 7: Print element not found</li>
      <li>Step 8: Exit</li>	  
        
	  </ul>
    <br />
    <br />
	
<b># Wap in c to implementing Linear Data Structure.</b>
<br />
<br />
		<br />
		
		<pre>
		
		
		
#include &lt;stdio.h&gt; <br /><br />

int main()<br />
&#123;<br /><br />
int  a[5], i, x, n;<br /><br />

printf("How many elements? ");<br />
scanf("%d", &n);<br /><br />

printf("Enter array elements: n ");<br />
for(i=0; i&lt;n; i++)<br />
&nbsp;    scanf("%d", &a[i]);<br /><br />
	
printf("\n Enter elements to search ");<br />
scanf("%d", &x );<br /><br />

for(i=0; i&lt;n; i++)<br />
&nbsp;&nbsp;&nbsp;    if(a[i] == x)<br />
&nbsp;&nbsp;	   break;<br /><br />
	   
if(i&lt;n)<br />
&nbsp;&nbsp;   printf("Element found at index %d ", i);<br />
else<br />
&nbsp;&nbsp;   printf("Element not found ");<br />
return 0;<br />
<br /><br />
&#125; <br />
		
		</pre>
		
		<br />
		<br />
		
		
		<Typography>
		<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		 <br />
    <br />
	
<b># Wap in python to implementing Linear Data Structure.</b>
<br />
<br />
		<br />
		
		<pre>
		
		
#!/usr/bin/python  <br /><br />

pos = -1     <br /><br /><br /> 


def search(list, n):<br />
&nbsp;&nbsp;   i = 0<br />
<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while i&lt; len(list):<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if list[i] == n:<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;globals() ['pos'] = i<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return True<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i = i + 1<br /><br />


list = [2, 4, 5, 67, 87, 8, 9, 1]<br /><br />

n = 9<br /><br />

if search(list, n):<br />
&nbsp;&nbsp;&nbsp;&nbsp;print("Value", n ,"is Found and its position is ", pos)<br />
else:<br />
&nbsp;&nbsp;&nbsp;&nbsp;print("Value", n , "is Not Found")<br />




		
		
		</pre>
        </Typography>
		
	
		
	<br />
	<br />
	<br /><Typography>
		<img src={webindex7} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
	<br /><br />
	<br /><Typography>
		<img src={webindex8} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
	<br />
	<br />
		

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Infixtoprefix" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Postfixexpression" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Lineardatastructure;