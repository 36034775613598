import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/dbms/images/DBMShackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Entitiesindbms = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Database Management System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>3 March 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>DBMS - Entities</strong></Typography><br />
	 <Typography>
    An entity is an object that exists. It doesn't have to do anything. It just has to exists.
	In database administration an entity can be a single thing, person, place or object.
	<br />

    Data can be stored about such entities.
	

     
	</Typography>
  	<br />
	
	<br />
	<Typography variant="h5" component="h6"><strong>Attributes </strong></Typography><br />
	  <Typography>
       
    An attributes define the information about the entity that need to be stored.
		<br />
		If the entity is an employee, attribute could include name, employee ID, work location, Destination.
		<br />
		
		
        </Typography>
		
		<br />
		<br />
		
		<Typography variant="h5" component="h6"><strong>Entity Set </strong></Typography><br />
    <Typography>
        
		
		An entity set is a group of entities that pass the same set of Attributes.
		<br />
		Each entity in an entity set has its own set of values for the attributes which make it distinct from
		other entities in a table.
		
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		
		
	
	<br />
	
	
	<br />
	<Typography class=" text-center" variant="h3" component="h4">
	  Student Table
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Roll no</th>
      <th scope="col">Name</th>
      <th scope="col">Course</th>
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">067</th>
      <td>Atul</td>
      <td>Comp Sci</td>
      
    </tr>
	<tr>
      <th scope="row">068</th>
      <td>HackerBoY</td>
      <td>Ethical Hacking</td>

    </tr>
    <tr>
      <th scope="row">068</th>
      <td>HackingTruth </td>
      <td>Pentesting </td>
     
    </tr>
    <tr>
      <th scope="row">069</th>
      <td>Atul</td>
	  <td>Web Dev</td>
      
    </tr>
  </tbody>
</table>


	<br />	
	<br />
	
	
	
	

	
	
	
	<br />
<Typography variant="h5" component="h6"><strong>Types of Entities Set </strong></Typography><br />
    <Typography>
        
		<ol>
    <li>Strong Entites</li>
    <li>Weak Entites</li>
    
		</ol>
		
		<br />
		<br />
		
		</Typography>
	
	<br />
<Typography variant="h5" component="h6"><strong>Strong Entities </strong></Typography><br />
    <Typography>
        
		
		<br />
		An entity set that has a primary key using which entities in the table can be uniquely identified.
		<br />
		In an ER diagram the strong entity set is represented by the rectangle. Here is the primary key is underlined with the solid line.
		
		
		
    <Typography>
        
	<br />
	<br />
	
	<table class="table table-dark table-hover">
	 	 
  <thead>
    <tr>
      
      <th scope="col">Student</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>Roll no</td>
      
    </tr>
	<tr>
      
      <td>Name</td>
      
    </tr>
    <tr>
      
      <td>Course </td>
     
    </tr>
    
  </tbody>
</table>


	<br />	
	<br />
	 <br />
	
	
	
		
        </Typography>		
        </Typography>
		

	<br />
	
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Weak Entites </strong></Typography><br />
    <Typography>
        
	 A weak entity set doesn't have any primary key.
	 <br />
	 The weak entity gets it primary key combining the partial key of the weak entity plus the primary key of identifying strong entity set.
	 <br />
	 <br />
	 Here is the primary key of the weak entity set section is course_id + sec_id, Year, Semester.
		<br />
		Attributes of weak entity set are underlined with the dashed line. The relationship of a weak entity set and strong
		set is shown with double double diamond.
		
		<br />
		<br />
		
		</Typography>
	<Typography class=" text-center" variant="h3" component="h4">
	<br />
	<br />
	  <Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">Course</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>Course_id</td>
      
    </tr>
	<tr>
      
      <td>name</td>
      
    </tr>
    <tr>
      
      <td>year </td>
     
    </tr>
    
  </tbody>
  <caption class=" text-center">Strong Entity Set</caption>
</table>


	<br />	
	<br />
	 <br />
	 <table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      
      <th scope="col">Section</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td>Section_id</td>
      
    </tr>
	<tr>
      
      <td>year</td>
      
    </tr>
    <tr>
      
      <td>semester </td>
     
    </tr>
	
    
  </tbody>
  <caption class=" text-center">Weak Entity Set</caption>
</table>


	 </Typography>
	 </Typography>
	

     
	 


		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Relationalalgebraarticle" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Primarykeyindbms" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Entitiesindbms;