import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody,  } from "@material-ui/core";
//import { categories } from "../../Categoriesconstant/Data";
import { Link, NavLink } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)'
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
	
})

const Softwareengineeringcategorieshome = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Webcreateview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <NavLink to="/"><TableCell>
		 
		All Categories
		
		 </TableCell></NavLink>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	  	  
	  
				    <TableRow>
				  
				  
				  <NavLink to="/Evolvingroleofsoftware" ><TableCell> Evolving Role of Software  </TableCell></NavLink>

	              </TableRow>
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Changing Nature of S/W  </TableCell>

	              </TableRow>	  
				  
				    
				<TableRow>
				
				<NavLink to="/Layeredtechnology"><TableCell> Layered Technology</TableCell></NavLink>
				
				</TableRow>
				
				        
				<TableRow>
				
				<NavLink to="/Umldiagram"><TableCell>UML Diagram</TableCell></NavLink>
				
				</TableRow>
				
				   	        
				<TableRow>
				
				<NavLink to="/Softwareprocessmodel"><TableCell>Software Process Model</TableCell></NavLink>
				
				</TableRow>
				
				    
				     	        
				<TableRow>
				
				<NavLink to="/Cohesionandcoupling"><TableCell>Cohesion And Coupling</TableCell></NavLink>
				
				</TableRow>
				
				         	        
				<TableRow>
				
				<NavLink to="/Functionalandnonfunctional"><TableCell>Functional And Non-Functional</TableCell></NavLink>
				
				</TableRow>
				
				
				         	        
				<TableRow>
				
				<NavLink to="/Casetool"><TableCell>CASE Tool</TableCell></NavLink>
				
				</TableRow>
				
				
				
				       	        
				<TableRow>
				
				<NavLink to="/Blackboxtesting"><TableCell>Black Box Testing</TableCell></NavLink>
				
				</TableRow>
				
				
				
				       	        
				<TableRow>
				
				<NavLink to="/Whiteboxtesting"><TableCell>White Box Testing</TableCell></NavLink>
				
				</TableRow>
				
				
				
				       	        
				<TableRow>
				
				<NavLink to="/Softwarerequirementspecification"><TableCell>SRS</TableCell></NavLink>
				
				</TableRow>
				
				
				
				
				
				
				
		</TableBody>
	 </Table>
	
	
   
	
	</>
   )
};

export default Softwareengineeringcategorieshome;