
import { makeStyles, Box, Typography } from "@material-ui/core";


const useStyles = makeStyles({
	

	 image: {
		
		 
		 background: `url(${'https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg'}) `,
	     flexDirection: 'column', // for text
	alignItems: 'center', // for text
	justifyContent: 'center', // for text
	width: '100%',
	height: '50vh',
	display: 'flex',
	
	
	

	
 '& :first-child': {
		  fontSize: 30,
		  color: '#ffffff',
		  lineHeight: 1,
		  textAlign: 'center',
		  textTransform: 'uppercase',
		  
	},
	
	'& :last-child': {
								
		  fontsize: 20,
		  textAlign: 'center',
		  padding: '3px',
		  marginTop: '15px',
		  background: '#ffffff',
		  textTransform: 'uppercase'
		 

	  }	
   
	}
});		

const Banner = () => {
	const classes = useStyles();
   
   return (
    
   <Box className ={classes.image }>
 
   <Typography>hacking truth blog /DataStructure</Typography>
   <Typography>We Will Update soon</Typography>
   </Box>
  
   
    
   
);
  };
  
  

export default Banner;