import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Javaservletspostdata from "./Javaservletspostdata";
import CGIpostdata from "./CGIpostdata";
import Lifecycleofaservletspostdata from "./Lifecycleofaservletspostdata";
import Genericservletsandhttpservletpostdata from "./Genericservletsandhttpservletpostdata";
import Helloservletpostdata from "./Helloservletpostdata";


const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Servletsinjava"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Javaservletspostdata />
		</Link>
		</Grid>
		
		 
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/CGIinjava"} style={{textDecoration: 'none', color: 'inherit'}}>
		<CGIpostdata />
		</Link>
	    </Grid>
		
		
		<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Lifecycleofaservlets"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Lifecycleofaservletspostdata />
		</Link>
	    </Grid>
		
		
	<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Genericservletsandhttpservlet"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Genericservletsandhttpservletpostdata />
		</Link>
	    </Grid>
			
	<Grid item lg={3} sm={4} xs={12}>
		<Link to={"/Helloservlet"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Helloservletpostdata />
		</Link>
	    </Grid>
		
		
	
	</>
	 
   )
}

export default Posts;
