import  { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";


import Jquerybasicpostdata from "./Jquerybasicpostdata";
import Jquerysyntaxpostdata from "./Jquerysyntaxpostdata";
import Jqueryeventlistpostdata from "./Jqueryeventlistpostdata";
import Jqueryclickeventpostdata from "./Jqueryclickeventpostdata";
import Jqueryhovereventpostdata from "./Jqueryhovereventpostdata";
import Jqueryhideeventpostdata from "./Jqueryhideeventpostdata";
import Jqueryblurpostdata from "./Jqueryblurpostdata";

const Posts = () => {
	
	return (
	
	<>
  
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jquerybasic"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jquerybasicpostdata />
		</Link>
		</Grid>
		
		 
	
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jquerysyntax"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jquerysyntaxpostdata />
		</Link>
		</Grid>
		
		 
	
		 
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryeventlist"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryeventlistpostdata />
		</Link>
		</Grid>
		


	
		 
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryclickevent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryclickeventpostdata />
		</Link>
		</Grid>
		

		 
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryhideevent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryhideeventpostdata />
		</Link>
		</Grid>
		

		 
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryhoverevent"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryhovereventpostdata />
		</Link>
		</Grid>
		


 
	    <Grid item lg={3} sm={4} xs={12}>
	    <Link to={"/Jqueryblur"} style={{textDecoration: 'none', color: 'inherit'}}>
		<Jqueryblurpostdata />
		</Link>
		</Grid>
		





	
	</>
	 
   )
}

export default Posts;
