import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Softwareengineering/images/classdiagram.png";
import webindex2 from "../../../../../src/MyComponents/home/Softwareengineering/images/objectdiagram.png";
import webindex3 from "../../../../../src/MyComponents/home/Softwareengineering/images/statemachine.png";
import webindex4 from "../../../../../src/MyComponents/home/Softwareengineering/images/activitydiagram.png";
import webindex5 from "../../../../../src/MyComponents/home/Softwareengineering/images/componentdiagram.png";
import webindex6 from "../../../../../src/MyComponents/home/Softwareengineering/images/packagediagram.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Cohesionandcoupling = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 Aug 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Cohesion And Coupling</strong></Typography><br />
	 <Typography>
	 
	 <strong>Coupling (computer programming)</strong>
	 <br />
	 <br />
In software engineering, coupling is the degree of interdependence between software
modules; a measure of how closely connected two routines or modules are; the strength of the relationships between modules.
<br />
     <br />
Coupling is usually contrasted with cohesion. Low coupling often correlates with high cohesion, and vice versa.
Low coupling is often a sign of a well- structured computer system and a good design, and when combined with high
cohesion, supports the general goals of high readability and maintainability. 


     <br />
     <br />
<br />
	</Typography>
  	<br />
	
		
		
		<Typography variant="h5" component="h6"><strong>Types of Coupling</strong></Typography><br />
    <Typography>
 <strong>Procedural programming</strong> 
 <br />
   <br />
   
A module here refers to a subroutine of any kind, i.e. a set of one or more statements having a name and preferably its
own set of variable names.


		<br /><br />
		<br />
		
 <strong>Content coupling (high)</strong>
<br /><br />
Content coupling (also known as Pathological coupling) occurs when one module modifies or relies on the internal workings
of another module (e.g., accessing local data of another module). In this situation, a change in the way the second module
produces data (location, type, timing) might also require a change in the dependent module.

<br />
		<br /><br />
 <strong>Common coupling </strong>
<br /><br />
Common coupling (also known as Global coupling) occurs when two modules share the same global data
(e.g., a global variable). Changing the shared resource might imply changing all the modules using it.

<br />
		<br /><br />
		
		
		
 <strong>External coupling</strong>
<br /><br />
External coupling occurs when two modules share an externally imposed data format, communication protocol, or device interface.
This is basically related to the communication to external tools and devices.

<br />
	
Control coupling Control coupling is one module controlling the flow of another, by passing it information on what to do
(e.g., passing a what-to-do flag).

<br />
		<br />
<br />
 <strong>Stamp coupling (Data-structured coupling)</strong>
<br /><br />
Stamp coupling occurs when modules share a composite data structure and use only parts of it, possibly different parts
(e.g., passing a whole record to a function that only needs one field of it).In this situation, a modification in a
field that a module does not need may lead to changing the way the module reads the record.

<br />
		<br />
<br />
		
        </Typography>
		
		
		
		<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Data coupling</strong></Typography><br />
    <Typography>
	
<i><b>Data coupling</b></i> occurs when modules share data through, for example, parameters. Each datum is an elementary piece, and
these are the only data shared (e.g., passing an integer to a function that computes a square root).



<strong>Message coupling (low)</strong>

<br />
		<br />
This is the loosest type of coupling. It can be achieved by state decentralization (as in objects) and component
communication is done via parameters or message passing.

<br />
		<br />
<br />
<strong>No coupling</strong>

<br />
		<br />
Modules do not communicate at all with one another. 

<br />
		<br />
<br />


		

    <br />
		
		
       

		</Typography>
			
		<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Object Oriented Programming</strong></Typography><br />
    <Typography>
	
<i><b>Data coupling</b></i> occurs when modules share data through, for example, parameters. Each datum is an elementary piece, and
these are the only data shared (e.g., passing an integer to a function that computes a square root).


Subclass coupling
<br />
		<br />
Describes the relationship between a child and its parent. The child is connected to its parent, but the parent is not
connected to the child.


<br />
		<br />
<br />

<strong>Temporal coupling</strong>
<br />
		<br />
When two actions are bundled together into one module just because they happen to occur at the same time.
<br />


In recent work various other coupling concepts have been investigated and used as indicators for different modularization
principles used in practice.


       <br />
		<br />
<br />


		</Typography>
			
		<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Disadvantages</strong></Typography><br />
    <Typography>

Disadvantages Tightly coupled systems tend to exhibit the following developmental characteristics, which are often seen as
disadvantages:

<br />
<br />
<ul>
<li>A change in one module usually forces a ripple effect of changes in other modules.
</li>

<li>Assembly of modules might require more effort and/or time due to the increased inter-module dependency.
</li>
<li>A particular module might be harder to reuse and/or test because dependent modules must be included. 
</li>

</ul>

       <br />
		<br />
<br />


		</Typography>
			
			<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Cohesion</strong></Typography><br />
    <Typography>

In computer programming, cohesion refers to the degree to which the elements of a module belong together. Thus, cohesion
measures the strength of relationship between pieces of functionality within a given module.

       <br />
       <br />
		
<b>For example, in highlycohesive systems functionality is strongly related.</b>
<br /><br />
Cohesion is an ordinal type of measurement and is usually described as “high cohesion” or “low cohesion”.



       <br />
<br />


<strong>Cohesion</strong>

Cohesion is a qualitative measure, meaning that the source code to be measured is examined using a rubric to
determine a classification. Cohesion types, from the worst to the best, are as follows:



       <br />
		<br />
<br />


		</Typography>
			
		
       
	<br />
	
	<br />
<Typography variant="h5" component="h6"><strong>Types of Cohesion</strong></Typography><br />
    <Typography>

<strong>Coincidental cohesion (worst)</strong>

<br />
		<br />
Coincidental cohesion is when parts of a module are grouped arbitrarily; the only relationship between the parts is
that they have been grouped together (e.g. a “Utilities” class).


Logical cohesion Logical cohesion is when parts of a module are grouped because they are logically categorized to do the
same thing even though they are different by nature (e.g. grouping all mouse and keyboard input handling routines).

<br />
		<br />
<br />


<strong>Temporal cohesion</strong>

<br />
		<br />
Temporal cohesion is when parts of a module are grouped by when they are processed - the parts are processed at a particular
time in program execution (e.g. a function which is called after catching an exception which closes open files, creates an
error log, and notifies the user).

<br />
		<br />
<br />


<strong>Procedural cohesion</strong>

<br />
		<br />
Procedural cohesion is when parts of a module are grouped because they always follow a certain sequence of execution (e.g. a function which checks file permissions and then opens the file).

<br />
		<br />
<br />


<strong>Communicational/informational cohesion</strong>

<br />
		<br />
Communicational cohesion is when parts of a module are grouped because they
operate on the same data (e.g. a module which operates on the same record of information).

<br />
		<br />
<br />

<strong>Sequential cohesion</strong>

<br />
		<br />
Sequential cohesion is when parts of a module are grouped because the output from one part is the input to another
part like an assembly line (e.g. a function which reads data from a file and processes the data).
<br />
		<br />
<br />


<strong>Functional cohesion (best)</strong>

<br />
		<br />
Functional cohesion is when parts of a module are grouped because they all contribute to a
single well- defined task of the module (e.g. Lexical analysis of an XML string).

While functional cohesion is considered the most desirable type of cohesion for a software module, it may not be achievable. There are cases where communicational cohesion is the highest level of cohesion that can be attained under the circumstances. 







       <br />
		<br />
<br />


		</Typography>
			
		
       

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Layeredtechnology" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Sdlc" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Cohesionandcoupling;