
import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";

const  useStyles = makeStyles((theme) => ({
	 
	 
	 
	 container: {
		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 
	 
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover'
		 
		 
	 },
	 
	 icons: {
		 
		 float: 'right',
		 
		 
	 },
	 
	 icon: {
		 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 
	 heading: {
		 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0',
		 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 
		 
		 } 
		 
	 }, 
	 
	 
	 paragraph: {
		 
  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 
		 
		 } 
		 
	 }, 
	 
	 
	 
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
	 
	
}))

const Articledetails = () => {
	
	
	const classes = useStyles();
	
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
	 <Typography className={classes.heading} > Penetration Testing Fundamentals</Typography>
	 
	 <Box className={classes.subheading}> 
	      <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span></Typography>
<Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
	</Box>
	
	<Box className={classes.paragraph}>
	<Typography> <strong>Penetration Testing Fundamentals</strong></Typography><br />
	
	<Typography>Learn the important ethics and methodologies behind every pentest.
 
     Before teaching you the technical hands-on aspects of ethical hacking, you'll need to understand more about what a penetration tester's job responsibilities
     are and what processes are followed in performing pentests (finding vulnerabilities in a clients application or system).
     
     The importance and relevancy of cybersecurity are ever-increasing and can be in every walk of life. News headlines fill our screens, reporting yet another
     hack or data leak. Penetration Testing Fundamentals
     
     <br />
     Cybersecurity is relevant to all people in the modern world, including a strong password policy to protect your emails or to businesses and other organisations
     needing to protect both devices and data from damages.
     
     A Penetration test or pentest is an ethically-driven attempt to test and analyse the security defences to protect these assets and pieces of information.
     A penetration test involves using the same tools, techniques, and methodologies that someone with malicious intent would use and is similar to an audit.


	</Typography>
	<br />
	<Typography>
	OR 
	<br />
	<br />
	 
    A penetration test, also known as a pen test, is a simulated cyber attack against your computer system to check for exploitable vulnerabilities. 
	In the context of web application security, penetration testing is commonly used to augment a web application firewall (WAF).
	
	
	
	</Typography>
	
	<br />
	

	<Typography><strong>Penetration Testing Ethics</strong></Typography><br />

 <Typography>
        The battle of legality and ethics in cybersecurity, let alone penetration testing is always controversial. Labels like "hacking" and "hacker" often hold
		negative connotations, especially in pop culture, thanks to a few bad apples. The idea of legally gaining access to a computer system is a challenging concept
		to grasp -- after all, what makes it legal exactly? Recall that a penetration test is an authorised audit of a computer system's security and defences as agreed
		by the owners of the systems. The legality of penetration is pretty clear-cut in this sense; anything that falls outside of this agreement is deemed unauthorised.
        Before a penetration test starts, a formal discussion occurs between the penetration tester and the system owner. Various tools, techniques, and systems to be
		tested are agreed on. This discussion forms the scope of the penetration testing agreement and will determine the course the penetration test takes.
        
        Companies that provide penetration testing services are held against legal frameworks and industry accreditation. For example, the National Cyber Security
		Centre (NCSC) has the CHECK accreditation scheme in the UK. This check means that only "[CHECK]  approved companies can conduct authorised penetration tests 
		of public sector and CNI systems and networks." (NCSC).
        <br />
		<br />
        Ethics is the moral debate between right and wrong; where an action may be legal, it may go against an individual's belief system of right and wrong.
        <br />
		<br />
        Penetration testers will often be faced with potentially morally questionable decisions during a penetration test. For example, they are gaining access 
		to a database and being presented with potentially sensitive data. Or they are, perhaps, performing a phishing attack on an employee to test an organisation's 
		human security. If that action has been agreed upon during the initial stages, it is legal -- however ethically questionable.
        <br />
		<br />
		<br />
		Update.....please wait...
		<br />
		<br />
        </Typography>
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>

	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	</>

	
	
 )
}


export default Articledetails;