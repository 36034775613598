import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist1.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist2.png";
import webindex3 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist3.png";
import webindex4 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist4.png";
import webindex5 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist5.png";
import webindex6 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist6.png";
import webindex7 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist7.png";
import webindex8 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist8.png";
import webindex9 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist9.png";
import webindex10 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist10.png";
import webindex11 from "../../../../../src/MyComponents/home/Datastructure/images/linkedlist11.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Linkedlist = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Linked List in Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Linked List in Data Structure</strong></Typography><br />
	<Typography variant="h5" component="h6"> <strong>What is Search</strong></Typography><br />
	


	<Typography>
    A linkedlist is a linear data structure  in which the elements are not stored at contiguous memory locations.

<br />
<br />
<b>OR</b>
<br />
<br />
In simple word, a linked list consists of nodes where each node contains a data fields and a 
reference (link) to the next node.
<br />
Elements in linked list is called Node.

<br />
<br />
<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		

<br />

	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Types of Linkedlist</strong></Typography><br />
    <Typography>
      <ul>
	  <li>Single Linked List</li>
	  <li>Double Linked LIst</li>
	  <li>Circular LInked List</li>
       
        
	  </ul>
    <br />
    <br />
	
<b># Why we linked list over array?</b>
<br />
<br />
<ul>

<li>The size of an array must be known in advance before using in the program.</li>  
<li>It allocates the memory dynamically.</li>	
<li>All the nodes of linekd list are  non-contigously stored in the memory.</li>	
<li>Sizing is no longer a problem since we do not need to define its size at the time.</li>


</ul>
	
		
        </Typography>
		
	
		
<br />
	
	<br />
	
	<Typography><strong>Single Linked List</strong></Typography><br />
    <Typography>
      <ul>
	  <li>Single linked list can be defined as the collection of order set elements.</li>
	  <li>The number of elements may vary according to need of the program.</li>
	  <li>A node in the single linked is consists of two parts - <b>Data part</b> and <b>Link part</b>.</li>
       <li>Data part of the nodes store actual information that is to be represented by the node.</li>
       <li>Link part of the nodes stores the address of its immediate successor like pointer.</li>
        <li>Also known as one-way list.</li>
		<li>Data fields stores information.</li>
		<li>Pointer points to the next node.</li>
		<li>Last node contains a null value.</li>
	  </ul>
    <br />
    <br />
	
<b># How to declare linked list?</b>
<br />
<br />
<pre><br />
Struct node <br />
&nbsp;&nbsp;&nbsp;&#123;<br />
&nbsp;&nbsp;&nbsp;&nbsp;int info;<br />
&nbsp;&nbsp;&nbsp;&nbsp;struct node *next;<br />
&nbsp;&nbsp;&nbsp;&#125;;<br /><br />



</pre>
	<br />
		<br /><br />
<br />
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		
        </Typography>
		
	
		

	<br />
	
	<Typography><strong>Node Creation</strong></Typography><br />
    <Typography>
   
	
<br />
<pre><br />
Struct node <br />
&nbsp;&nbsp;&nbsp;&#123;<br />
&nbsp;&nbsp;&nbsp;&nbsp;int data;<br />
&nbsp;&nbsp;&nbsp;&nbsp;struct node *next;<br />
&nbsp;&nbsp;&nbsp;&#125;;<br /><br />
<br />
Struct node *head, *ptr;  <br />
ptr = (struct node *) malloc (SizeOf(struct node *));<br />




</pre>
	<br />

		
        </Typography>
		
	<br />
	
	<Typography><strong>First operation of Single Linked List</strong></Typography><br />
    <Typography>
     <b>Traversing a single linked list</b>
    <br />
    <br />
	Traversing is the most common operation that is performed in almost every scenario of single 
	linked list. Traversing means visiting each node of the list once in order to perform some
	operation on that. This will be done by using the following Statement.
<br />
<br />
<pre><br />
ptr = head<br />
while (ptr != NULL) <br />
&nbsp;&nbsp;&nbsp;&#123;<br />
&nbsp;&nbsp;&nbsp;&nbsp; ptr = ptr -> next<br />
&nbsp;&nbsp;&nbsp;&#125;;<br /><br />



</pre>
	<br />
		<br /><br />

        </Typography>
		
	
		

	<br />
	<Typography><strong>Algorithm</strong></Typography><br />
    <Typography>
    
    <br />
    <br />
        <b>Step 1 :- </b>SET PTR = HEAD <br />
        <b>Step 2 :- </b>IF PTR = NULL <br />
		
		WRITE "EMPTY LIST" <br />
		GOTO STEP 6 <br />
		END OF IF
        <b>Step 3 :- </b> REPEAT STEP 4 and 5 UNTIL PTR != NULL<br />
        <b>Step 4 :- </b>PRINT PTR -> DATA <br />
		<b>Step 5 :- </b>PTR = PTR -> NEXT <br />
&nbsp;&nbsp;&nbsp;&nbsp;[END OF LOOP]

<br />
<br />

Goal is to traverse the list from starting to end node.
	
<br />
			<br /><br />
<br />
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
Type of pointer that points to the node currently being processed <br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;node = next[node]
<br />
<br />
		
        </Typography>
		
	<br />
	
		


	<br />
	<Typography><strong>Algorithm Steps</strong></Typography><br />
    <Typography>
    Start holds the address of the Ist node.
    <br />
    <br />
       
	   <ol>
	   <li>Set node = Start [initialize pointer variable node]</li>
	   <li>Repeat Step 3 and 4 while node != NULL</li>
	   <li>Process info [node] [apply process to info [node]</li>
	   <li>Set node = next [node] (move pointer to the next node)</li>
	   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;END OF LOOP<br /><br />
	   <li>Exit</li>
	   
	   </ol>
<br />
<br />

		
        </Typography>
		
	<br />
			
	<br />
	
	<Typography><strong>Insertion of a node in Single Linked List</strong></Typography><br />
    <Typography>
 
   
	Used to add an element in an existing linked list (ordered / unordered)<br />
	Ordered and unordered means asscending or descending order.<br /><br />
	<b>position for insertion</b> :- <br /><br />
	<ul><li>Beginning</li>
	                                                    <li>End</li>
	                                                    <li>Specific Location</li>
	                                                    <li>Given node</li>
	</ul>
	
<br />
<br />

	<br />
		<br /><br />

        </Typography>
		
	
		

	<br />
	<Typography><strong>For the Beginning</strong></Typography><br />
    <Typography>
    Steps -
    <br />
    <br />
        <ul>
		<li>Create a new node </li>
		<li>List empty then insert as first node</li>
		<li>Adjust the next pointer field of the node.</li>
 
</ul>
<br />
<br />

<b>Insert at the Beginning</b>	
<br />
			<br /><br />
<br />
		<Typography>
		<img src={webindex3} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<br /><br />
<br />
		<Typography>
		<img src={webindex4} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<b>Algorithm</b>
<br />
<br />
Start hold the address of the Ist node<br /><br />
<ol>
<li>Create a new node named as node</li>
<li>If node == NULL then, write "out of memory space " & exist </li>
<li>Set info [node] = x</li>
<li>Set next [node] = Start</li>
<li>Set start = node</li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
</ol>		
        </Typography>
		
	<br />
		

	<br />
	<Typography><strong>Insert at a Specific location of Linked List</strong></Typography><br />
    <Typography>
    Steps -
    <br />
    <br />
        <ul>
		<li>To insert an element at specific location, it is necessary to track the starting node, current node and previous node. </li>
		<li>Scan from the begining to the desired node where we want to insert the new node.</li>
		
		
		
</ul>
<br />
<br />
			<br /><br />
<br />
		<Typography>
		<img src={webindex5} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<br /><br />
<br />
		<Typography>
		<img src={webindex6} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<b>Algorithm</b>
<br />
<br />
HINT - curr = current<br />x = 88<br /><br />
start hold the address of the first node <br /><br />
<ol>
<li>Set curr = start</li>
<li>Create a new node named as node</li>
<li>If node == NULL then, "out of memory space</li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
<li>Set info [node] = x</li>
<li>Set next [node] = NULL</li>
<li>Read Location</li>
<li>Set i = 1</li>
<li>Repeat 9 to 11 Steps, while curr != NULL & i &lt; Loc</li>
<li>Step prev = curr</li>
<li>Set curr = next [curr]</li>
<li>Set i = i + 1</li>
<li>If curr = NULL then "Position not found" EXIT</li>
<li>Set next [prev] = node</li>
<li>Set next [node] = curr</li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
</ol>		
        </Typography>
		
	<br />
		

			
	<br />
	<Typography><strong>Delete of a node from a Single Linked List</strong></Typography><br />
    <Typography>
    
    <br />
    <br />
        <ul>
		<li>Use to remove elements from single linked list.</li>
		<li>If the list is empty deletion is not possible.</li>
		<li>If list contains one node, after deletion will be empty</li>
		<li>If the node is deleted, the memory space for the deleted node is deallocated.</li>
		
		
		
		
</ul>
<br />
<br />
<b>Deletion can be done - </b>
			<br /><br />
			<ol>
			<li>Delete first node</li>
			<li>Delete last node</li>
			<li>Delete at a specific location</li>
			<li>Delete after a given node</li>
			</ol>
<br />
		<Typography>
		<img src={webindex7} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<br /><br />
<br />
		<Typography>
		<img src={webindex8} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<b>Algorithm</b>
<br />
<br />
start holds the address of the first node <br /><br />
<ol>
<li>temp = start</li>
<li>If start = NULL then ' underflow ' </li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
<li>Set start = next [start]</li>
<li>Free the space associated with temp</li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
</ol>		
        </Typography>
		
	<br />
		
			
			
			
	<br />
	<Typography><strong>Delete of a node from a Specific location [ Linked List ]</strong></Typography><br />
    <Typography>
    
    <br />
    <br />
        <ul>
		<li>Hence, we know the location of the node to be deleted.</li>
		<li>We need to traverse the linked list to obtain the location of the node, if it exist.</li>
		<li>We move the pointer to that particular node, free the node & adjust the pointer to make
		the list in a linked list.</li>
		<li>Before the deletion operation we must check while the list empty or not.</li>
		
		
		
		
</ul>
<br />
<br />
<b>Deletion can be done - </b>
			<br /><br />
			<ol>
			<li>Delete first node</li>
			<li>Delete last node</li>
			<li>Delete at a specific location</li>
			<li>Delete after a given node</li>
			</ol>
<br />
		<Typography>
		<img src={webindex9} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<br /><br />
<br />
		<Typography>
		<img src={webindex10} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
<br />
<b>Algorithm</b>
<br />
<br />
start holds the address of the first node <br /><br />
<ol>
<li>Set node = start</li>
<li>Set temp = start</li>
<li>If node = NULL then write ' Underflow ' </li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
<li>Set i = 1</li>
<li>Read Loc</li>
<li>Repeat steps 7 to 9 while node != NULL & i &lt; Loc (Location)</li>
<li>Set temp = node</li>
<li>Set node = next [node]</li>
<li>Set i = i + 1</li>
<li>If node == NULL then "Position not found " </li>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
<li>Set next [temp] = next [node]</li>
<li>Free the space associated with node</li>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
</ol>		
        </Typography>
		
	<br />
		
			
			
			
			
	<Typography><strong>Traversing a Double Linked List</strong></Typography><br />
    <Typography>
     Traversing is the most common operation in case of each data structure. For this purpose, copy the
	 head pointer in any of the temporary pointer ptr.
    <br />
    <br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>ptr = head</b>
	<br />
	<br />
     then, traverse through the list by using while loop keep shifting value of pointer variable 
	  ptr until we find the last node.
	  <br />
	  The last node contains null in its next node.
<br />
<br />
<pre><br />
while (ptr != NULL)<br />
&nbsp;&nbsp;&nbsp;&#123;<br />
&nbsp;&nbsp;&nbsp;&nbsp;printf("%d\n", ptr -> data);<br />
&nbsp;&nbsp;&nbsp;&nbsp;ptr = ptr -> next;<br />
&nbsp;&nbsp;&nbsp;&#125;<br /><br />

<br />
<br />
Also, traversing means visiting each node of the list once to perform some specific operation.
<br />
Here, we are printing the data associated with each node of the list.

</pre>
	<br />
		<br /><br />
<br />
		<Typography>
		<img src={webindex11} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       

<br />
<br />
<b>Algorithm</b>
<br />
<br />
Start holds the address of Ist node
<ol>
<li>Set node = start</li>
<li>Repeat step 3 and 4 while node != NULL</li>
<li>Process into [node] </li>
<li>Set node = next [node]</li>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Exit
</ol>		
		<br />
		
        </Typography>
		
	
		
			
			
			
			
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Infixtoprefix" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Postfixexpression" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Linkedlist;