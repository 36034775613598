import React from "react-router-dom";
import { Typography, Paper, makeStyles, Grid, FormControl, Box } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Facebook, Instagram, Twitter,  LinkedIn } from '@material-ui/icons';
//import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	
	
	 mystyle: {
		
		textDecoration: 'none'
		
	},
	
    root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing.unit * 8,
   // padding: `${theme.spacing.unit * 6}px 0`,
   textAlign: 'center'
  },
  
  
  bottombar: {
	  
	  padding: `${theme.spacing.unit * 3}px 0`,
	  textAlign: 'center'
  },
  
  
  
  container: {
		 
		//padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 
  form: {
	  display: 'flex-column',
	 // display: 'inline-block',
	  paddingBottom: '5px',
	   textAlign: 'center',
	  flexDirection: 'row',
	  paddingTop: theme.spacing.unit * 2
	  
	 
  },
  
 
	
	
	
	
}));


const Footer = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	
	 <Box className={classes.container}>
	  <footer className={classes.footer}>
	     <Paper className={classes.root} elevation={1}>
	        <Grid container >
               <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                     Made with Love by Hacking Truth
                       </Typography>
		                  </Grid>
		
		 <Grid item xs={12}>
                               <Typography>
                                  <FormControl className={classes.form}>

        		                <a href="https://fb.com/hackingtruthin" target="_blank"> <Facebook color="HexToRGB" /></a>
		                       <a href="https://twitter.com/hackingtruthin" target="_blank" ><Twitter  color="HexToRGB" /> </a>
		                     <a href="https://www.linkedin.com/company/hackingtruthin" target="_blank" ><LinkedIn color="HexToRGB" /></a>
		                 <a href="https://instagram.com/h4cking_truth.in_" target="_blank"> <Instagram  color="HexToRGB"  /></a>
		               </FormControl>
                     </Typography>
					 </Grid>
	  	   </Grid>
         </Paper>
	   <Typography className={classes.bottombar}> © 2022 Hacking Truth Cyber Security Company. OPC. All Rights Reserved. <a href="https://www.hackingtruth.org/Privacypolicy" target="_blank" className={classes.mystyle}>Privacy Policy.</a></Typography>
     </footer>
   </Box>
	
	
	 </>	
	
   )
 }
 

 
export default Footer;