import { Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody,  } from "@material-ui/core";
import { categories } from "../../Categoriesconstant/Data";
import { NavLink, Link } from "react-router-dom";


const useStyles = makeStyles({
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '86%'
	},
	
	table:{
	 	 border: '1px solid rgba(224, 224, 244, 1)'
 		
    },
	
	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
	
})

const Categories = () => {
	const classes = useStyles();
	
	return (
	<>
	
	 <Link to="/Createview" className={classes.link}><Button variant="contained" className={classes.create}> Create Blog</Button></Link>
	 
	 
	 <Table className={classes.table}>
	   <TableHead>
	     <TableRow>
		 <TableCell>
		 
		 All Categories
		
		 </TableCell>
		 
		 </TableRow>
	   </TableHead>
	   
	   <TableBody>
	   
	 <TableRow>
				  
				  
				  <NavLink to="/Webdevelopmenthome"><TableCell>Web Development	  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Penetration Testing  </TableCell>

	              </TableRow>	  
				  
				  
				  <TableRow>
				  
				  
				  <NavLink to="/Softwareengineeringhome" ><TableCell>	Software Engineering  </TableCell></NavLink>

	              </TableRow>	  
				  
				    <TableRow>
				  
				  
				  <NavLink to="/Datastructurehome" ><TableCell>	Data Structure  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				  
				  
				    <TableRow>
				  
				  
				  <NavLink to="/DBMShome" ><TableCell>	DBMS  </TableCell></NavLink>

	              </TableRow>	  
				  
				    <TableRow>
				  
				  
				  <NavLink to="/Oshome" ><TableCell>	Operating System  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				
				    <TableRow>
				  
				  
				  <NavLink to="/Javahome" ><TableCell>Java  </TableCell></NavLink>

	              </TableRow>	  
				  
				  
				  
				  
				  
				     <TableRow>
				  
				  
				  <TableCell>	Python  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  
				  <TableCell>	Bash Scripting  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Networking  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Bug Bounty </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	CEH v11  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	OWASP TOP 10  </TableCell>

	              </TableRow>	  
				  
				  
				    <TableRow>
				  
				  
				  <TableCell>	Dark Web  </TableCell>

	              </TableRow>	  
				  
				  
		
		 
		 
		</TableBody>
	 </Table>
	
	
	        
		
   
	
	</>
   )
};

export default Categories;