import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/os/images/OShackingtruth.png";
import webimages from "../../../../../src/MyComponents/home/os/images/hackingtruthlts.png";
import webgraph from "../../../../../src/MyComponents/home/os/images/hackingtruthgraph.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Schedulinginos = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Operating System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>19 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Scheduling In OS</strong></Typography><br />
	 <Typography>
    <li>Scheduling is a method that is used to distribute valuable computing resources, usually processor time, bandwidth and memory, to the various processes, threads, data flows and applications that need them.</li>
    <li>Scheduling is done to balance the load on the system and ensure equal distribution of resources and give some prioritization according to set rules.</li>
    <li>This ensures that a computer system is able to serve all requests and achieve a certain quality of service. Scheduling is also known as process scheduling.</li>

     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Types of Scheduling</strong></Typography><br />
    <Typography>
       
    <ol>	   
	<li>LONG TERM SCHEDULING</li>
    <li>SHORT TERM SCHEDULING</li>
    <li>SCHEDULING CRETERIA</li>
	<li>Scheduling Criteria</li>
    <li>NON PREEMPTIVE SCHEDULING</li>
    <li>PREEMPTIVE SCHEDULING </li>
   
	</ol>
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography variant="h5" component="h6"><strong>1. Long Term Scheduling </strong></Typography><br />
    <Typography>
        
		
    <li>Long-term Scheduling Long term scheduling is performed when a new process is created.</li>
    <li>If the number of ready processes in the ready queue becomes very high, then there is a overhead on the operating system (i.e., processor).</li>
    <li>Once when admit a process or job, it becomes process and is added to the queue for the short-term scheduler.</li>
    
		<br />
		<br />
		
		<br />
		
		 <img src={webimages} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br />
		<br />
		
		
        </Typography>
		
		
		
	
	
	<br />
<Typography variant="h5" component="h6"><strong>2. Short Term Scheduling </strong></Typography><br />
    <Typography>
        
		
    <li>Short-term Scheduling Short-term scheduler is also called as dispatcher.</li>
    <li>Short-term scheduler is invoked whenever an event occurs, that may lead to the interruption of the current running process.</li>
    <li>For example clock interrupts, I/O interrupts, operating system calls, signals, etc.</li>
    <li>Short-term scheduler executes most frequently. It selects from among the processes that are ready to execute and allocates the CPU to one of them.</li>
    <li>It must select a new process for the CPU frequently. It must be very fast.</li>
   
		
		
		<br />
		<br />
		
		
	<br />	
	<br />
	<br />
	
		
		
		</Typography>
			
		
	
	<br />
<Typography variant="h5" component="h6"><strong>3. Scheduling Criteria  </strong></Typography><br />
    <Typography>
        
		
    <li>Scheduling Criteria Scheduling criteria is also called as scheduling methodology.</li>
    <li>Key to multiprogramming is scheduling. Different CPU scheduling algorithm have different properties.</li>
    
	
	<br />
	<br />
	<strong>The criteria used for comparing these algorithms include the following: </strong><br />
    
	
	<li>CPU Utilization</li>
	<li>Throughput</li>
	<li>Turn a round time</li>
	<li>Waiting time</li>
	<li>Response time</li>
	<li>Fairness </li>
   
		
		
		<br />
		<br />
		
		
		
		</Typography>
		
	
	<br />
<Typography variant="h5" component="h6"><strong>4. Non-pre-emptive Scheduling </strong></Typography><br />
    <Typography>
        
		
    <li>In non-preemptive mode, once if a process enters into running state, it continues to execute until it terminates or blocks itself to wait for Input/Output or by requesting some operating system service.
</li>
    
		
		
		<br />
		<br />
		
			
		</Typography>
		
	
	<br />
<Typography variant="h5" component="h6"><strong>5. Preemptive Scheduling </strong></Typography><br />
    <Typography>
        
		
    <li>In preemptive mode, currently running process may be interrupted and moved to the ready State by the operating system.
</li>
    <li>When a new process arrives or when an interrupt occurs, preemptive policies may incur greater overhead than non- preemptive version but preemptive version may provide better service. 
 </li>
    

	</Typography>
		
		<br />
		<br />
		
		
		<Typography><strong>Short-Tem Scheduling Criteria User-oriented:</strong></Typography><br />
    <Typography>
       
	<br />
	<br />
		
		
		
    <li>Relate to the behavior of the system as perceived by the individual user or process </li><br />
    <li><strong>Response Time:</strong> Elapsed time from the submission of a request to the beginning of response.</li><br />
    <li><strong>Turnaround Time:</strong>  Elapsed time from the submission of a process to its completion.</li><br />
    <li><strong>System-oriented:</strong>  focused on effective and efficient utilization of the processor.</li><br />
    <li><strong>Processor utilization:</strong>  keep the CPU as busy as possible. Fairness.</li><br />
    <li><strong>Throughput:</strong>   number of process completed per unit time.</li><br />
    <li><strong> Waiting time:</strong>amount of time a process has been waiting in the ready queue 14</li><br />
    
	
		
		
	<br />
	<br />
		
		
		</Typography>
		
	
	<br />
<Typography variant="h5" component="h6"><strong>6. First-come First-served </strong></Typography><br />
    <Typography>
        
		
    <li>First-come First-served Scheduling (FCFS) First-come First-served Scheduling follow first in first out method.</li>
	<li>As each process becomes ready, it joins the ready queue. When the current running process ceases to execute, the oldest process in the Ready queue is selected for running.
</li>
    <li>That is first entered process among the available processes in the ready queue.The average waiting time for FCFS is often quite long.
 </li>
	
	<br />
	<br />
    <strong>TURNAROUND TIME = WAITING TIME + SERVICE TIME    </strong>
	
		
		<br />
		<br />
		
		
		<Typography><strong>Advantages</strong></Typography><br />
    <Typography>
       
	
    <li>Better for long processes.</li><br />
    <li>Simple method (i.e., minimum overhead on processor).</li><br />
    <li>No starvation.</li><br />
  

	<br />	
	<br />
	
	<Typography><strong>Disadvantages</strong></Typography><br />
    <Typography>
       
	
	 
<li>Convoy effect occurs. Even very small process should wait for its turn to come to utilize the CPU. Short process behind long process results in lower CPU utilization.
</li><br />
    <li>Through put is not emphasized. </li><br />
  

		 <br />
		 <br />
		 <br />
		 
		 
		 
		 
<Typography class=" text-center" variant="h3" component="h4">
	 Running Example to discuss various scheduling policies
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">Process</th>
      <th scope="col">Arival Time</th>
      <th scope="col">Service Brust/Time/Until Time</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">P1</th>
      <td>0</td>
      <td>3</td>
     
    </tr>
	<tr>
      <th scope="row">P2</th>
      <td>2</td>
      <td>3</td>
   
    </tr>
    <tr>
      <th scope="row">P3</th>
      <td>3</td>
      <td>5</td>
  
    </tr>
    <tr>
      <th scope="row">P4</th>
      <td>4</td>
	  <td>2</td>
      
    </tr>
	
	 <tr>
      <th scope="row">P5</th>
      <td>8</td>
	  <td>3</td>
      
    </tr>
	
	
	
	
	
  </tbody>
</table>


	<br />	
	<br />
	 <br />
		 
	
		 
		 
<Typography class=" text-center" variant="h3" component="h4">
	 Maping
	  </Typography>
	<table class="table table-dark table-hover">
	 
  <thead>
    <tr>
      <th scope="col">P.Time</th>
      <th scope="col">PID</th>
      <th scope="col">MIA</th>
      <th scope="col">W.T</th>
      <th scope="col">P.Used</th>
      <th scope="col">S.P</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">0</th>
      <td>_</td>
      <td>_</td>
      <td>_</td>
      <td>_</td>
	  <td>_</td>
     
    </tr>
	<tr>
      <th scope="row">3</th>
      <td>P1</td>
      <td>3</td>
	  <td>1.00</td>
      <td>P1</td>
	  <td>P1</td>
      
   
    </tr>
    <tr>
      <th scope="row">6</th>
      <td>P2</td>
      <td>4</td>
	  <td>1.33</td>
      <td>P2, P3</td>
	  <td>P2</td>
     
  
    </tr>
    <tr>
      <th scope="row">11</th>
      <td>P3</td>
	  <td>8</td>
	  <td>1.60</td>
      <td>P3, P4</td>
	  <td>P3</td>
      
      
    </tr>
	
	 <tr>
      <th scope="row">13</th>
      <td>P4</td>
	  <td>9</td>
	  <td>4.50</td>
      <td>P4, P5</td>
	  <td>P4</td>
     
      
    </tr>
	
	
	<tr>
      <th scope="row">16</th>
      <td>P5</td>
	  <td>8</td>
	  <td>2.67</td>
      <td>P5, P5</td>
	  <td>P5</td>
      
      
    </tr>
	
	
	
	
	
  </tbody>
</table>


	<br />	
	<br />
	<Typography><li><strong>P.Time = </strong>Process Time</li></Typography>
	<Typography><li><strong>PID = </strong>Process ID</li></Typography>
	<Typography><li><strong>MIA = </strong>Missing in Action</li></Typography>
	<Typography><li><strong>W.T = </strong>Waiting Time</li></Typography>
	<Typography><li><strong>P.U = </strong>Process Used</li></Typography>
	<Typography><li><strong>S.P = </strong>Schedular Process</li></Typography>
	<br />
	<br />
	
    <strong>Calculation  </strong>
	<br />
	<ul>
	<li>P.T + MIA = P.T</li>
		<ul><li>P1 + P2 (Brust Time) = Next P. Time</li></ul>
		<ul><li>3 + 3 = 6</li></ul>
		<ul><li>P. Time - P2 (Arival Time) = MIA</li></ul>
		<ul><li>6 - 2 = 4</li></ul>
		
		
	<li>Next P. Time + P3 (Brust Time) = Next P.Time</li>	
		<ul><li>6 + 5 = 11</li></ul>
		<ul><li>Next P. Time - P3 (Arival Time) = MIA</li></ul>
		<ul><li>11 - 3 = 8</li></ul>
			
	<li>Next P. Time + P4 (Brust Time) = Next P.Time</li>	
		<ul><li>11 + 2 = 13</li></ul>
		<ul><li>Next P. Time - P4 (Arival Time) = MIA</li></ul>
		<ul><li>13 - 4 = 9</li></ul>
		
				
	<li>Next P. Time + P5 (Brust Time) = Next P.Time</li>	
		<ul><li>13 + 3 = 16</li></ul>
		<ul><li>Next P. Time - P5 (Arival Time) = MIA</li></ul>
		<ul><li>16 - 8 = 8</li></ul>
		
		
		
    </ul>
  <br />
  <br />
      <Typography><strong> NOTE : </strong> Something is missing - please wait we will update you very soon.</Typography>	
		<br />
		
		<br />
		
		 <img src={webgraph} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
		
		<br />
		 
		 
		 
		 
	 </Typography>

       

	 </Typography>

       

	 </Typography>

       

<br />
		<br />
		
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Redundantarraysofindependent" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Schedulinginos;