import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/Softwareengineering/images/softwareengineering-hackingtruth.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Softwarerequirementspecification = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Software Engineering</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>15 Sep 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>SRS Document</strong></Typography><br />
	 <Typography>
    SRS is stand for Software Requirement Specification
	<br />
	<br />
	
	SRS document contains all the functional requirement of the software. It also contains all the constructs of the sfotrware.
	This document is used by stack holder (customer) to ensure that all the requirement other are fixed
	by the development team and there is not any conflicts in the requirement development in used this document for development
	of the project based on customer requirements
	
<br />
<br />
 This document is also contains how the end user is used the software and sued to interact the softwarfe and all the software system
 in conflict with existing software and networks.
<br />

<br />
<br />
	
	</Typography>
 


	<br />
	<br />
	
	
<Typography><strong>Characteristics of exceptional SRS</strong></Typography><br />
<Typography>
    There are certain things developers should strive to achieve in their SRS document to make it primed for a smooth development project. These can be broken up into three categories:
		<br />
		<br />
		<ul>
		<li> Meaningful qualities</li>
    <li> Characteristics that meet goals</li>
   <li>  Identifiable requirement smells</li>
		
		</ul>		
		<br />
		<br />
		<br />
<b>Meaningful Qualities  </b>
<br />
<br />
The meaningful qualities of SRS are those that are purposeful in helping the developer
understand the full scope of the project.
		<br />
		<br />
		<br />
<b>Characteristics that Meet Goals </b>
<br />
<br />
Each development project should have a pre-established set of goals. These characteristics are
used to ensure goals are met and the project stays on the right track.
		<br />
		<br />
		<br />
<b>Identifiable Requirement Smells </b>
<br />
<br />
Similar to code smells, requirements smells are indicators that a requirement could be problematic.
Developers should pay attention to these characteristics and make changes as necessary.		
	

</Typography>
			
		
	
	<br />
	<br />
	<br />
	

	
	<Typography><strong>SRS document contains for its section </strong></Typography><br />
    <Typography>
  
<ol>
<li>Introduction</li>
<li>Detail description project and requirement</li>
<li>Specific Requirement</li>

</ol>
<br />
<br />
This is the first section of SRS document. This section describe the overview of the software.
Introduction section divided into four parts.

<br />	
<br />	
1) <b>Purpose </b>- In this section purpose of entire projects and its functionality are specific explicitly
mentions.
<br />
<br />
2) <b>Scope </b>- In this section after analyzing the requirement scope of the project and this 
scope are mention specific so that we can move into the next steps of software development.
<br />
<br />
3) <b>Design and Convention </b>- In this section all the naming convention  related with function, interface, class.
so that developer can be easily identified the entities involved in the development.
<br />
<br />
4) <b>Overview </b>- This section Contains of overview of entire project.
<br />

		</Typography>
			
	
	
	<br />
	<br />
	
	
	<Typography><strong>Default Description </strong></Typography><br />
    <Typography>
       
	   This section contains product behaviour in this part of the software is interact with system and network.
	   If there is any confliction with other software or network then this is specifications confliction.
		<br />	     	
		<br />
		
		</Typography>
			
	<br />
	<br />
	
	
	<Typography><strong>Function</strong></Typography><br />
    <Typography>
     This section which gives all the related with function and this section also contains the function
	 with its priority. So that developoer can constraint on higher priority first function first.
	  
	  
		<br />
		<br />
		<br />
		
		</Typography>
			
		
	
	<Typography><strong>User profile</strong></Typography><br />
    <Typography>
	
This section describe about end users profile who is going to use this software. Based on user profile
user interface of should be user friendly.
	  <br />
	  
		<br />
		
		</Typography>
			
		
	<Typography><strong>Design Constraint </strong></Typography><br />
    <Typography>
	
	This section describe about design constraint about the software system.
	We can for designing of user interface and also the information about of the input field of the user interface.
	  <br />
	  
		<br />
		
		</Typography>
			
		
	<Typography><strong>Specific Requirement</strong></Typography><br />
    <Typography>
	
	In this phase contains requirement include, intermediate work product and output of the entire project. 
Developers can use this section to<br /><br />
<b>1)</b> Design and IT project <br />
<b>2)</b> Create and IT Project <br />
 <b>3)</b> Test and IT project<br />
 <b>4)</b> Implement and IT project and deliver, IT project<br />
	  
		<br />
		
		</Typography>
	

			
		<br />
		<br />
		<br />
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Casetool" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Softwarerequirementspecification;