//import React from "react";
import React from "react-router-dom";
import { Box } from "@material-ui/core";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";


//import React from "react";
import Navbar from "./MyComponents/Navbar";
import Home from  "./MyComponents/home/Home";


import Articledetails from "./MyComponents/Article/Articledetails";
import Createview from "./MyComponents/Article/Createview";
import Updateview from "./MyComponents/Article/Updateview";

import Htmlintroarticle from "./MyComponents/home/webdevelopment/Webarticle/Htmlintroarticle";
import Htmlbasicarticle from "./MyComponents/home/webdevelopment/Webarticle/Htmlbasicarticle";
import Htmlcardbox from "./MyComponents/home/webdevelopment/Webarticle/Htmlcardbox";
import Htmlsidenavbar from "./MyComponents/home/webdevelopment/Webarticle/Htmlsidenavbar";
import Webcreateview from "./MyComponents/home/webdevelopment/Webarticle/Webcreateview";
import Webupdateview from "./MyComponents/home/webdevelopment/Webarticle/Webupdateview";
import SEOtips from "./MyComponents/home/webdevelopment/Webarticle/SEOtips";


import Jquerybasic from "./MyComponents/home/webdevelopment/Jqueryarticle/Jquerybasic";
import Jquerysyntax from "./MyComponents/home/webdevelopment/Jqueryarticle/Jquerysyntax";
import Jqueryeventlist from "./MyComponents/home/webdevelopment/Jqueryarticle/Jqueryeventlist";
import Jqueryclickevent from "./MyComponents/home/webdevelopment/Jqueryarticle/Jqueryclickevent";
import Jqueryhoverevent from "./MyComponents/home/webdevelopment/Jqueryarticle/Jqueryhoverevent";
import Jqueryhideevent from "./MyComponents/home/webdevelopment/Jqueryarticle/Jqueryhideevent";
import Jqueryblur from "./MyComponents/home/webdevelopment/Jqueryarticle/Jqueryblur";

import Webdevelopmenthome from "./MyComponents/home/webdevelopment/Webdevelopmenthome";
import Htmlhome from "./MyComponents/home/webdevelopment/Htmlhome";

import Jqueryhome from "./MyComponents/home/webdevelopment/Jqueryhome";


import Relationalalgebraarticle from "./MyComponents/home/dbms/Webarticle/Relationalalgebraarticle";
import Entitiesindbms from "./MyComponents/home/dbms/Webarticle/Entitiesindbms";
import Primarykeyindbms from "./MyComponents/home/dbms/Webarticle/Primarykeyindbms";
import Foreignkeyindbms from "./MyComponents/home/dbms/Webarticle/Foreignkeyindbms";

import Dbmshome from "./MyComponents/home/dbms/Dbmshome";


import Schedulinginos from "./MyComponents/home/os/Webarticle/Schedulinginos";
import Redundantarraysofindependent from "./MyComponents/home/os/Webarticle/Redundantarraysofindependent";
import Oshome from "./MyComponents/home/os/Oshome";



import Servletsinjava from "./MyComponents/home/java/Webarticle/Servletsinjava";
import CGIinjava from "./MyComponents/home/java/Webarticle/CGIinjava";
import Lifecycleofaservlets from "./MyComponents/home/java/Webarticle/Lifecycleofaservlets";
import Genericservletsandhttpservlet from "./MyComponents/home/java/Webarticle/Genericservletsandhttpservlet";
import Helloservlet from "./MyComponents/home/java/Webarticle/Helloservlet";

import Javahome from "./MyComponents/home/java/Javahome";


import Softwareengineeringhome from "./MyComponents/home/Softwareengineering/Softwareengineeringhome";
import Evolvingroleofsoftware from "./MyComponents/home/Softwareengineering/Webarticle/Evolvingroleofsoftware";
import Layeredtechnology from "./MyComponents/home/Softwareengineering/Webarticle/Layeredtechnology";
import Softwareprocessmodel from "./MyComponents/home/Softwareengineering/Webarticle/Softwareprocessmodel";
import Requirementnegotiation from "./MyComponents/home/Softwareengineering/Webarticle/Requirementnegotiation";
import Sdlc from "./MyComponents/home/Softwareengineering/Webarticle/Sdlc";
import Umldiagram from "./MyComponents/home/Softwareengineering/Webarticle/Umldiagram";
import Functionalandnonfunctional from "./MyComponents/home/Softwareengineering/Webarticle/Functionalandnonfunctional";
import Cohesionandcoupling from "./MyComponents/home/Softwareengineering/Webarticle/Cohesionandcoupling";
import Casetool from "./MyComponents/home/Softwareengineering/Webarticle/Casetool";
import Blackboxtesting from "./MyComponents/home/Softwareengineering/Webarticle/Blackboxtesting";
import Whiteboxtesting from "./MyComponents/home/Softwareengineering/Webarticle/Whiteboxtesting";
import Softwarerequirementspecification from "./MyComponents/home/Softwareengineering/Webarticle/Softwarerequirementspecification";
import Optimizationtechniques from "./MyComponents/home/Softwareengineering/Webarticle/Optimizationtechniques";


import Datastructurehome from "./MyComponents/home/Datastructure/Datastructurehome";
import Datastructure from "./MyComponents/home/Datastructure/Webarticle/Datastructure";
import Arrayindatastructure from "./MyComponents/home/Datastructure/Webarticle/Arrayindatastructure";
import Stackdatastructure from "./MyComponents/home/Datastructure/Webarticle/Stackdatastructure";
import Applicationofstack from "./MyComponents/home/Datastructure/Webarticle/Applicationofstack";
import Infixtopostfix from "./MyComponents/home/Datastructure/Webarticle/Infixtopostfix";
import Postfixexpression from "./MyComponents/home/Datastructure/Webarticle/Postfixexpression";
import Infixtoprefix from "./MyComponents/home/Datastructure/Webarticle/Infixtoprefix";
import Prefixexpression from "./MyComponents/home/Datastructure/Webarticle/Prefixexpression";
import Recursion from "./MyComponents/home/Datastructure/Webarticle/Recursion";
import Lineardatastructure from "./MyComponents/home/Datastructure/Webarticle/Lineardatastructure";
import Sorting from "./MyComponents/home/Datastructure/Webarticle/Sorting";
import Linkedlist from "./MyComponents/home/Datastructure/Webarticle/Linkedlist";
import Binarysearchtree from "./MyComponents/home/Datastructure/Webarticle/Binarysearchtree";


import Footer from "./MyComponents/Footer";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



function App() {
  return (
  
  
  <>
  <Router>
	<Navbar />
     <Box style={{marginTop: 64}}>
	   <Switch>
	   <Route exact path="/" component={Home} />
	   
	   <Route exact path="/Articledetails" component={Articledetails} />
	   <Route exact path="/Createview" component={Createview} />
	   <Route exact path="/Updateview" component={Updateview} />
	   
	   
	   <Route exact path="/Htmlintroarticle" component={Htmlintroarticle} />
	   <Route exact path="/Htmlbasicarticle" component={Htmlbasicarticle} />
	   <Route exact path="/Htmlcardbox" component={Htmlcardbox} />
	   <Route exact path="/Htmlsidenavbar" component={Htmlsidenavbar} />
	   <Route exact path="/SEOtips" component={SEOtips} />
	   <Route exact path="/Jquerybasic" component={Jquerybasic} />
	   <Route exact path="/Jquerysyntax" component={Jquerysyntax} />
	   <Route exact path="/Jqueryeventlist" component={Jqueryeventlist} />
	   <Route exact path="/Jqueryclickevent" component={Jqueryclickevent} />
	   <Route exact path="/Jqueryhoverevent" component={Jqueryhoverevent} />
	   <Route exact path="/Jqueryhideevent" component={Jqueryhideevent} />
	   <Route exact path="/Jqueryblur" component={Jqueryblur} />
	   
	   <Route exact path="/Relationalalgebraarticle" component={Relationalalgebraarticle} />
	   <Route exact path="/Entitiesindbms" component={Entitiesindbms} />
	   <Route exact path="/Primarykeyindbms" component={Primarykeyindbms} />
	   <Route exact path="/Foreignkeyindbms" component={Foreignkeyindbms} />
	   
	   
	   <Route exact path="/Schedulinginos" component={Schedulinginos} />
	   <Route exact path="/Redundantarraysofindependent" component={Redundantarraysofindependent} />
	   
	   
	   <Route exact path="/Servletsinjava" component={Servletsinjava} />
	   <Route exact path="/CGIinjava" component={CGIinjava} />
	   <Route exact path="/Lifecycleofaservlets" component={Lifecycleofaservlets} />
	   <Route exact path="/Genericservletsandhttpservlet" component={Genericservletsandhttpservlet} />
	   <Route exact path="/Helloservlet" component={Helloservlet} />
	   
	   <Route exact path="/Javahome" component={Javahome} />
	   
	   <Route exact path="/Webcreateview" component={Webcreateview} />
	   <Route exact path="/Webupdateview" component={Webupdateview} />
	   
	   
	   
	   <Route exact path="/Webdevelopmenthome" component={Webdevelopmenthome} />
	   <Route exact path="/Htmlhome" component={Htmlhome} />
	   <Route exact path="/Jqueryhome" component={Jqueryhome} />
	   
	   <Route exact path="/Softwareengineeringhome" component={Softwareengineeringhome} />
	    <Route exact path="/Evolvingroleofsoftware" component={Evolvingroleofsoftware} />
	   <Route exact path="/Layeredtechnology" component={Layeredtechnology} />
	   <Route exact path="/Softwareprocessmodel" component={Softwareprocessmodel} />
	   <Route exact path="/Requirementnegotiation" component={Requirementnegotiation} />
	   <Route exact path="/Sdlc" component={Sdlc} />
	   <Route exact path="/Umldiagram" component={Umldiagram} />
	   <Route exact path="/Functionalandnonfunctional" component={Functionalandnonfunctional} />
	   <Route exact path="/Cohesionandcoupling" component={Cohesionandcoupling} />
	   <Route exact path="/Casetool" component={Casetool} />
	   <Route exact path="/Blackboxtesting" component={Blackboxtesting} />
	   <Route exact path="/Whiteboxtesting" component={Whiteboxtesting} />
	   <Route exact path="/Softwarerequirementspecification" component={Softwarerequirementspecification} />
	   <Route exact path="/Optimizationtechniques" component={Optimizationtechniques} />
	   
	   
	   <Route exact path="/Datastructurehome" component={Datastructurehome} />
	   <Route exact path="/Datastructure" component={Datastructure} />
	   <Route exact path="/Arrayindatastructure" component={Arrayindatastructure} />
	   <Route exact path="/Stackdatastructure" component={Stackdatastructure} />
	   <Route exact path="/Applicationofstack" component={Applicationofstack} />
	   <Route exact path="/Infixtopostfix" component={Infixtopostfix} />
	   <Route exact path="/Postfixexpression" component={Postfixexpression} />
	   <Route exact path="/Infixtoprefix" component={Infixtoprefix} />
	   <Route exact path="/Prefixexpression" component={Prefixexpression} />
	   <Route exact path="/Recursion" component={Recursion} />
	   <Route exact path="/Lineardatastructure" component={Lineardatastructure} />
	   <Route exact path="/Sorting" component={Sorting} />
	   <Route exact path="/Linkedlist" component={Linkedlist} />
	   <Route exact path="/Binarysearchtree" component={Binarysearchtree} />
	   
	   
	   
	   <Route exact path="/Dbmshome" component={Dbmshome} />
	   	   
	   <Route exact path="/Oshome" component={Oshome} />
	   
	   
	   
	   
	   
	   </Switch>
	 </Box>
     	
	 <Footer />
	   
	   </Router>
   </>   
  );
}

export default App;
