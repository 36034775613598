import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/primitive-data-structure.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/non-primitive-data-structure.png";


const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Datastructure = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>21 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>About Data Structure</strong></Typography><br />
	 <Typography>
    <li>A collection of data elements whose organization is characterised but accessing function that are used to store and retrieve
individual data elements.</li>
    <br />
	OR
    <br />
	  <br />
<li>

The logical or mathematical model of a particular organization of data is called data structure.
</li>
<br />


OR
<br />
<br />
<li>

Data structure is a a way of collecting and organising data in such a way that we can perform operation on these
on these data in a effective way. Data structure is about rendering data elements in terms of some
relationship  for better organization and storage .
</li>

<br />
<br />
For eg - we have some which data has players name "atul" and age 22.
<br /><br />
<br />
Here atul is a string data type and 23 is a of integet data type.
<br />
<br />
In simple language, data structure are structure programmed to store ordered data . so that various 
operations can be performed on it easiy. It represent on knowledge of data to be organized in memory.
It should be designed and implemented in such a way that it reduces the complexity and increase the 
efficiency.

<br />
<br />


	</Typography>
	
	<br />
	
	<Typography><strong>Classification of data structure -</strong></Typography><br />
    <Typography>
       
    <ol>	   
	
The data structure are classified in the following categories -
<br />
<br />
<li> Primitive data structure </li>
<li> Non-primitive data structure </li>
    
   
	</ol>
		<br />
		<br />
		<br />
		
		
		</Typography>
			
		
	<Typography><strong>Primitive Data Structure -</strong></Typography><br />
    <Typography>
       
    Primitive data structure are directly operated upon machine by machine level
	instructions integers.
	    <br />
		<br />
		int *ptr;<br />
		int info;
         <br />		
         <br />		
		info = *ptr;
		<br />
		<br />
		Where pointer contains the address 0xff02 and the infromation stored at the location is 11.
		After this assignment we will get 11 in info.
		<br />
		<br />
		<br />
		
		</Typography>
			
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
		<Typography><strong>Operation on primitive data structure</strong></Typography><br />
    <Typography>
       
    1) Creation operation - This operation is used to create a storage representation for a particular
	data structure. This operation is normatlly performed  with the help of  a declaration statement  available
	 in the programming language.
	    <br />
		<br />
		For eg - Int n = 45;
         <br />		
		 
         <br />	
	      cause memory space to be created for n -
		<br />
		<br />
		n ------------------> Name 
		<br />
		45 ------------------> Value
		<br />
		0xff02 ------------------> Address
		<br />
		<br />
		Memory space refered by name, n where integer value can be stored.
		<br />
		<br />
		<br />
		<br />
		
		2) Selection operation - This is most frequently used operation and is used to access data 
		within a data structure.
		<br />
		<br />
		For eg - scanf("%d", &a);
		<br />
		<br />
		In case of files the access can be sequential or random depending on the nature of files. This
		operation is normally	performed using the name of the structure.
		<br />
		<br />
		<br />
		<br />
		
		3) Update operation - This operation is used to change for modify the data in a structure. 
		An assignment operator is a good example of an update operator.
		<br />
		<br />
		Modifies the value of y to store the new value 5 in it.
		<br />
		<br />
		<br />
		<br />
		
		4) Destroy operation - This operation is used to destroy or disassociate a partucular data
		structure from its storage representaction.
		<br />
		<br />
		In some language this operation is not supported or it is automatically perfomed. In c one can 
		destroy data structure by using the function called free(). This aids in efficient use of memory.
		<br />
		<br />
		<br />
		<br />
		
		</Typography>
		
				
	<Typography><strong>Non-Primitive Data Structure -</strong></Typography><br />
    <Typography>
       
    Non-Primitive data structure are those structure which are not readily available in a programming
	language ie. they cannot be directly operated upon by programming language (instruction). The storage
	representation and the possible operations for these types of structure are not pre-defined and the
	user has to defined them
	    <br />
		<br />
          OR
		  <br />		
         <br />
      The data structure that are composed of primitive data structure.
	  <br />
	  <br />
	  <br />
	  	
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
	  <br />
	  <br />
	  <br />
	  Non-primitive data structure are further classified into two types.
	  
	  
	    <br />
		<br />
		<ol>
		<li> Linear Data Structure</li>
		<li> Non-Linear Data Structure</li>
		</ol><br />
		<br />
		<br />
		
		</Typography>
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Arrayindatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Stackdatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Datastructure;