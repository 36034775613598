import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/heading-hackingtruth1.png";
import hackingtruthflexbox from "../../../../../src/MyComponents/home/webdevelopment/images/hackingtruthflexbox.png";



const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Htmlcardbox = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > HyperText Markup Language</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>HTML - Card Box</strong></Typography><br />
	 <Typography>
    


If you are new to html or if you want to be above average in webpage design you should concentrate solely on learning the structure of an HTML page, tags, and their functionalities etc.
<br />
<br />
HTML page is all about tags. Tags are similar to instructions or codes. Web browsers know their meaning and interpret them to render text images and other elements on the screen properly.
<br />
<br />
Today we will make a <strong>Card Box</strong> through <strong>Flexbox</strong>, in which we will only take the help of <strong>HTML</strong> and <strong>CSS</strong> and will try to make it completely responsive.     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>FlexBox</strong></Typography><br />
    <Typography>
       


The CSS3 Flexible Box, or flexbox, is a layout mode providing for the arrangement of elements on a page such that the elements behave predictably when the page layout must accommodate different screen sizes and different display devices. Mozilla Developer Network <br />
<br />


</Typography>

<br />

	
	<br />
	
	<Typography><strong>What is FlexBox</strong></Typography><br />
    <Typography>
       

 Designed to provide a better way to lay out, align, and distribute space among items in a container - even when dynamic. Flexbox is a CSS layout mode designed to ensure that elements behave predictably in different screen sizes and devices. … it creates Intelligent boxes. Easily Defined in CSS and markup (incl. media queries). Directionally agnostic. (block = vertically-based & inline = horizontally-based) Best used for smaller modules over broader layout <br />


</Typography>

<br />

<br />









<Typography><strong>FlexBox Syntax</strong></Typography><br />
    <Typography>
      
A Flexible Layout must have a parent element with the display property set to flex.

Direct child elements(s) of the flexible container automatically becomes flexible items.
<br />
<br />
Consider the following code :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets write the Syntax</strong></Typography><br />
    <Typography>
        
		
    <pre>
		 

		&lt;!DOCTYPE html&gt; <br />
		&lt;html&gt; <br />
		&lt;head&gt;<br />
		&lt;title>Hacking Truth Flex Box&lt;/title&gt;<br />
		<br />
		&lt;style&gt;   <br />
		.flex-container &#123;   <br />
           display: flex;        <br />
           background-color: DodgerBlue;  <br />
&#125;

<br />
<br />
.flex-container &gt; div &#123;   <br />
  background-color: #f1f1f1;        <br />
  margin: 10px;                      <br />
  padding: 20px;                       <br />
  font-size: 30px;                        <br />
&#125;
<br />

      &lt;/style&gt;      <br /> <br />
		&lt;/head&gt;<br />
		
		&lt;body&gt;<br /><br />
		
		&lt;div class="flex-container"&gt; <br />
  &lt;div&gt;1&lt;/div&gt;  <br />
  &lt;div&gt;2&lt;/div&gt;  <br />
  &lt;div&gt;3&lt;/div&gt;   <br />
&lt;/div&gt;                   <br /><br />


		&lt;/body&gt;<br />
		&lt;/html&gt;<br />
		
		
		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
<br />
<br />

<Typography><strong>FlexBox HTML Code</strong></Typography><br />
    <Typography>
      
First of all we will create a cardbox.html file 
<br />
Consider the following code :
		
		
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		&lt;!DOCTYPE html&gt;  <br />
&lt;html&gt;           <br />  
&lt;head&gt;          <br />  
&lt;title&gt;Hacking Truth Card Box&lt;/title&gt;          <br />  
&lt;link rel="stylesheet" type="text/css" href="cardbox.css" /&gt;          <br />  
&lt;/head&gt;          <br />  
&lt;body&gt;          <br />  <br />  <br />  


&lt;div class="container-flex"&gt;          <br />  

&lt;div class="first-hackingtruth-container demo"&gt;          <br />  

&lt;img class="HackingTruth-card-img img-responsive" src="sem.png" alt="i am here"&gt;           <br />  
&lt;div class="card-content"&gt;           <br />  
   &lt;h1 class="card-header"&gt;Kumar Atul Jaiswal (Hacking Truth) &lt;/h1&gt;           <br />  
   &lt;p class="card-para"&gt;My Self Kumar Atul Jaiswal Urf HackerboY.           <br />  
   I am self taught WHH, programmer web dev, web penetration 
   tester by profession but I am also a Security analyst & 
   computer science student from Ranchi University. Kumar Atul Jaiswal is a name 
   among millions who struggled failed and surged ahead in
   search of how to become a Hacker ( passionate about Hacking 
   just like profession an entrepreneur ), just like any middle 
   class guy, he too had a bunch of unclear dreams and a blurred
   version of his goals in life.&lt;/p&gt;           <br />  <br />  
   &lt;button class="btn-primary"&gt;&lt;a href="https://www.hackingtruth.in/" target="_blank"&gt;Click Here&lt;/a&gt;&lt;/button&gt;          <br />  <br />  
			&lt;/div&gt;	          <br />  
			&lt;div class="capclasshackingtruth"&gt;          <br />  
			&lt;p&gt;Made with Love 💝 by&lt;a href="https://hackingtruth.org" target="_blank"&gt; Hacking Truth&lt;/a&gt;&lt;/p&gt;          <br />  
			
		
&lt;/div&gt;           <br />  
&lt;/div&gt;          <br />  <br />  <br />  

&lt;div class="first-hackingtruth-container demo"&gt;          <br />  

&lt;img class="HackingTruth-card-img img-responsive" src="sem.png" alt="i am here"&gt;          <br />  
&lt;div class="card-content"&gt;          <br />  
   &lt;h1 class="card-header"&gt;Kumar Atul Jaiswal (Hacking Truth)&lt;/h1&gt;          <br />  
   &lt;p class="card-para"&gt;My Self Kumar Atul Jaiswal Urf HackerboY.          <br />  
   I am self taught WHH, programmer web dev, web penetration 
   tester by profession but I am also a Security analyst & 
   computer science student from Ranchi University. Kumar Atul Jaiswal is a name 
   among millions who struggled failed and surged ahead in
   search of how to become a Hacker ( passionate about Hacking 
   just like profession an entrepreneur ), just like any middle 
   class guy, he too had a bunch of unclear dreams and a blurred
   version of his goals in life.&lt;/p&gt;
   <br />  <br />  
   &lt;button class="btn-primary"&gt;&lt;a href="https://www.hackingtruth.in/" target="_blank"&gt;Click Here&lt;/a&gt;&lt;/button&gt;          <br />  <br />  
			&lt;/div&gt;	          <br />  
			&lt;div class="capclasshackingtruth"&gt;          <br />  
			&lt;p&gt;Made with Love 💝 by &lt;a href="https://iam.kumaratuljaiswal.in" target="_blank"&gt; Hacking Truth&lt;/a&gt;&lt;/p&gt;          <br />  

	
&lt;/div&gt;          <br />  
&lt;/div&gt;          <br />  <br />  <br />  


&lt;div class="first-hackingtruth-container demo"&gt;     <br />

&lt;img class="HackingTruth-card-img img-responsive" src="sem.png" alt="i am here"&gt;      <br />
&lt;div class="card-content"&gt;     <br />
   &lt;h1 class="card-header"&gt;Kumar Atul Jaiswal (Hacking Truth)&lt;/h1&gt;     <br />
   &lt;p class="card-para"&gt;My Self Kumar Atul Jaiswal Urf HackerboY.
   I am self taught WHH, programmer web dev, web penetration 
   tester by profession but I am also a Security analyst & 
   computer science student from Ranchi University. Kumar Atul Jaiswal is a name 
   among millions who struggled failed and surged ahead in
   search of how to become a Hacker ( passionate about Hacking 
   just like profession an entrepreneur ), just like any middle 
   class guy, he too had a bunch of unclear dreams and a blurred
   version of his goals in life.&lt;/p&gt;
        <br />     <br />
   &lt;button class="btn-primary"&gt;&lt;a href="https://www.hackingtruth.in/" target="_blank"&gt;Click Here&lt;/a&gt;&lt;/button&gt;     <br />     <br />
			&lt;/div&gt;	     <br />
			&lt;div class="capclasshackingtruth"&gt;     <br />
			&lt;p&gt;Made with Love 💝 by &lt;a href="https://hackingtruth.org" target="_blank"&gt; Hacking Truth&lt;/a&gt;&lt;/p&gt;     <br />

	  
&lt;/div&gt;<br />  
&lt;/div&gt;<br />  
<br />  <br />  

&lt;/body&gt;          <br />  
&lt;/html&gt;          <br />  
</pre>
		
		<br />
		<br />
		
		
        </Typography>
		
		<br />	
		<Typography><strong>FlexBox CSS Code</strong></Typography><br />
    <Typography>
		

then we will create another file with the name is cardbox and the extension is .css (cardbox.css)
<br />
<br />

		</Typography>
		

		<Typography><strong>Lets write the code</strong></Typography><br />
    <Typography>
        
		
    <pre>
		
		* &#123;           <br />
	margin:0;          <br />
	padding: 0;          <br />
&#125;

          <br />          <br />

body &#123;          <br />
	background-color: gray;          <br />
	color:white;          <br />
&#125;
          <br />          <br />

a &#123;          <br />
	text-decoration: none;           <br />
	
&#125;
          <br />          <br />
.container-flex &#123;          <br />
	display: flex;          <br />
	color: black;          <br />
&#125;

          <br />          <br />

.demo &#123;          <br />
	
	border: 2px solid green;            <br />
	
&#125;

          <br />          <br />


.first-hackingtruth-container &#123;          <br />          <br />
	
	width: 100%;          <br />
	height: 100%;          <br />
	background-color: white;          <br />
    display: inline-block;          <br />
	margin-top: 20px;          <br />
	margin: 10px;          <br />
	border-top-left-radius: 25px;          <br />
	box-shadow: 0 8px 12px 0 rgba(0,0, 0, 0.2), 0 8px 24px 0 rgba(0, 0, 0, 0.25);          <br />
	
&#125;
          <br />          <br />

.HackingTruth-card-img  &#123;          <br />
	  width: 100%;          <br />
	  height: 100%;          <br />
	  border-bottom: 1px solid green;          <br />
	  border-top-left-radius: 25px;          <br />
&#125;

          <br />          <br />
		  
		  
.card-content &#123;           <br />
	
	padding: 20px;          <br />
&#125;

          <br />          <br />

.card-header &#123;          <br />
	color: black;          <br />
	margin-bottom: 5px;          <br />
	
&#125;
          <br />          <br />
		  
.card-para &#123;          <br />
	color: black;          <br />
	margin-bottom: 10px;          <br />

&#125;

          <br />          <br />

.btn-primary &#123;          <br />
   background-color: black;           <br />
  border: 1px solid red;          <br />
   
  padding: 10px;          <br />
  text-align: center;          <br />
  
&#125;
          <br />          <br />


.btn-primary > a:hover &#123;          <br />
	color: white;          <br />
&#125;

          <br />          <br />

.capclasshackingtruth &#123;          <br />
	border-top: 2px solid red;          <br />
	border-bottom: 2px solid red;          <br />
	text-align: center;          <br />

&#125;
          <br />          <br />


		</pre>
		
		
		
		<br />
		<br />
		
		
        </Typography>
		<strong>Lets Run the code :</strong>
		<br />
		<br />
		<br />
				<Typography>
		<img src={hackingtruthflexbox} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		<br />
		
		
		
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Htmlbasicarticle" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Htmlintroarticle" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Htmlcardbox;