import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
	
	component: {
		background: '#ffffff',
		color: '#000000'
	}, 
	
	container: {
		justifyContent: 'center',
		'& > *' : {
			padding: 20
		}
		
	},
	
	navlink: {
		textDecoration: 'none',
		color: 'inherit'
	},
	
})


const Navbar = () => {
  const classes = useStyles();
   return (
	<AppBar className = {classes.component}>
	  <Toolbar className = {classes.container}>
	  
	   <Link className={classes.navlink} to = "/"><Typography>Home</Typography></Link>
	   <a className={classes.navlink} href ="https://hackingtruth.org/service" target="_blank" ><Typography>Services</Typography></a>
	   <a className={classes.navlink} href ="https://hackingtruth.org/academy" target="_blank" ><Typography>Academy</Typography></a>
	   <a className={classes.navlink} href ="https://hackingtruth.org/contact" target="_blank" ><Typography>Contact</Typography></a>
	   
	   
	  </Toolbar>
	</AppBar>
	
	
	
	
 );
};

export default Navbar;