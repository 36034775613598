import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
//import webindex from "../../../../../src/MyComponents/home/dbms/images/1.png";
import webindex from "../../../../../src/MyComponents/home/os/images/OShackingtruth.png";
import webimages from "../../../../../src/MyComponents/home/os/images/raid-1.png";
import webimagesraid2 from "../../../../../src/MyComponents/home/os/images/raid-2.png";
import webimagesraid3 from "../../../../../src/MyComponents/home/os/images/raid-3.png";
import webimagesraid4 from "../../../../../src/MyComponents/home/os/images/raid-4.png";
import webimagesraid5 from "../../../../../src/MyComponents/home/os/images/raid-5.png";
import webimagesraid6 from "../../../../../src/MyComponents/home/os/images/raid-6.png";
import webimagesraid7 from "../../../../../src/MyComponents/home/os/images/raid-7.png"
import webimagesraid8 from "../../../../../src/MyComponents/home/os/images/raid-8.png"

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Redundantarraysofindependent = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Operating System</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>26 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Redundant Arrays of Independent (RAID) </strong></Typography><br />
	 <Typography>
    <li>Redundant Array of Indepenedent Disk.</li>
    <li>Redundant Arrays of Inexpensive Disk</li>
    <li>Redundant means copy.</li>

     
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Purpose </strong></Typography><br />
    <Typography>
       
	   If we think point of view for company or organisation we need two things more important -
	   <br />
	   <br />
	<li>Performance</li>
    <li>Security or Availability</li>
    
   
	
		<br />
		<br />
		
		
        </Typography>
		
		
		<Typography variant="h5" component="h6"><strong> Performance </strong></Typography><br />
    <Typography>
        
		
    <li>In case of read and write you want to read data from disk and write from disk fastly.</li>
   
		<br />
		<br />
		
		<Typography variant="h5" component="h6"><strong> Security or Availability </strong></Typography><br />
 
		
			
    <li>Data should be available 24 by 7 and the real life example some websites were in town for 6 to 7 hours and
	the his owner suffered the losses.
</li>
   		
    <li>So that's why company 1/2 their data should be 24/7 data available if our data is not available then it will
be a great loss for the company.

</li>
   
		
		<br />
		<br />
		
		
        </Typography>
		
		
		
	
	
	<br />
<Typography variant="h5" component="h6"><strong>Different Levels </strong></Typography><br />
  <Typography variant="h6" component="h6"><strong>Raid 0 - Data striping</strong></Typography><br />
      
    <Typography>
      
		
    <li>Breaking the data and two separate disc.</li>
  
		<br />	
	<br />
	<br />
		 <img src={webimages} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
		<br />
			
	<br />
	<br />
		<img src={webimagesraid2} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	
	<br />	

		
		
		</Typography>
			
		
	
	<br />
<Typography variant="h5" component="h6"><strong>What is the benefit for this?  </strong></Typography><br />
    <Typography>
        
		
    Performance because if you want to read data then you can parallel and both disc are independent like if you
	want to change data A7 and then write parallel.

	
	<br />
	<br />
	
		<br />
		<br />
		
		
		
		</Typography>
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Raid 1 - Data Mirroring </strong></Typography><br />
    <Typography>
        
		
    <li>Same data copy to the other disk.</li>
    <li>We can say cost will be the too much but in expensive disk means we are used that memory whose price
	is not high ( not SSD) only desi reviews and performance is good but price in not too much.
.</li>
    <br />
		<br />
		<br />
	<br />
		<img src={webimagesraid3} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
<br />
It's good with availability and security let say if one disk failed then We can access the same data from another disk.
<br />
<strong>But still all disk are failed then it's a worst case but if the failures occurred whether I will access method or not??</strong>
<br />
Yes we can, we increase our probability because still we can access the data.

		 
    
		
		
		
	<br />	
			
		</Typography>
		
	<br />	
			<br />	
			
	<br />
<Typography variant="h5" component="h6"><strong>Raid 1+0 or Raid 10 </strong></Typography><br />
    <Typography>
        
		<br />
		<br />
	<br />
		<img src={webimagesraid4} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
<br />
    <li>Here, stripping and mirroring are both done and that's why we say it's a nested raid because it's a combination of both.
</li>
    <li>Example, email server, web server because RAID 0 performance is good and RAID 1 for good security or availability.
 </li>
    

	</Typography>
		
		<br />
		<br />
		
		
		
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Raid 3 </strong></Typography><br />
    <Typography>
        
		
    <li>We can breaks data into different blocks or disc.</li>
	   
		<br />
		<br />
	<br />
		<img src={webimagesraid5} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
<br />
	<li>Ap - parity of Data "A"</li>
    
	
	<Typography variant="h6" component="h6" >Why we use parity?</Typography>
	
	
	
    <li>Because if we store parity of data then in case if one  the disc is failed still I can recover the data from parity.</li>
	   
		<br />
		<strong>A1 + A2 + A3 and Ap(1-3)</strong><br />
		1 + 2 + 3 = 6
		<br />
		<strong>A1 disk is failed so how to recover ?</strong>
		<br />
		We know that how we take parity so,<br />
		2 + 3 = 5 is sum then we minus (-) <strong>6 - 5 = 1</strong>
		<br />
		<br />
		So data will be <strong>1</strong>
	<br />
		<br />
		
    <Typography>
       
	   <strong>But what happened next if two disk are failed?</strong><br />
        So, we can't recover the data from disk.
   
	<br />	
	<br />
	But the problem is all parity are stored in only one disk in case the problem will be happen in parity disk then we can't use another any disk and can't read and write disk because failed of parity disc then so can't Change in parity disc.
  <br />
  <br />
  <br />
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Raid 4 </strong></Typography><br />
    <Typography>
        <br />
	<br />
		<img src={webimagesraid6} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
	<br />
	<br />
		
    <li>Not difference in RAID 3 and RAID 4 and the problem is solved in RAID 5.</li>
	   
		<br />
		
<br />
	 </Typography>

       

<br />
  <br />
  <br />
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Raid 5 </strong></Typography><br />
    <Typography>
        <br />
	<br />
		<img src={webimagesraid7} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
	<br />
	<br />
		
    <li>parity divided into different ways of blocks but for its what will happen so what will happen with this it will happen that you will not have any data over CPU utilisation.</li>
	 <li>Yaa, we can say that write performance is good as compare than RAID 3 and 4.</li>
	   
		<br />
		
<br />
	 </Typography>

       


<br />
  <br />
  <br />
		
	
	<br />
<Typography variant="h5" component="h6"><strong>Raid 6 </strong></Typography><br />
    <Typography>
        <br />
	<br />
		<img src={webimagesraid8} className = {classes.imagelongterm} alt="hackingtruthbanner" />
		
	<br />
	<br />
	<br />
		
    <li>If there are two data or disk is failed then definitely we can  recover with parity because I have two parity.</li>
	 <li>This time, we have two parities.</li>
	   
		<br />
		
<br />
	 </Typography>

       





















	 </Typography>

       

	 </Typography>

       

<br />
		<br />
		
		 <br />
		<br />
		
		 
	
			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Redundantarraysofindependent;