import { Button, Box, makeStyles, Typography, Grid, item } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/webdevelopment/images/jqueryblur1.png";
import webindex2 from "../../../../../src/MyComponents/home/webdevelopment/images/jqueryblur2.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	
}))

const Jqueryblur = () => {
	
	const classes = useStyles();
	const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={url} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Javascript jQuery</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>8 Feb 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography> <strong>Javascript Blur </strong></Typography><br />
	 <Typography>
    

    You can focus or apply css with blur and why we use blur event because
	when your focus is no longer in your input box.
	<br />
	The blur() method attaches an event handler function to an HTML form field.
	The function is executed when the form field loses focus. 
    <br />
	this event was used only with form elements like &lt;input&gt;. In latest browsers, 
	it has been extended to include all element types. 
	<br />
	The blur () method is often used together with focus () method. 
	<br />
	<br />
	








<br />
<br />
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Syntax</strong></Typography><br />
    <Typography>
       
The Syntax is - <br /><strong>  $(selector).blur(function)  </strong>
<br />
<br />
<li>Function - Its an optional parameter. It is used to specifiy the funciton to run when the 
    element loses the focus (blur).</li>

<br />
<br />

		
		
		
        </Typography>
		
		
			<br />
	
	
	<Typography><strong>Lets use this</strong></Typography><br />
    <Typography>
       
The following example demonstrates the blur method with input box.
<br />
<br />
<pre>
&lt;!DOCTYPE html&gt;  <br />
&lt;html&gt;   <br />
&lt;head&gt;   <br />
&lt;title&gt;jQuery Blur&lt;&#47;title&gt; <br /> <br />
&lt;script src="https:&#47;&#47;code.jquery.com&#47;jquery-1.10.2.js"&gt;&lt;&#47;script&gt;   <br />
 <br /> <br />
&lt;&#47;head&gt;   <br /> 
&lt;body&gt;   <br /> <br />

&lt;form action="" method="POST" class="from-form" style="text-align:center" &gt; <br /> <br />
  &lt;label for="fname"&gt;First name:&lt;&#47;label&gt; <br />
  &lt;input type="text" id="fname" name="fname" value=""&gt;&lt;br &#47;&gt;&lt;br &#47;&gt; <br />
  &lt;label for="lname"&gt;Last name:&lt;&#47;label&gt; <br />
  &lt;input type="text" id="lname" name="lname" value=""&gt;&lt;br&gt;&lt;br&gt; <br />
  &lt;input type="submit" value="Submit"&gt; <br /> <br />
&lt;&#47;form&gt;  <br /> 
&lt;&#47;body&gt;   <br /> <br /> <br />

&lt;script&gt;   <br /> <br />

$(document).ready(function()&#123;   <br />
  &nbsp;   $("input").blur(function()&#123;   <br />
      &nbsp; &nbsp;   $(this).css("background-color", "#f0f0f0"); <br />
	&nbsp; &nbsp; &nbsp; 	$(this).css("border", "2px solid red"); <br />

		
   &nbsp;  &#125;);   <br />
	
&#125;);   <br /> <br />
&lt;&#47;script&gt;   <br />
&lt;&#47;html&gt;   <br />

 




</pre>



		
		<br />  <br />  <br />
  <strong> You can also use this line of code..something like this in between &lt;head&gt; open and &lt;&#47;head&gt; close without downloading any file of jquery.</strong>
      <br />  <br />
	  
<pre>

	&lt;script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;
	
	  &lt;&#47;script&gt;

</pre>
<br />
<br />
		
        </Typography>
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		<img src={webindex} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		
		</Typography>
		<br />
		<br />
		
			<br />
	
	
	<Typography><strong>Blur with Click Event</strong></Typography><br />
    <Typography>
       
Now we will focus and unfocus in input box.
<br />
<br />
<br />
<br />

<pre>

&lt;!DOCTYPE html&gt;  <br />
&lt;html&gt;   <br />
&lt;head&gt;   <br />
&lt;title&gt;jQuery Blur&lt;&#47;title&gt; <br /> <br />
&lt;script src="https:&#47;&#47;code.jquery.com&#47;jquery-1.10.2.js"&gt;&lt;&#47;script&gt;   <br />
 <br /> <br />
&lt;&#47;head&gt;   <br /> 
&lt;body&gt;   <br /> <br />

&lt;form action="" method="POST" class="from-form" style="text-align:center" &gt; <br /> <br />
  &lt;label for="fname"&gt;First name:&lt;&#47;label&gt; <br />
  &lt;input type="text" id="fname" name="fname" value=""&gt;&lt;br &#47;&gt;&lt;br &#47;&gt; <br />
  &lt;label for="lname"&gt;Last name:&lt;&#47;label&gt; <br />
  &lt;input type="text" id="lname" name="lname" value=""&gt;&lt;br&gt;&lt;br&gt; <br />
  &lt;input type="submit" value="Submit"&gt; <br /> <br />
&lt;&#47;form&gt;  <br /> 
&lt;&#47;body&gt;   <br /> <br /> <br />

&lt;script&gt;   <br /> <br />

$(document).ready(function()&#123;   <br />
  &nbsp;  $("input").blur(function()&#123;   <br />
  &nbsp;&nbsp;      $(this).css("background-color", "#f0f0f0"); <br />
		&nbsp;&nbsp;&nbsp; $(this).css("border", "2px solid red"); <br />
&nbsp;&nbsp;&nbsp;&nbsp;		 $(this).css("border-radius", "0%"); <br />
		
    &#125;);   <br /> <br />
	&nbsp;$("input").click(function()&#123; <br />
	&nbsp;&nbsp;   $(this).css("background-color", "#ffffff"); <br />

	&nbsp;&nbsp;&nbsp;   $(this).css("border-radius", "50px"); <br />
	   
	   &#125;); <br /> 
&#125;);     <br /> <br />
&lt;&#47;script&gt;   <br />
&lt;&#47;html&gt;   <br />

 




</pre>

<br />
<br />





		<br />
		<br />
		
		
        </Typography>
		
		
		
		<Typography><strong>Lets Run</strong></Typography><br />
		<Typography>
		
		 <br />
		 <strong>NOTE :</strong> You can save HTML files with different types of extensions. Like: <strong>.html &nbsp; .htm &nbsp; .xhtml &nbsp; .xht &nbsp; .hta</strong>
		
		<br />
		<br />
		<br />
		
		</Typography>
		
		
		<Typography>
		
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
		<br />
		<br />
		
		
			<br />
	
	
	
		
		
		
		
		
		
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
	</Typography>		
		
		<br />
		<br />
		<br />
		
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/Jquerybasic" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Jqueryeventlist" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	
	


	</>

	
 )
}


export default Jqueryblur;