import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Edit, Delete }  from '@material-ui/icons';
import { Link } from "react-router-dom";
import webindex from "../../../../../src/MyComponents/home/Datastructure/images/datastructure-hackingtruth.png";
import webindex1 from "../../../../../src/MyComponents/home/Datastructure/images/arrayinDS.png";
import webindex2 from "../../../../../src/MyComponents/home/Datastructure/images/2d.png";

const  useStyles = makeStyles((theme) => ({
	 
	 container: {		 
		 padding: '0 100px',
		 [theme.breakpoints.down('md')]: {
			 padding: 0
		 }
	 },
	 image: {
		 width: '100%',
		 height: '50vh',
		 objectFit: 'cover' 
	 },
	 
	 
	 imagelongterm: {
		 width: '100%',
		 height: 'auto',
		 objectFit: 'cover' 
	 },
	 
	 myfirstheading: {
		 width: '100%',
		 height: '100%',
		 [theme.breakpoints.down('md')]: {
		 
		 
		 }
	 },
	 
	 icons: { 
		 float: 'right',
	 },
	 icon: { 
		 margin: 5,
		 border: '1px solid #878787',
		 padding: '5px',
		 borderRadius: '10px'
	 },
	 heading: { 
		 fontSize: 35,
		 fontWeight: 600,
		 textAlign : 'center',
		 margin: '50px 0 10px 0'
	 },
	 subheading: {
		 display: 'flex',
		 color: '#878787',
		 margin: '20px  0', 
		 [theme.breakpoints.down('md')]: {
		 display: 'block',
		 textAlign: 'center'
		 } 
	 }, 
	 paragraph: {  
	  [theme.breakpoints.down('md')]: {
		 display: 'block',
		  margin: '0 20px',
		 } 
	 }, 


	headingone: {
         fontSize: 26,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 27,
		 }
	},
	
	
	headingtwo: {
         fontSize: 22,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 23,
		 }
	},
	
	
	headingthree: {
         fontSize: 21,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 22,
		 }
	},
	
	
	headingfour: {
         fontSize: 20,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 21,
		 }
	},
	
	
	headingfive: {
         fontSize: 19,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 20,
		 }
	},
	
	
	headingsix: {
         fontSize: 18,
         [theme.breakpoints.down('md')]: {
			 
			 fontSize: 19,
		 }
	},
	
	selective: {
		
		fontSize: 25,
	},
	
	selectiveexample: {
		
		fontSize: 18,
	},
	
	
	
	
	create:{
		
		margin: 20,
		background: '#6495ED',
		color: '#ffffff',
		width: '50%',
		
		[theme.breakpoints.down('md')]: {
			
			width: '80%',
			
		}
	},
	

	link:{
		color: 'inherit',
		textDecoration: 'none'
	}
	
 
	
	

	
	
}))

const Arrayindatastructure = () => {
	
	const classes = useStyles();
	
	return (
	
	<>
	
	<Box className={classes.container} > 
     <img src={webindex} className = {classes.image} alt="hackingtruthbanner" />
     <Box className={classes.icons} >
	 <Link to="/UpdateView" ><Edit className={classes.icon} color="primary" /></Link>
	 <Delete className={classes.icon} color="error"  />
	 </Box>
	 
	 
<Typography className={classes.heading} > Array in Data Structure</Typography>
	 
<Box className={classes.subheading}> 
  <Typography>Author:<span style={{fontWeight: 600}}> Hacking Truth</span>
     </Typography>
        <Typography style={{marginLeft: 'auto'}}>22 July 2022 </Typography>
	 
</Box>
	
<Box className={classes.paragraph}>
   <Typography variant="h5" component="h6"> <strong>Array in Data Structure</strong></Typography><br />
	 <Typography>
    <li>An array is a data structure which can store multiple homogenous data elements under one name</li>
    <li>Array is an infinite, ordered collection of homogenous data elements.</li>
    
<li>Contains limited number of elements (less storage capacity).</li>
<li>Elements are stored one by one in contiguous location of computer memory in a linear order.</li>
<li>All elements should be of same data type.</li>
<li>For eg - Suppose you want to store age of 50 students using a programming language</li>
<li>This can be done by taking 50 different variables for each students but it is not easy to handle the 50 different variables for this.</li>

<li>All alternate to this is array where we need not declare 50 variables for 50 students.
<br /> 
Where a list of data item is specfied under one name using a single subscript then such a 
variable is called a one dimensional ( 1 - D ) Array.
<br />
<br />
<br />
<b>An array is declared in following way - </b>
<br />
<br />
<li>Where date type specifies types of elements such as int, float, char etc.</li>
<li>And size indicate a maximum number of elements that can be stored inside the array</li>
<br />
<br />
<b>To represent age of 5 student</b>
<br />
<br />
int age[5];
<br />
<br />
	
		<Typography>
		<img src={webindex1} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />
<li>number of elements = 6</li>
<li>index of an array start from 0 to (size - 1)</li>

<br />
</li>
	</Typography>
  	<br />
	
	<br />
	
	<Typography><strong>Initialization of 1 - D Array</strong></Typography><br />
    <Typography>
      
	   Elements of an array can be intialized after an array is declared. An array can
	   be delcared at compile time or run time.
    <br />
    <br />
	<ol>	   
	<li><b>Compile Time Initialization</b></li><br />
     int age[5] = &#123;20, 25, 21, 23, 19, 17&#125;
        <br />
        <br />
        <br />
		<li><b>Run Time Initialization</b></li><br />
		for ( i = 0; i &lt; 5; i++)
			<br />
			&#123; <br />
			age[i] = 20;
			<br />
			&#125;
			<br />
			<br />
			<br />
	</ol>
	Here are elements of array are intialized to 20.
		<br />
		<br />
		<br />
		
		<Typography><strong>Terminology</strong></Typography><br />
    <Typography>
       
    	   
	<li>Size : No of elements in array</li>
	<li>Type : Data type (int, char, float, etc)</li>
	<li>Base : Address of memory location.</li>
	<li>Index : a[i], index of an array
	<br />
	a[0], a[1], a[2] ....</li>
     
	 <li>Range of index : 0 to n-1; n = size</li>
	 <li>Word : size of an element</li>
	 <li>One dimensional : int a[8]</li>
	 <li>Two dimensional : int a[5][6]
	 <br />
	 (for matrix in two dimensional are a[5] is row and a[6] column)
	 </li>
   
	
		<br />
		<br />
		
		
		
        </Typography>
		
	
		
		
        </Typography>
		
		
		  <Typography><strong>Two Dimensional Array</strong></Typography><br /> <Typography>
    <li>2D dimensional can be defined as an array of arrays. The 2D array is organized
	 as matrix which can be represented as the collection of rows and column</li>
    <br />
	<br />
	The syntax of 2D - 
    <br />
	  <br />
	  int arr [max rows] &nbsp;[max column]


<br />
<br />
<br />
	
		<Typography>
		<img src={webindex2} className = {classes.myfirstheading} alt="hackingtruthbanner" />
		
		</Typography>
       
<br />
		<br />
		<br />

However, we can store the value stored in any particular cell of a 2D array to some
variable x by using the following syntax


<br />
<br />
int x = a]i][j];
<br /><br />
where i and j is the row and column number of the cell respectively.
<br />
<br />
We can arrign each cell of a 2D array to a by using the following code.

<br />
<br />
for (int i=0; i &lt;n; i++)
	<br />
  &#123;
  <br />
  for (int j=0; j &lt;n; j++)
	  <br />
  &#123;
  <br />
  a[ i ][ j ] = 0;
    <br />
	&#125;<br />
	&#125;
	<br />
	<br />
	<br />
	The syntax of declare and intialize the 2D array is given as follows : 
	
<br />
<br />
int arr[2][2] = &#123;0, 1, 2, 3&#125;
<br />
<br />
<br />

<li>The number of elements that can be presented in a 2D array will always be equal to (number of rows X number of columns)</li>

<br />
<br />
<br />
<br />



<b># Wap in c to storing users data into a 2D array and printing it.</b>
<br />
<br />
#include &lt;stdio.h&gt;
<br /><br />
void main() <br />
&#123;<br /><br />
int arr [ 3 ][ 3 ], i, j;
<br /><br />
for (i=0; i&lt;3; i++)<br />
&#123;
for (j=0; j&lt;3; j++)<br />
&#123;
<br /><br />
printf("Enter a [%d][%d] :" i, j); <br />
scanf("%d ", &arr[ i ][ j ]); <br />
<br /><br />
&nbsp;&nbsp;&nbsp;&#125; <br />
&nbsp;&nbsp;&#125; <br /><br />

printf("\n printing the elements .... \n"); <br /><br />

for (i=o; i&lt;3; i++) <br /><br />
&#123;<br />
 printf("\n");<br />
 for (j=0; j &lt;3; j++)<br />
 &#123;<br /><br />
 printf("%d\t", arr[ i ][ j ]); <br />
 &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br />
 &nbsp;&nbsp;&nbsp;&#125;<br />
 &nbsp;&nbsp;&#125;<br /><br />
<br />
<br />

	</Typography>
		
	
		

			
			
<Typography> I hope you liked this post, then you should not forget to share this post at all.
Thank you so much :-)
</Typography>
 <br />
 <br />
 <Typography> <strong>Disclaimer</strong></Typography><br />
<br />
<Typography>
All tutorials are for informational and educational purposes only and have been made using our own routers, servers, websites and other vulnerable free resources.
we do not contain any illegal activity. We believe that ethical hacking, information security and cyber security should be familiar subjects to anyone using digital
information and computers. Hacking Truth is against misuse of the information and we strongly suggest against it. Please regard the word hacking as ethical hacking or
penetration testing every time this word is used. We do not promote, encourage, support or excite any illegal activity or hacking.
		
	</Typography>		
		
		<br />
		<br />
		<br />
		
	</Box>
	</Box>
	
	
     <Box className={classes.container}  sx={{ width: '100%' }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
       <Link to="/stackdatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Previous</Button></Link>
	 
        </Grid>
        <Grid item xs={6}>
         <Link to="/Stackdatastructure" className={classes.link}><Button variant="contained" className={classes.create}> Next</Button></Link>
	 
        </Grid>
        
		
      </Grid>
    </Box>
	</>

	//const url = `https://1.bp.blogspot.com/-idDrv7rJTZU/XaWbNQ7bhZI/AAAAAAAAuKk/ScyiDT7AwD8tMEqmgtQuFr7E6KwHWwP1wCLcBGAsYHQ/s1600/15051001_1301-2019-05485131004046851354152-01.jpeg`
	
 )
}


export default Arrayindatastructure;